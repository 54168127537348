import { makeStyles } from '@mui/styles';

const MainWrapperStyle = makeStyles(() => ({
    headerSection: {
        color: '#1C1C1C',
        width: '100%',
        '& svg': {
            color: '#1C1C1C',
        },
        '& h2': {
            color: '#1C1C1C',
            fontWeight: '600',
        },
        '& p': {
            color: '#A1A1A1',
            marginTop: '8px',
        },
        '& .MuiToolbar-root': {
            backgroundColor: '#fff',
            boxShadow: '0px 3px 3px rgba(196, 196, 196, 0.2)',
            padding: '16px 30px',
            position: 'absolute',
            width: '100%',
            right: '-20px',
            left: '0',
            boxSizing: 'border-box',
            overflowX: 'hidden',
        },
        '& .MuiPaper-root': {
            boxShadow: 'none',
        },
    },
    headerSectionSideBar: {
        '& hr': {
            display: 'none',
        },
        '& .MuiPaper-root': {
            boxShadow: '0px 8.57143px 3.80952px rgba(118, 118, 118, 0.3)',
            borderRight: 'none',
            padding: '5px',
        },
        '& main': {
            padding: '0px',
            height: 'auto',
        },
        '& ul': {
            padding: '8px 8px 0px 8px',
        },
    },
    tableSection: {
        '& .MuiPaper-root': {
            boxShadow: 'none',
            borderRight: 'none',
            padding: '0px',
            margin: '0px',
        },
        '& .grui-listitem.grui-odd': {
            backgroundColor: '#F8F8F8',
        },
        '& .grui-selected': {
            backgroundColor: '#C1D6FF !important',
        },
        '& .grui-listitem': {
            backgroundColor: '#F8F8F8',
            borderColor: '#CECECE',
            color: '#050505',
            borderBottom: 'none',
            '&:last-child': {
                borderBottom: '1px solid #CECECE',
            },
        },
        '& .grui-dock': {
            border: '1px solid #CECECE',
            borderRadius: '15px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
        },
        '& .grui-title-el': {
            color: '#8F8F8F',
            fontFamily: 'Inter-Bold',
            fontSize: '14px',
        },
        '& .grui-gridcell-body-el': {
            '& div': {
                padding: '5px 12px !important',
                fontSize: '14px',
            },
        },
        '& .grui-gridcolumn-title-wrap-el': {
            padding: '8px 12px',
        },
        '& .grui-headercontainer': {
            borderBottom: '0px',
        },
        '& .grui-rownumberercell .grui-rownumberercell-body-el': {
            padding: '5px',
        },
        '& .grui-gridrow': {
            minHeight: '29px',
        },
        '& .grui-header-el': {
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
            borderColor: '#CECECE',
        },
        '& .grui-layout-box': {
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
        },
    },
    tableStyleAgGrid: {
        '& .ag-root-wrapper': {
            border: '1px solid #CECECE',
            borderRadius: '4px 4px 14.23px 14.23px',
        },
        '& .ag-header': {
            minHeight: '31px !important',
            height: 'auto !important',
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
            borderBottom: '1px solid #CECECE',
        },
        '& .ag-header-row': {
            height: '31px !important',
        },
        '& .ag-header-cell-label': {
            fontWeight: '600',
            color: '#8F8F8F',
            fontSize: '13px',
            // commented below line as value field is not aligned to right
            // flexDirection: 'row !important',
        },
        '& .ag-header-cell': {
            borderRight: '1px solid #CECECE',
        },
        '& .ag-row': {
            borderBottom: '1px solid #CECECE',
            backgroundColor: '#F8F8F8',
            color: '#353535',
            fontSize: '13px',
            fontWeight: '400',
        },
        '& .ag-cell': {
            '& .ag-icon': {
                display: 'none',
            },
        },
        '& .ag-checkbox-input-wrapper': {
            fontSize: '12px',
            lineHeight: 'normal',
            width: '12px',
            height: '12px',
            backgroundColor: '#fff',
            '&:after': {
                color: '#B9B9B9',
            },
        },
    },
}));

export default MainWrapperStyle;
