import { orange, cyan } from '@mui/material/colors';

const colorSchemes = {
    light: {
        palette: {
            primary: { main: '#1C1C1C' },
            secondary: { main: '#4C4C4C' },
        },
    },
    dark: {
        palette: {
            primary: cyan,
            secondary: orange,
        },
    },
};

export default colorSchemes;
