import {
    Box, Button, FormControl, MenuItem, Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import TableHeaderStyle from './TableHeader.style';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import SelectDropdown from '../../../../components/DropdownSelect';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import exportExcel from '../../../../helper/ExportExcel';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import { OutletContext } from '../../../../types/OrganisationDetailType';

// interface Props {
// tittle: string,
// handleClickOpen: () => void;
// handleChange: (event: SelectChangeEvent) => void;
// handleClickOpenDuplicate: () => void;
// }

interface MyComponentProps {
    onFilterTextBoxChanged?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    searchText: string,
}

function EstimateHeader(props: MyComponentProps) {
    const classes = TableHeaderStyle();
    const [view, setView] = useState('Default View');
    const { onFilterTextBoxChanged, searchText, setSearchText } = props;
    const navigate = useNavigate();
    const selectMenuOptions = ['Default View'];
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=estimate`);
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'estimate_detail',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    ESTIMATE
                </Typography>
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    {/* <Box className={classes.undoButtn}>
                        <button type="submit">
                            <BarChartOutlinedIcon />
                            {' '}
                            Benchmark
                        </button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    {/* <SelectDropdown view={view} menuOptions={selectMenuOptions} setView={setView} /> */}
                </Box>
                <QuickSearchFilter setSearchText={setSearchText} searchText={searchText} />
            </Box>
            {/* <div className="example-header">
                <span>Quick Filter:</span>
                <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={onFilterTextBoxChanged}
                />
            </div> */}
            <Box className={classes.versonUndo}>
                {/* <Box className={`${classes.undoButtn} ${classes.deleteButtonStyle}`}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <ContentCopyIcon />
                        {' '}
                        Duplicate
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit">
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </button>
                </Box> */}
                <Box className={classes.outlineButton}>
                    <Button onClick={handleExportExcel}>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box>
                {/* <Box className={classes.undoButtnNew}>
                    <button type="submit" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </button>
                </Box> */}
            </Box>
        </div>
    );
}

export default EstimateHeader;
