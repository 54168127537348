import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Button, DialogActions,
} from '@mui/material';
import DeleteIcon from '../../../../assets/deleteIcon.svg';
import DeleteReportStyle from './DeleteReportConfirmationPopup.style';
import Loader from '../../../../components/Loader';

interface InviteProps {
    open: boolean,
    setOpenDelRow: Dispatch<SetStateAction<boolean>>,
    deleteAction: () => void,
    reportName?: string,
    title?:string,
    loading: boolean,
}

function DeleteReportPopup({
    open, setOpenDelRow, deleteAction, reportName, title, loading,
}: InviteProps) {
    const classes = DeleteReportStyle();

    const handleClose = () => {
        setOpenDelRow(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <Loader loading={loading} isFromAGGrid />
                <DialogTitle className={classes.dilogBoxSection}>
                    Delete
                    {' '}
                    {title || 'Rows'}
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Box className={classes.centerBody}>
                        <Box className={classes.projectListMain}>
                            <img src={DeleteIcon} alt="graph" />
                        </Box>
                        <Typography className={classes.subHeader} variant="h3">
                            { reportName
                                ? `Are you sure you want to delete ${title?.toLocaleLowerCase() || ''} '${reportName}'?`
                                : 'Are you sure you want to delete the selected items?'}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledrmButton}>
                        <Button variant="contained" color="primary" onClick={deleteAction}>
                            Delete
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DeleteReportPopup;
