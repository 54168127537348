import React, { useState } from 'react';
import useStyles from './DashboardHeader.style';
import { Box, Button, Typography } from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import BarChartIcon from '@mui/icons-material/BarChart';
import BenchmarkingOptModel from '../../../ProjectsListing/Components/BenchmarkingOptModel';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';

function DashboardHeader() {
    const classes = useStyles();
    const [openbm, setOpenBenchmark] = useState(false);
    const navigate = useNavigate();

    const handleClickOpenBenchmark = () => {
        setOpenBenchmark(true);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=project dashboard`);
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    CAPEX
                </Typography>
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    {/* <Box className={classes.outlineButton}>
                        <Button onClick={handleClickOpenBenchmark}>
                            <BarChartIcon />
                            {' '}
                            Benchmark
                        </Button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                </Box>
            </Box>
            {/* <Box className={classes.versonUndo}>
                <Box className={classes.outlineButton}>
                    <Button>
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box>
            </Box>
            {openbm && <BenchmarkingOptModel open={openbm} setOpenBenchmark={setOpenBenchmark} />} */}
        </div>
    );
}

export default DashboardHeader;
