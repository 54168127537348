import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const INSERT_INVITE = gql`
mutation InsertInvite($email: String!, $status: String!, $invite_code: String!, $meta: jsonb, $org_id: uuid!, $role: String, $invited_by: uuid) {
    insert_org_invite(objects: {
        email: $email,
        status: $status,
        invite_code: $invite_code,
        meta: $meta,
        org_id: $org_id,
        role: $role,
        invited_by: $invited_by,
    }, on_conflict: {constraint: org_invite_pkey, update_columns: [email]}) {
        affected_rows
      }
  }
`;

const GET_INVITE = gql` 
    query GetInvite($email: String!) {
        org_invite(where: {email: {_eq: $email}, _and: {status: {_eq: "pending"}}}) {
            id
            email
            status
            org_id
            role
          }
    }
`;

const UPDATE_INVITE = gql`
    mutation UpdateInvite($email: String!) {
        update_org_invite(_set: {status: "accepted"}, where: {email: {_eq: $email}}) {
            affected_rows
            affected_rows
            returning {
            id
            org_id
            role
            status
            }
        }
    }
`;

const SEND_INVITE = (inviter: string, invitee_email: string, org_id: string, role_id: string) => gql`
mutation {
    addInvitation(
      input: { 
        inviter: ${addEscapeCharacters(inviter)}, 
        invitee_email: ${addEscapeCharacters(invitee_email)}, 
        org_id: ${addEscapeCharacters(org_id)}, role_id: ${role_id} }
    ) {
      error
      created_at
      expires_at
      client_id
      email
      error_code
      invitation_url
    }
  }  
`;

const CHECK_USER_INVITATION = (invitationCode: string, org_id: string) => gql`
query {
    getUserInvitation(invite_code: ${addEscapeCharacters(invitationCode)}, org_id: ${addEscapeCharacters(org_id)}) {
      email
      error
      expires_at
      error_code
      method
      invited_by
      org_name
    }
  }  
`;

const ACCEPT_INVITATION = (type: string, invite_code: string, org_id: string, email: string, password: string, username?: string) => gql`
  mutation {
    acceptInvitation(
      input: {
        type: ${type}
        invite_code: ${addEscapeCharacters(invite_code)}
        org_id: ${addEscapeCharacters(org_id)}
        email: ${addEscapeCharacters(email)}
        password:${addEscapeCharacters(password)}
        ${username ? `user_name:${addEscapeCharacters(username)}` : ''}
      }
    ) {
      email
      error
      error_code
      expires_at
    }
  }
`;

export default {
    INSERT_INVITE,
    GET_INVITE,
    UPDATE_INVITE,
    SEND_INVITE,
    CHECK_USER_INVITATION,
    ACCEPT_INVITATION,
};
