import { makeStyles } from '@mui/styles';

const AddCurrencyStyle = makeStyles(() => ({
    undoButtnNew: {
        marginLeft: '15px',
        '& button': {
            fontSize: '14px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            fontWeight: '700',
            textTransform: 'none',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '5px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '10px !important',
            minWidth: '65%',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '0px !important',
        '& input': {
            padding: '6px',
            height: '22px',
            fontSize: '14px',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
        },
    },
    dilogBoxSectionFooter: {
        padding: '10px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    popheight: {
        height: '500px',
    },
    white: {
        backgroundColor: '#fff',
    },
    datepic: {
        '& svg': {
            fontSize: '15px',
        },
    },
    addPopup: {
        paddingTop: '38px !important',
        width: 'calc(100% + 16px) !important',
        marginLeft: '-8px !important',
        paddingBottom: '20px',
        '& > div': {
            padding: '20px 10px 0px',
        },
        '&  span': {
            fontSize: '16px',
        },
        '&  input': {
            height: '23px',
        },
    },
    checkbox: {
        marginRight: '15px',
        width: '21px',
        position: 'relative',
        top: '7px',
    },
    errorMsg: {
        display: 'block',
        fontSize: '14px',
        fontWeight: '400',
        color: '#F00',
        marginBottom: '10px',
    },
}));
export default AddCurrencyStyle;
