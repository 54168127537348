import { makeStyles } from '@mui/styles';

const DeleteProjectModelStyle = makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '10px !important',
            maxWidth: '900px !important',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '0px !important',
        '& input': {
            padding: '6px',
            height: '27px',
            fontSize: '18px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
        },
    },
    sectionBody: {
        padding: '32px !important',
        '& p': {
            color: '#787878 !important',
            padding: '32px',
        },
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '27px !important',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
        },
        '& button': {
            textTransform: 'unset !important',
            fontSize: '16px !important',
            border: '1px solid #787878 !important',
            color: '#787878 !important',
            fontFamily: 'Inter-Medium !important',
        },
    },
    leftdivCenter: {
        textAlign: 'center',
        padding: '28px 16px 16px 16px !important',
    },
    rigntdivCenter: {
        textAlign: 'center',
        borderLeft: '1px solid #CECECE',
        padding: '28px 16px 16px 16px !important',
    },
    projectListMain: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '40vh !important',
            },
        },
        '& main': {
            height: 'auto !important',
        },
    },
    dilogBoxSectionFooter: {
        padding: '10px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledrmButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #B00000',
            borderRadius: '4px',
            backgroundColor: '#B00000',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#B00000 !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    subHeader: {
        padding: '32px 0px 0px 32px',
        color: '#787878',
    },
    centerBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '32px',
    },
}));

export default DeleteProjectModelStyle;
