import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Box, Button, FormControl, MenuItem, Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AgGridReact } from 'ag-grid-react';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Link,
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { PATH_DATABASE } from '../../../../Routes/path';
import Loader from '../../../../components/Loader';
import NotificationBar from '../../../../components/NotificationBar';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE, ROLES } from '../../../../constants';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import projectQuery from '../../../../queries/projects';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import NotificationBarType from '../../../../types/NotificationBarType';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import ProjectListType, { ProjectCellRendererType } from '../../../../types/ProjectListType';
import BenchmarkingOptModel from '../../Components/BenchmarkingOptModel';
import DeleteProjectModel from '../../Components/DeleteProjectModel';
import DeleteRowModel from '../../Components/DeleteRowModel';
import ImportOptionsModel from '../../Components/ImportOptionsModel';
import NewProjectDialog from '../../Components/NewProjectModel';
import ProjectsMapView from '../ProjectsMapView';
import ProjectViewStyle from './ProjectsList.style';
import { useUserRole } from '../../../../hooks/useUserRole';
import { formatCurr } from '../../../../hooks/useFormatCurrency';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import OrgCostHeaderBar from '../../../../components/OrgCostHeaderBar';
import { OrganisationContext } from '../../../../store/context/organisationContext';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import exportExcel from '../../../../helper/ExportExcel';

interface LocationState {
    state ?: {
        industryId?: string | undefined;
        regionId?: string | undefined;
        country?: string | undefined
        industry?: string | undefined;
    }
}

function ProjectListView() {
    const classes = ProjectViewStyle();
    const [view, setView] = React.useState('listview');
    const [open, setOpen] = useState(false);
    const [openbm, setOpenBenchmark] = useState(false);
    const [openim, setOpenImport] = useState(false);
    const [opendp, setOpenDelProject] = useState(false);
    const [opendr, setOpenDelRow] = useState(false);
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [resourceSortingState, setResourceSortingState] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [selectedProject, setSelectedProject] = useState<ProjectListType | null>(null);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });

    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);
    const loggedInUserRole = useUserRole();
    const navigate = useNavigate();
    const gridRef = useRef<AgGridReact<ProjectListType>>(null);
    const curLocation:LocationState = useLocation();
    const cont: OutletContext = useOutletContext();
    const [searchText, setSearchText] = useState('');
    const OrganisationCtx = useContext(OrganisationContext);
    const fillerFn = () => {};
    const refetchOrg = OrganisationCtx?.refetchOrgCost || fillerFn;
    const refetchProjCount = OrganisationCtx?.refetchProjCount || fillerFn;

    const selectedIndustry = curLocation?.state?.industryId;
    const selectedRegion = curLocation?.state?.regionId;
    const country = curLocation?.state?.country;
    const industry = curLocation?.state?.industry;

    const organizationId = userCtx?.user?.default_org_id;

    const handleChange = (event: SelectChangeEvent) => {
        setSearchText('');
        setView(event.target.value);
    };
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0]
        && selectedProject?.name ? `${response.errors[0].message} '${selectedProject?.name}'` : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    // Navigate to project dashboard page
    const handleLink = (project: ProjectListType) => {
        projectCtx?.setProject(project);
        navigate(PATH_DATABASE.projectDashboard);
    };

    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const nameCellRenderer = (params: ProjectCellRendererType) => {
        const { value } = params;
        const colName = params.colDef.field;
        let date = params.data?.base_date;
        date = date && date.toString();
        const formattedDate = date && date.split('-')[0];
        if (params?.value != null) {
            switch (colName) {
            case 'name':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params.data);
                        }}
                    >
                        {value}
                    </Link>
                );
            case 'total_cost':
                return `${params.data?.currency_data?.symbol || '$'}${formatCurr(+(+params.value).toFixed(0), 'en-US')}`;
            case 'base_date':
                return formattedDate;
            case 'version_code':
            case 'estimate_type':
            case 'industry.description':
            case 'subindustry.description':
            case 'country.name':
            case 'region':
            case 'consultant':
            case 'owner':
            case 'currency_data.code':
            case 'client_number':
                return params.value.toLocaleString();
            default:
                return params?.value;
            }
        } else if (params?.value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'client_number', type: 'string', initialWidth: 160, headerName: 'Project Number', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'name',
            type: 'string',
            initialWidth: 350,
            headerName: 'Name',
            cellRendererFramework: nameCellRenderer,
            enableValue: false,
        },
        {
            field: 'estimate_type', type: 'string', initialWidth: 150, headerName: 'Estimate Type', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'industry.description', type: 'string', initialWidth: 180, headerName: 'Industry', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'subindustry.description', type: 'string', initialWidth: 180, headerName: 'Sub-Industry', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'country.name', type: 'string', initialWidth: 180, headerName: 'Country', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'region', type: 'string', initialWidth: 180, headerName: 'Region', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'base_date', type: 'string', initialWidth: 150, headerName: 'Base Date', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'consultant', type: 'string', initialWidth: 150, headerName: 'Consultant', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'owner', type: 'string', initialWidth: 150, headerName: 'Owner', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'total_cost', type: 'numericColumn', initialWidth: 150, headerName: 'Total Cost', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'currency_data.code', type: 'string', initialWidth: 150, headerName: 'Currency', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'version_code', type: 'number', initialWidth: 150, headerName: 'Version', cellRendererFramework: nameCellRenderer,
        },
    ];

    // ProjectQuery to get project data
    const { data, isFetching, refetch } = useGQLQuery(
        'GetProject',
        projectQuery.GET_PROJECTS(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            resourceSortingState,
            sortOrder,
            userCtx?.user?.default_org_id || '',
            selectedIndustry || '',
            selectedRegion || '',
        ),
        {},
        {
            onError: handleApiError,
            enabled: !!userCtx?.user?.default_org_id,
        },
    );

    // Extracting total page count and table data
    const projectData = data?.project;
    const tableData = projectData?.data;
    const pageInfo = projectData?.pageInfo;
    const rowData = tableData && tableData.length ? tableData : [];
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    useEffect(() => {
        if (userCtx?.user?.default_org_id) {
            refetch();
        }
    }, [pageSkipValue, resourceSortingState, sortOrder, organizationId]);

    // To change sorting order
    const changeSortingValue = (par: string) => {
        if (resourceSortingState === par || resourceSortingState === '') {
            if (sortOrder === 'DESC') {
                setSortOrder('ASC');
            } else {
                setSortOrder('DESC');
            }
        }
        setResourceSortingState(par);
    };

    // Fetch new page data
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const handleDeleteSuccess = () => {
        refetch();
        refetchOrg();
        refetchProjCount();
        setSelectedProject(null);
        setNotificationDetails({
            open: true,
            message: 'Project deleted successfully.',
            type: 'success',
        });
    };

    // Querry to delete project
    const { mutate, isLoading } = useGQLMutation(
        'DeleteProject',
        projectQuery.DELETE_PROJECT(userCtx?.user?.default_org_id || '', selectedProject?.id || ''),
        {
            onSuccess: handleDeleteSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const onDeleteConfirmation = () => {
        mutate({});
        setOpenDelRow(false);
    };

    // To get selected row data
    const onSelectionChanged = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const rows = gridApi?.getSelectedRows();
            if (rows && rows.length === 1) {
                const row = rows[0];
                setSelectedProject(row);
            } else {
                setSelectedProject(null);
            }
        }
    };

    const handleClickDelRow = () => {
        setOpenDelRow(true);
    };

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handleClickOpenBenchmark = () => {
    //     setOpenBenchmark(true);
    // };
    const handleClickOpenImport = () => {
        setOpenImport(true);
    };
    // const handleClickDelProject = () => {
    //     setOpenDelProject(true);
    // };

    // checking logged in user
    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    const navigateToReports = () => {
        navigate(`${PATH_DATABASE.reports}?module=projects`);
    };
    const handleClear = () => {
        setSearchText('');
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'project',
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };
    return (
        <div>
            <Loader loading={isFetching || isLoading} />
            <div className={classes.TableHeader}>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h2">
                        PROJECT LIST
                    </Typography>
                    <OrgCostHeaderBar />
                    <Box className={classes.versonUndo}>
                        {/* {view === 'listview' ? (
                            <div>
                                <Box className={classes.outlineButton}>
                                    <Button type="button" onClick={handleClickOpenBenchmark}>
                                        <BarChartIcon />
                                        {' '}
                                        Benchmark
                                    </Button>
                                </Box>
                            </div>
                        ) : (<div> </div>) } */}
                        <Box className={classes.outlineButton}>
                            <Button type="button" onClick={navigateToReports}>
                                <DataSaverOffOutlinedIcon />
                                {' '}
                                Reports
                            </Button>
                        </Box>
                        <FormControl variant="filled">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={view}
                                label="view"
                                onChange={handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                color: '#787878',
                                            },
                                            '& .Mui-selected': {
                                                color: '#021948',
                                                bgcolor: '#E6ECFA !important',
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="listview">List View</MenuItem>
                                <MenuItem value="mapview">Map View</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <QuickSearchFilter searchText={searchText} setSearchText={setSearchText} />
                </Box>
                <Box className={classes.versonUndo}>
                    {/* visible on project selection */}
                    {view === 'listview' && selectedProject?.id && (
                        <div className={classes.versonUndo}>
                            <Box className={classes.deleteButton}>
                                <Button onClick={handleClickDelRow}>
                                    <DeleteOutlineIcon />
                                    Delete Project
                                </Button>
                            </Box>
                            {/* <Box className={classes.outlineButton}>
                                <Button onClick={handleClickOpen}>
                                    <ContentCopyIcon />
                                    Duplicate
                                </Button>
                            </Box> */}
                        </div>
                    )}
                    <div className={classes.versonUndo}>
                        {(userRole?.role === 'Admin' || userRole?.role === 'Super Admin') && !selectedProject?.id && (
                            <Box className={classes.outlineButton}>
                                <Button onClick={handleClickOpenImport}>
                                    <CloudDownloadOutlinedIcon />
                                    Import
                                </Button>
                            </Box>
                        )}
                        <Box className={classes.outlineButton}>
                            <Button onClick={handleExportExcel}>
                                <CloudUploadOutlinedIcon />
                                Export
                            </Button>
                        </Box>
                    </div>
                    {/* <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                            <AddIcon />
                            {' '}
                            New
                        </Button>
                    </Box> */}
                </Box>
            </div>
            {view === 'listview' ? (
                <div>
                    {(selectedRegion || selectedIndustry) && (
                        <Box className={classes.filterDesc}>
                            <span>Data filtered by: </span>
                            <span>
                                {country || industry }
                            </span>
                        </Box>
                    )}
                    <Box className={`${isFetching ? classes.opacityBlur : ''} 
                        ${selectedRegion || selectedIndustry ? classes.projectListMain : classes.projectListing}`}
                    >
                        <AgGridComponent
                            gridRef={gridRef}
                            columnDefs={columnDefs}
                            rowData={rowData}
                            changeSortingValue={changeSortingValue}
                            sortOrder={sortOrder}
                            onSelectionChanged={onSelectionChanged}
                            isRangeSelectable
                            isGroupable
                            isExportEnabled
                            isClipboardEnabled
                            isToolPanelsEnabled
                            isStatusBarEnabled
                            moduleName="projectListView"
                            quickFilterText={searchText}
                            isPinnable
                            disableResizable
                            setSearchText={setSearchText}
                        />
                    </Box>
                    {/* <Box>
                        {(totalPages > 1) ? <Pagination totalPages={totalPages} handlePageChange={handlePageChange} /> : null}
                    </Box> */}
                </div>
            ) : (
                <div>
                    <ProjectsMapView searchedText={searchText} setSearchText={setSearchText} />
                </div>
            ) }
            {open && <NewProjectDialog open={open} setOpen={setOpen} />}
            {openbm && <BenchmarkingOptModel open={openbm} setOpenBenchmark={setOpenBenchmark} />}
            {openim && <ImportOptionsModel open={openim} setOpenImport={setOpenImport} refetchProject={refetch} />}
            {opendp && <DeleteProjectModel open={opendp} setOpenDelProject={setOpenDelProject} />}
            {opendr && (
                <DeleteRowModel
                    open={opendr}
                    setOpenDelRow={setOpenDelRow}
                    deleteAction={onDeleteConfirmation}
                    orgName={selectedProject?.name}
                    title="Project"
                />
            )}
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
        </div>
    );
}

export default ProjectListView;
