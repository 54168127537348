import LoginBackground from '../../../../assets/login_bg.png';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        backgroundImage: 'url("3409297.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    wrapper: {
        height: 'calc(100vh - 72px)',
        background: 'rgb(2,25,72)',
        backgroundImage: `url(${LoginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    loginBox: {
        backgroundColor: '#fff',
        padding: '50px 40px',
        borderRadius: '20px',
        marginTop: '25%',
        width: '80%',
    },
    loginBtn: {
        backgroundColor: '#021948 !important',
        padding: '10px 16px !important',
        marginTop: '18px !important',
        marginBottom: '10px !important',
        '&:disabled': {
            color: 'white !important',
            opacity: 0.6,
        },
    },
    forgotPasswordText: {
        textDecoration: 'none',
        display: 'block',
        textAlign: 'right',
    },
    pageTitle: {
        color: '#fff',
        fontSize: '57px !important',
        fontWeight: '700 !important',
    },
    pageDescription: {
        paddingRight: '243px !important',
        color: '#fff',
        fontSize: '22px !important',
        lineHeight: '34.5px !important',
        marginTop: '35px !important',
    },
    headerBackground: {
        background: '#fff',
    },
    loginContent: {
        textAlign: 'center',
    },
    copyrightText: {
        textDecoration: 'none',
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px !important',
        fontStyle: 'normal',
        fontWeight: '400 !important',
        lineHeight: '32px',
        textDecorationLine: 'none',
        marginTop: '100px !important',
    },
    HeaderNav: {
        background: '#fff !important',
        boxShadow: 'none !important',
        color: '#000 !important',
        width: 'calc(100% - 240px) !important',
        marginLeft: '240px',
    },
    fullwidth: {
        background: '#fff !important',
        boxShadow: 'none !important',
        color: '#000 !important',
        width: '100%',
        marginLeft: '0',
    },
    errorMsg: {
        color: 'red',
        margin: '0px',
        textAlign: 'left',
        fontSize: '12px',
        fontFamily: 'Inter, sans-serif',
    },
    atag: {
        color: '#ffffff !important',
    },
}));
