import { useMutation } from 'react-query';
import gqlConfig from '../helper/gqlConfig';
import InvitationType from '../types/InvitationTypes';

type Config = {
    onError?: ({ response }: QueryErrorResponse) => void,
    onSuccess?: (data: InvitationType) => void
    enabled? : boolean;
};

type QueryErrorResponse = {
    response : {
        errors: {
            message: string;
        }[]
    }
};

export const useGQLMutation = (key: string, query: string, config?: Config, path?: string) => {
    const apiPath = path || '/setting';
    const graphQLClient = gqlConfig(apiPath);

    const onError = ({ response }: QueryErrorResponse) => {
        const { message } = response.errors[0];
        const defaultMessage = 'Something went wrong!';
        // snackbarCtx?.setNotificationDetails({
        //     open: true,
        //     message: message || defaultMessage,
        //     type: 'error',
        // });
    };

    const fetchData = async (variables = {}) => graphQLClient.request<InvitationType>(query, variables);
    const defaultQueryConfig = { refetchOnWindowFocus: false, enabled: false };
    return useMutation(key, fetchData, {
        ...defaultQueryConfig,
        ...config,
        onError: config?.onError ? config.onError : onError,
    });
};
