import React, { useEffect, useMemo, useState } from 'react';

function useIsInViewport(ref: React.MutableRefObject<HTMLInputElement>) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), {
            // rootMargin: '-90px',
            threshold: 1.0,
        }),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

export default useIsInViewport;
