import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const SAVE_GRID_LAYOUT = gql`
    mutation ($module_name: String!, $grid_state: String!){
      saveGrid (
        module_name: $module_name
        grid_state: $grid_state
      ){
        message
      }  
}`;

const GET_GRID_LAYOUT = (modole_name: string) => gql`
query{
  getGridLayout(
    module_name: ${addEscapeCharacters(modole_name)}
  ) {
    data {
      grid_state
      created_at
      updated_at
      module_name
    }
  }
}`;

const RESET_GRID_LAYOUT = gql`
  mutation ResetGridLayout($module_name: String!) {
    resetGrid(module_name: $module_name) {
      message
    }
  }
`;

export default {
    SAVE_GRID_LAYOUT,
    GET_GRID_LAYOUT,
    RESET_GRID_LAYOUT,
};
