import React, {
    createContext, ReactNode, useState, useMemo, memo,
} from 'react';

interface Props {
  children: ReactNode
}

interface TabType {
    general: boolean,
    version: boolean,
    structure: boolean,
    currency: boolean,
    location: boolean,
    [key: string]: boolean,
}

interface ProjectSettingsTabContextType {
  tabSelected: TabType,
  setTabSelected: React.Dispatch<React.SetStateAction<TabType>>
}

const ProjectSettingsTabContext = createContext<ProjectSettingsTabContextType | null>(null);

function ProjectSettingsTabContextProvider(props: Props) {
    const { children } = props;
    const [tabSelected, setTabSelected] = useState<TabType>({
        general: false,
        version: false,
        structure: false,
        currency: false,
        location: false,
    });

    const defaultValue = useMemo(() => ({
        tabSelected,
        setTabSelected,
    }), [tabSelected, setTabSelected]);

    return (
        <ProjectSettingsTabContext.Provider value={defaultValue}>
            {children}
        </ProjectSettingsTabContext.Provider>
    );
}

export { ProjectSettingsTabContext };

export default ProjectSettingsTabContextProvider;
