import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './ImportLog.style';
import AgGridComponent from '../../../components/agGridTableComponent';
import Loader from '../../../components/Loader';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import Pagination from '../../../components/paginationTable';
import ERROR_LOGS from '../../../queries/importLog';
import { UserContext } from '../../../store/context/userContext';
import { OutletContext, QueryErrorResponse } from '../../../types/CommoditiesListType';
import { useOutletContext } from 'react-router-dom';
import { dateFormat } from '../../../helper/DateFormat';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { RowHeightParams } from 'ag-grid-community';

interface CustomCellRendererType {
    column: {
        colId: string,
        colDef: {
            field: string
            headerName: string
        },
    },
    value: number | string,
    node: {
        footer: boolean
        rowPinned: string
    },
    colDef: {
        field: string
        headerName: string
    }
    successCallback: (projects: string[]) => void,
    isProjectCountGreaterThanZero: (projects: string[]) => boolean,
}

interface LogType {
    import_sequence: string;
    log: string;
    message: string;
    created_at: string;
}

interface CellRendererTyp {
    data: {
    import_logs: LogType[];
     project_name: string;
     version_name: string;
    }
    successCallback: (import_logs: LogType[]) => void,
    isProjectCountGreaterThanZero: (import_logs: LogType[]) => void,
}

function ImportLog() {
    const classes = useStyles();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const cont: OutletContext = useOutletContext();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0]
            ? response.errors[0].message
            : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'error-log',
        ERROR_LOGS.GET_ERROR_LOGS(
            userCtx?.user?.default_org_id || '',
            userCtx?.user?.user_id || '',
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
        ),
        {
            onError: handleApiError,
        },
    );

    useEffect(() => {
        refetch();
    }, [pageSkipValue]);

    const customCellRenderer = (params: CustomCellRendererType) => {
        const colName = params.colDef.field;
        const mydate = dateFormat(+params.value, 'dd/mm/yyyy');
        const time = new Date(+params.value).toLocaleTimeString();
        if (params.value != null) {
            switch (colName) {
            case 'project_name':
            case 'version_name':
                return params.value;
            case 'log':
                if (params.value === 'error') {
                    return (
                        <span className={classes.errorStatus}>{params.value}</span>
                    );
                }
                return (
                    <span className={classes.successStatus}>{params.value}</span>
                );
            case 'created_at':
                return `${mydate}, ${time}`;
            case 'message':
                return params.value;
            default:
                return params.value;
            }
        }
        if (params.value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'row_expand ',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRenderer: 'agGroupCellRenderer',
            sortable: false,
        },
        {
            field: 'project_name',
            initialWidth: 200,
            headerName: 'Project Name',
            type: 'string',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'version_name',
            initialWidth: 200,
            headerName: 'Version Name',
            type: 'string',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'created_at',
            initialWidth: 200,
            headerName: 'Last Import Time',
            type: 'string',
            cellRenderer: customCellRenderer,
        },
    ];

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'import_sequence',
                        headerName: 'Import Sequence',
                        minWidth: 50,
                        width: 150,
                        flex: 1,
                    },
                    {
                        field: 'log',
                        headerName: 'Import Status',
                        minWidth: 50,
                        width: 150,
                        cellRenderer: customCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'created_at',
                        headerName: 'Import Time',
                        minWidth: 50,
                        width: 180,
                        cellRenderer: customCellRenderer,
                        flex: 1,
                        sort: 'desc',
                    },
                    {
                        field: 'duration',
                        headerName: 'Import Duration',
                        minWidth: 50,
                        width: 150,
                        cellRenderer: customCellRenderer,
                        flex: 1,
                    },
                    {
                        field: 'message',
                        headerName: 'Message',
                        minWidth: 50,
                        width: 500,
                        cellRenderer: customCellRenderer,
                        flex: 4,
                    },
                ],
                getRowHeight: (params: RowHeightParams) => 27,
                defaultColDef: {
                    sortable: true,
                    unSortIcon: true,
                    suppressMenu: true,
                    resizable: true,
                    suppressMovable: true,
                },
                getContextMenuItems: () => ['copy', 'copyWithHeaders', 'export'],
            },
            getDetailRowData(params: CellRendererTyp) {
                params.successCallback(params.data.import_logs || []);
            },
        }),
        [],
    );

    const logs = data?.getImportLogList;
    const tableData = logs?.data || [];
    const pageInfo = logs?.pageInfo;

    const rowData = tableData?.map((item) => item) || [];
    const totalPages = pageInfo && pageInfo.totalcount
        ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE)
        : 0;

    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    return (
        <Box>
            <Loader loading={isFetching} />
            <Box className={classes.inTableHeader}>
                <Typography variant="h3">
                    Import Logs
                </Typography>
            </Box>
            <div>
                <Box className={classes.tableSec}>
                    <AgGridComponent
                        columnDefs={columnDefs}
                        rowData={rowData}
                        changeSortingValue={() => {}}
                        detailCellRendererParams={detailCellRendererParams}
                        masterDetail
                        noSelection
                        moduleName="ImportLog"
                        isClipboardEnabled
                    />
                </Box>
            </div>
        </Box>
    );
}

export default ImportLog;
