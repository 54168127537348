import React from 'react';
import {
    Box, Button, Grid, TextField, Typography,
    Container, DialogActions, DialogContent, MenuItem, Select,
}
    from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import NewCaseStyle from './NewCase.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function NewCaseModal(props: {
        caseModel: boolean;
        setCaseModel: (caseModel: boolean) => void;
      }): React.JSX.Element {
    const classes = NewCaseStyle();
    const {
        caseModel, setCaseModel,
    } = props;
    const handleCaseOpen = () => {
        setCaseModel(true);
    };
    const handleCaseClose = () => {
        setCaseModel(false);
    };

    return (
        <div>
            <Dialog
                open={caseModel}
                onClose={handleCaseClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '45%',
                        minHeight: 300,
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h5" component="h5">
                        New Case
                    </Typography>

                </DialogTitle>
                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Container>
                        <Grid className={classes.addPopup} container spacing={4} sx={{ pt: '10px' }}>
                            <Grid xs={12} md={3}>
                                <Typography variant="h6" component="span">Code</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="4" size="small" />
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Typography variant="h6" component="span">Name</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="Alternative 3" size="small" />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Typography variant="h6" component="span">Based on</Typography>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className={classes.Dropdown}
                                    fullWidth
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Container>

                </DialogContent>
                <DialogActions sx={{ mr: '20px' }}>
                    <Box>
                        <Button autoFocus onClick={handleCaseClose} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box className={classes.undoButtnNew}>
                        <Button onClick={handleCaseClose} autoFocus variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NewCaseModal;
