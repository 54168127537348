import React from 'react';
import {
    Box, Button, Grid, TextField, Typography,
    Container, DialogActions, DialogContent,
}
    from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EditGeneralStyle from './EditGeneral.style';
import SaveGeneralModal from '../SaveGeneralModal';

function EditGeneralModal(props: {
    open: boolean;
    setOpen: (open: boolean) => void;
    save: boolean;
    setSave: (open: boolean) => void;
  }): React.JSX.Element {
    const classes = EditGeneralStyle();
    const {
        open, setOpen, save, setSave,
    } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSaveOpen = () => {
        setOpen(true);
    };
    const handleSaveClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <SaveGeneralModal save={save} setSave={setSave} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '70%',
                        minHeight: 500,
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h5" component="h5">
                        Edit General Settings
                    </Typography>

                </DialogTitle>
                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Container>
                        <Grid className={classes.addPopup} container spacing={4} sx={{ pt: '10px' }}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Project number</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="6645-6950" size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Consultant</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="PC Engineering" size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Client</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="Coal Mine Facilites" size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Owner</Typography>
                                <TextField label="" className={classes.white} placeholder="CB Resources" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Client Project Number</Typography>
                                <TextField label="" className={classes.white} placeholder="6445-6950" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Estimate Type</Typography>
                                <TextField label="" className={classes.white} placeholder="Class 2 Construction" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Target Accuracy</Typography>
                                <TextField label="" className={classes.white} placeholder="20%" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Version</Typography>
                                <TextField label="" className={classes.white} placeholder="A2" fullWidth size="small" />
                            </Grid>
                            <Grid item className="datepic" xs={12} md={4}>
                                <Typography variant="h6" component="span">Base Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']} sx={{ pt: 0 }}>
                                        <DatePicker className={classes.white} slotProps={{ textField: { size: 'small', fullWidth: true } }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Industry</Typography>
                                <TextField label="" className={classes.white} placeholder="Oil&Gas" fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" component="span">Sub Industry</Typography>
                                <TextField label="" className={classes.white} placeholder="Oil Refinery" fullWidth size="small" />
                            </Grid>
                        </Grid>
                    </Container>

                </DialogContent>
                <DialogActions sx={{ mr: '20px' }}>
                    <Box>
                        <Button autoFocus onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box className={classes.undoButtnNew}>
                        <Button onClick={handleSaveOpen} autoFocus variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditGeneralModal;
