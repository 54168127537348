import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_PROJECT_VERSION = (orgId: string, projectId: string) => gql`
query GetProjectVersion {
  version (
    project_id: ${addEscapeCharacters(projectId)},
    org_id: ${addEscapeCharacters(orgId)}
  ) {
    id
    code
    name
    date
    updated_by
    isCurrentVersion
    org_id
    project_id
    total_cost
    created_at
    updated_at
  }
}`;

const SWITCH_VERSION = (orgId: string, projectId: string, versionId: string) => gql`
  mutation {
    switchVersion (
      org_id: ${addEscapeCharacters(orgId)},
      project_id: ${addEscapeCharacters(projectId)},
      version_id: ${addEscapeCharacters(versionId)},
    ) {
      message
    }
}`;

export default { GET_PROJECT_VERSION, SWITCH_VERSION };
