import React from 'react';
import {
    Box, Button, Grid, TextField, Typography,
    Container, DialogActions, DialogContent,
}
    from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EditLocationStyle from './EditProjectLocation.style';

function EditLocationModal(props: {
    editLocation: boolean;
    setEditLocation: (editLocation: boolean) => void;
  }): React.JSX.Element {
    const classes = EditLocationStyle();
    const {
        editLocation, setEditLocation,
    } = props;

    const handleLocationOpen = () => {
        setEditLocation(true);
    };
    const handleLocationClose = () => {
        setEditLocation(false);
    };

    return (
        <div>
            <Dialog
                open={editLocation}
                onClose={handleLocationClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '70%',
                        minHeight: 320,
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h5" component="h5">
                        Edit Project Location
                    </Typography>

                </DialogTitle>
                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Container>
                        <Grid className={classes.addPopup} container spacing={4} sx={{ pt: '10px' }}>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Country</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="Canada" size="small" />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Region</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="Quebec" size="small" />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Location</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="Vieux-Moulin" size="small" />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Longitude</Typography>
                                <TextField label="" className={classes.white} placeholder="46.863011" fullWidth size="small" />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Latitude</Typography>
                                <TextField label="" className={classes.white} placeholder="-71.213766" fullWidth size="small" />
                            </Grid>
                        </Grid>
                    </Container>

                </DialogContent>
                <DialogActions sx={{ mr: '20px' }}>
                    <Box>
                        <Button autoFocus onClick={handleLocationClose} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box className={classes.undoButtnNew}>
                        <Button onClick={handleLocationClose} autoFocus variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditLocationModal;
