import * as React from 'react';
import { generateRandom } from '../helper/validations';
import { JSONParse, getAccessTokenFromLocalStorage } from '../helper/StorageHelper';
import SubscriptionType from '../types/SubscriptionResponseTypes';
import { useLogout } from './useLogout';

const connectionAck = 'connection_ack';
const devURL = 'ws://localhost:4000/api/graphql/subscribe';
const deployedURL = process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT || '';
const wsURL = process.env.NODE_ENV === 'development' ? devURL : deployedURL;

/**
 * Custom hook to connect to WS server and is a one way communication (Server -> Client).
 * @param subscriptionQuery graphQL query sent to ws server
 * @param cbFn :When ever a new data is received this fn will be called
 * @returns initialize function which on call will initialize ws connection
 */
const useSubscription = (subscriptionQuery: string, cbFn: (data: SubscriptionType) => void) => {
    const websocket = React.useRef<WebSocket>();
    const logout = useLogout();

    const initialize = () => {
        const token = getAccessTokenFromLocalStorage();
        websocket.current = new WebSocket(wsURL, 'graphql-transport-ws');
        websocket.current.onopen = () => {
            const data = JSON.stringify(
                {
                    type: 'connection_init',
                    payload: {
                        Authorization: `Bearer ${token || ''}`,
                    },
                },
            );
            websocket?.current?.send(data);
            console.log('GraphQl Subscription Server Connected');
        };
        websocket.current.onmessage = (event: MessageEvent<string>) => {
            const { data } = event;
            const response = JSONParse<{ type: string, payload: { data: SubscriptionType } }>(data);
            if (response.type === connectionAck) {
                const subscribeToGetUserDetails = {
                    id: generateRandom(),
                    type: 'subscribe',
                    payload: {
                        query: subscriptionQuery,
                        httpMultipartParams: {
                            includeCookies: false,
                            requestQueryPlan: false,
                            showTraces: false,
                            abortSignalRef: {},
                        },
                    },
                };
                websocket?.current?.send(JSON.stringify(subscribeToGetUserDetails));
            } else {
                const { data: responseData } = response.payload;
                cbFn(responseData);
            }
        };

        websocket.current.onclose = (close) => {
            if (close.reason === 'INVALID_TOKEN_CONTEXT') {
                logout();
            }
            console.log('GraphQl Subscription Server Closed');
            setTimeout(() => {
                console.log('Reconnecting To GraphQl Subscription Server');
                initialize();
            }, 10000);
        };

        websocket.current.onerror = (err) => {
            console.log('GraphQl Subscription Server Connection Error', err);
            websocket.current?.close();
        };
    };

    return initialize;
};

export default useSubscription;
