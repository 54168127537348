import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '6px !important',
            minWidth: '45%',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '12px 30px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '0px 30px !important',
        '& input': {
            padding: '6px',
            height: '27px',
            fontSize: '15px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            color: '#6A6A6A',
            fontFamily: 'Inter-Medium',
        },
        '& .Mui-disabled': {
            backgroundColor: '#E0E0E0',
        },
    },
    sectionBody: {
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '15px',
            height: '27px !important',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            color: '#6A6A6A',
            alignItems: 'center',
            display: 'flex',
        },
    },
    dateSection: {
        '& input': {
            padding: '6px',
            height: '27px',
            fontSize: '15px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#E8E8E8',
            color: '#6A6A6A',
            fontFamily: 'Inter-Medium',
        },
        '& .Mui-disabled': {
            backgroundColor: '#E0E0E0',
        },
    },
    labelText: {
        marginBottom: '5px !important',
        color: '#353535',
    },
    dilogBoxSectionFooter: {
        padding: '10px 30px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '16px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '5px 25px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
    filledButton: {
        marginLeft: '15px !important',
        '& button': {
            fontSize: '16px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            padding: '5px 33px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
        },
    },
}));
