import { makeStyles } from '@mui/styles';

const mapStyle = makeStyles(() => ({
    mapContainer: {
        '& .leaflet-bottom': {
            display: 'none',
        },
        '& .leaflet-top': {
            zIndex: '998 !important',
        },
        '& .leaflet-pane': {
            zIndex: '9 !important',
        },
        '& .leaflet-container': {
            '& .leaflet-map-pane': {
                '& .leaflet-tooltip-pane': {
                    '& .leaflet-tooltip-top:before': {
                        borderTopColor: '#DA6970 !important',
                    },
                },
            },
        },
    },
}));

export default mapStyle;
