import { getAccessTokenFromLocalStorage } from './StorageHelper';

const BASE_URL = process.env.REACT_APP_DATA_IMPORT_ENDPOINT || '';
interface ExportExcelParams {
    activeOrgId: string | undefined;
    grid: string;
    activeVersionId?: string | undefined;
}

const exportExcel = async ({ activeOrgId, grid, activeVersionId }:ExportExcelParams) => {
    const authToken = getAccessTokenFromLocalStorage();
    const optionalParam = activeVersionId ? `&activeVersionId=${activeVersionId}` : '';
    const exportURL = `${BASE_URL}/spring/pcu/export-excel?activeOrgId=${activeOrgId || ''}&grid=${grid}${optionalParam}`;

    const response = await fetch(exportURL, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken || ''}`,
        },
    });

    if (!response.ok) {
        const statusMessage = response.headers.get('X-Status-Message') || 'An error occurred while generating the report';
        throw new Error(statusMessage);
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${grid.charAt(0).toUpperCase() + grid.slice(1)}_report.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
};
export default exportExcel;
