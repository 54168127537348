import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, DialogActions, DialogContent, DialogTitle, Dialog, Button, IconButton, Grid, Typography, TextField, Select, FormControl, MenuItem,
} from '@mui/material';
import useStyles from './AddEditCommodities.style';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface InviteProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}

function AddEditCommodities({ open, setOpen }: InviteProps) {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open} onClose={handleClose}>
                <DialogTitle className={classes.dilogBoxSection}>Add Commodities</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 8,
                        color: '#000',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={classes.dialogBody}>
                    <Grid container mt={2} spacing={2} className={classes.sectionBody}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Direct/Indirect*
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="Direct">Direct</MenuItem>
                                    <MenuItem value="Indirect">Indirect</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Code (Format: 00.00.00)*
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Title*
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} mb={4} spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Description
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AddEditCommodities;
