import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import searchFilterStyle from './SearchFilter.style';
import { checkSpace } from '../../helper/validations';

interface Props {
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

function SearchFilter(props: Props) {
    const { searchText, setSearchText } = props;
    const classes = searchFilterStyle();

    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value === ' ') {
            return;
        }

        if (checkSpace(value)) {
            setSearchText(value);
        }
    };

    const handleClear = () => {
        setSearchText('');
    };

    return (
        <Box className={classes.inputBoxStyle}>
            <input type="text" placeholder="Search" value={searchText} className="searchInput" onChange={onFilterTextBoxChanged} />
            <span><SearchIcon /></span>
            <span>
                {searchText.length > 0 && <ClearIcon className={classes.searchClear} onClick={handleClear} />}
            </span>
        </Box>
    );
}

export default SearchFilter;
