import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const TOTAL_PROJRCTS_COUNT = (Org_id: string) => gql`
    query {
        projectTotalCount(
          filter: { 
            org_id: ${addEscapeCharacters(Org_id)}
          }
        ) {
          project_count
          project_country_count
        }
    }`;

export default TOTAL_PROJRCTS_COUNT;
