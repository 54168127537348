import { makeStyles } from '@mui/styles';

const quickSearchFilterStyle = makeStyles(() => ({
    searchClear: {
        right: '10px',
        display: 'block',
        marginLeft: 'auto',
        cursor: 'pointer',
    },
    inputBoxStyle: {
        position: 'relative',
        marginLeft: '15px',
        '& input.searchInput': {
            color: '#A5A5A5',
            fontSize: '12px',
            border: '1px solid #787878',
            padding: '7px 29px 7px 30px',
            width: '124px',
            borderRadius: '4px',
            letterSpacing: '.5px',
            fontWeight: '500',
            textOverflow: 'ellipsis',
        },
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '5px',
            transform: 'translateY(-50%)',
            color: '#787878 !important',
            fontSize: '17px',
        },
    },
}));
export default quickSearchFilterStyle;
