import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_ERROR_LOGS = (orgId: string, userId:string, skip: number, take: number) => gql`
query{
  getImportLogList(
    org_id: ${addEscapeCharacters(orgId)}
    user_id: ${addEscapeCharacters(userId)}
    skip: ${skip}
    take: ${take}
  ) {
    data {
      project_name
      version_name
      org_id
      created_at
      import_logs {
        import_sequence
        log
        message
        created_at
        duration
      }
    }
    pageInfo {
      hasNextPage
      totalcount
    }
  }
}`;

export default {
    GET_ERROR_LOGS,
};
