import React, { lazy } from 'react';
import { Dashboard } from '../screens/Dashboard';
import Package from '../screens/Package';
import Preload from './Preload';
import {
    EditCodeStructureModal, GeneralSetting, VersionSetting,
} from '../screens/ProjectSettings';
import { ProjectListView } from '../screens/ProjectsListing';
import { UnitRateHomePage } from '../screens/UnitRate';
import Schedule from '../screens/Schedule';
import { Commodities } from '../screens/Commodities';
import { Reports } from '../screens/Reports';
import { Industries } from '../screens/Industries';
import { Resources } from '../screens/Resources';
import { Estimate } from '../screens/Estimate';
import { UserSettings } from '../screens/UserSettings';
import { UserOrganisationSettings } from '../screens/OrganisationSettings';
import { WBSBenchMarking, WBSDifferent, WBSList } from '../screens/WBS';
import { COABenchmarkingView, COADifferentView, COAListView } from '../screens/COA';
import Organisations from '../screens/Organisations';
import {
    PATH_CMFR, PATH_DATABASE, PATH_SETTINGS,
} from './path';
import ProjectDashboard from '../screens/ProjectDashBoard';

import { AuthGuard } from '../helper/AuthGuard';
import ProtectedRouteWrapper from '../helper/ProtectedRouteWrapper';
import ImportLog from '../screens/ImportLog/pages';

const PageUnavailable = Preload(lazy(() => import('../screens/ProjectSettings')));

export default function ProtectedRouter() {
    const ProtectedRoutes = [
        {
            path: '/',
            element: <AuthGuard component={ProtectedRouteWrapper} />,
            children: [
                {
                    path: PATH_DATABASE.projectDashboard,
                    element: <ProjectDashboard />,
                },
                {
                    path: PATH_DATABASE.dashboard,
                    element: <Dashboard />,
                },
                {
                    path: PATH_DATABASE.organisations,
                    element: <Organisations />,
                },
                {
                    path: PATH_DATABASE.reports,
                    element: <Reports />,
                },
                {
                    path: PATH_DATABASE.industries,
                    element: <Industries />,
                },
                {
                    path: PATH_DATABASE.commodities,
                    element: <Commodities />,
                },
                {
                    path: PATH_DATABASE.projects,
                    element: <ProjectListView />,
                },
                {
                    path: PATH_CMFR.capex.reports,
                    element: <Reports />,
                },
                {
                    path: PATH_CMFR.capex.wbs,
                    element: <WBSList />,
                },
                {
                    path: PATH_DATABASE.userSettings,
                    element: <UserSettings />,
                },
                {
                    path: PATH_CMFR.capex.wbsBenchmarking,
                    element: <WBSBenchMarking />,
                },
                {
                    path: PATH_CMFR.capex.wbsDifferent,
                    element: <WBSDifferent />,
                },
                {
                    path: PATH_CMFR.capex.wbsList,
                    element: <WBSList />,
                },
                {
                    path: PATH_CMFR.capex.coaBenchmarking,
                    element: <COABenchmarkingView />,
                },
                {
                    path: PATH_CMFR.capex.coaDifferent,
                    element: <COADifferentView />,
                },
                {
                    path: PATH_CMFR.capex.coa,
                    element: <COAListView />,
                },
                {
                    path: PATH_CMFR.capex.packages,
                    element: <Package />,
                },
                {
                    path: PATH_CMFR.capex.estimate,
                    element: <Estimate />,
                },
                {
                    path: PATH_CMFR.capex.resources,
                    element: <Resources />,
                },
                {
                    path: PATH_CMFR.capex.schedule,
                    element: <Schedule />,
                },
                {
                    path: PATH_CMFR.capex.unit,
                    element: <UnitRateHomePage />,
                },
                {
                    path: PATH_CMFR.capex.settings.general,
                    element: <GeneralSetting />,
                },
                {
                    path: PATH_CMFR.capex.settings.version,
                    element: <VersionSetting />,
                },
                {
                    path: PATH_CMFR.capex.settings.structure,
                    element: <EditCodeStructureModal />,
                },
                {
                    path: PATH_SETTINGS.organisation,
                    element: <UserOrganisationSettings />,
                },
                {
                    path: PATH_SETTINGS.user,
                    element: <UserSettings />,
                },
                {
                    path: PATH_SETTINGS.importLog,
                    element: <ImportLog />,
                },
                { path: '*', element: <PageUnavailable /> },
            ],
        },
    ];

    return ProtectedRoutes;
}
