/* eslint-disable no-param-reassign */
import React, {
    useState, useContext, useRef, useCallback, useEffect,
} from 'react';
import {
    Box, FormControl, Button, InputLabel, MenuItem, Select, TextField, Typography,
}
    from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AgGridTable from '../../../../components/agGridTableComponent';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import useStyles from './UserOrganisation.style';
import Grid from '@mui/material/Grid';
import { SUPER_ADMIN_ROLE } from '../../../../constants';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import orgQueries from '../../../../queries/organisations';
import { UserContext } from '../../../../store/context/userContext';
import { ColumnDefs, CellRendererType } from '../../../../types/AgGridTypes';
import GetOrgUser from '../../../../types/GetOrgUser';
import NotificationBarType from '../../../../types/NotificationBarType';
import { AgGridReact } from 'ag-grid-react';
import AddOrganisationModal from '../../components/ProjectAssignmentModal';
import DeleteRowModel from '../../components/DeleteRowModel';
import NotificationBar from '../../../../components/NotificationBar';
import { useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import InviteUserPopup from '../../components/InviteUserPopup';
import Loader from '../../../../components/Loader';

const COLUMN_UNIT_MEASURE : ColumnDefs[] = [
    {
        field: 'name',
        headerName: 'name',
    },
    {
        field: 'abbreviation',
        headerName: 'Abbrevation',
    },
    {
        field: 'system',
        headerName: 'System',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'base',
        headerName: 'Base',
    },
    {
        field: 'conversion',
        headerName: 'Conversion',
    },
];
const COLUMN_ATTRIBUTES: ColumnDefs[] = [
    {
        field: 'label',
        headerName: 'Label',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
];

function UserOrganisationSettings() {
    const classes = useStyles();
    const [editFormat, setEditFormat] = React.useState<boolean>(false);
    const [openModal, setOpenAddOrganisationModal] = useState<boolean>(false);
    const [deactivateUser, setDeactivateUserModal] = useState<boolean>(false);
    const [confirmDelete, setConfirmDeleteModal] = useState<boolean>(false);
    const [renderNotification, setrenderNotification] = useState(false);
    const [openInvite, setOpenInvite] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [selectedUser, setSelectedUser] = useState<GetOrgUser>({
        role: '',
        user: {
            id: '',
            user_name: '',
            email: '',
            auth_id: '',
            created_at: '',
            updated_at: '',
        },
    });

    const cont: OutletContext = useOutletContext();
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };
    const gridRef = useRef<AgGridReact<GetOrgUser>>(null);
    const userContext = useContext(UserContext);
    const [sortOrder, setSortOrder] = useState('');
    const orgID: string = userContext?.user?.default_org_id || '';
    const loggedInUserId = userContext?.user?.user_id || '';
    const { data, refetch, isFetching } = useGQLQuery(
        `GetOrganisationUsers-${orgID}`,
        orgQueries.GET_ORGANISATIONS_USERS(),
        { orgId: orgID },
        {
            enabled: !!(orgID),
            onError: handleApiError,
        },
        '/setting',
    );

    const rowDataUser = data?.getorguser || [];

    const customCellRenderer = (params: CellRendererType) => {
        if (params.value != null) {
            const date = new Date(parseInt(`${params.value}`, 10));
            return `${date.getDate()}-${date.toLocaleString('en', { month: 'short' })}-${date.getFullYear()}`;
        }
        return '';
    };
    const COLUMN_DEFS: ColumnDefs[] = [
        {
            field: 'user.user_name',
            headerName: 'User Name',
            initialWidth: 400,
            type: 'string',
        },
        {
            field: 'user.email',
            headerName: 'Email Address',
            initialWidth: 300,
            type: 'string',
        },
        {
            field: 'role',
            headerName: 'User Role',
            initialWidth: 130,
            type: 'string',
        },
        {
            field: 'user.created_at',
            headerName: 'Activation Date',
            initialWidth: 130,
            cellRenderer: customCellRenderer,
            type: 'string',
        },
        {
            field: 'user.updated_at',
            headerName: 'Last Active',
            initialWidth: 130,
            cellRenderer: customCellRenderer,
            type: 'string',
        },
    ];

    const snapManipulate = () => {
        refetch();
        setrenderNotification(true);
        setNotificationDetails({
            open: true,
            message: 'User deactivated successfully.',
            type: 'success',
        });
        setSelectedUser({
            role: '',
            user: {
                id: '',
                user_name: '',
                email: '',
                auth_id: '',
                created_at: '',
                updated_at: '',
            },
        });
    };
    const { data: sucessFullyConfig, mutate: deactivateUserConfig } = useGQLMutation(
        'deactivateUserOrganisation',
        orgQueries.DEACTIVATE_USER_ORGANISATIONS_SETTINGS(orgID, selectedUser?.user?.id),
        {
            enabled: !!(orgID && confirmDelete && selectedUser?.user?.id),
            onSuccess: snapManipulate,
        },
        '/setting',
    );

    useEffect(() => {
        if (confirmDelete && selectedUser?.user?.id) {
            deactivateUserConfig({
                email: selectedUser?.user?.email,
                status: 'pending',
                role: selectedUser?.role,
            });
            setConfirmDeleteModal(!confirmDelete);
        }
    }, [confirmDelete, selectedUser?.user?.id, deactivateUserConfig]);

    const handleFormatOpen = () => {
        setEditFormat(true);
    };
    const handleFormatClose = () => {
        setEditFormat(false);
    };
    const handleClick = useCallback(() => {
        setOpenAddOrganisationModal(true);
    }, []);
    const handleDeactivate = () => {
        setDeactivateUserModal(true);
    };
    const onSelectionChanged = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const selectedRows = gridApi?.getSelectedRows();
            if (selectedRows && selectedRows.length === 1) {
                const row = selectedRows[0];
                setSelectedUser(row);
            } else {
                setSelectedUser({
                    role: '',
                    user: {
                        id: '',
                        user_name: '',
                        email: '',
                        auth_id: '',
                        created_at: '',
                        updated_at: '',
                    },
                });
            }
        }
    };
    const setOpenAddOrgModalCallback = useCallback((isOpen: boolean) => setOpenAddOrganisationModal(isOpen), []);

    const userRole = useUserRole();
    return (
        <div>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    Organisation Settings
                    { userRole === SUPER_ADMIN_ROLE ? (
                        <Button
                            variant="outlined"
                            className={classes.btngray}
                        >
                            <EditOutlinedIcon />
                            {' '}
                            Change Organisation Name
                        </Button>
                    ) : null}
                </Typography>
            </Box>
            <Box className={classes.inTableHeader} sx={{ mt: '40px', mb: '21px' }}>
                <Typography variant="h2">
                    Users
                    <Button
                        className={classes.undoButtnNew}
                        variant="outlined"
                        onClick={() => setOpenInvite(true)}
                    >
                        <AddIcon />
                        {' '}
                        Invite
                    </Button>
                    {selectedUser.user.id
                        ? (
                            <>
                                {/* <Button
                                    className={classes.undoButtnNewSelected}
                                    variant="outlined"
                                >
                                    <EditIcon />
                                    {' '}
                                    Change Role
                                </Button> */}
                                <Button
                                    className={classes.undoButtnNewSelected}
                                    variant="outlined"
                                    onClick={() => handleClick()}
                                >
                                    <AssignmentIcon />
                                    {' '}
                                    Project Assignment
                                </Button>
                                {/* hide Deactivate button once the user selects the org which he has loggen in. */}
                                {selectedUser.user.id !== loggedInUserId && (
                                    <Button
                                        className={classes.undoButtnNewSelectedRed}
                                        variant="outlined"
                                        onClick={handleDeactivate}
                                    >
                                        <DeleteIcon style={{ color: 'red' }} />
                                        Deactivate User
                                    </Button>
                                )}
                                {deactivateUser
                                    ? (
                                        <DeleteRowModel
                                            open={deactivateUser}
                                            setOpenDelRow={setDeactivateUserModal}
                                            setConfirmDeleteModal={setConfirmDeleteModal}
                                            userName={selectedUser?.user?.user_name || ''}
                                        />
                                    )
                                    : null}
                            </>
                        )
                        : null}
                    <InviteUserPopup
                        open={openInvite}
                        setOpen={setOpenInvite}
                    />
                    <NotificationBar
                        open={notificationDetails.open}
                        setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                        type={notificationDetails.type}
                        message={notificationDetails.message}
                    />
                </Typography>
            </Box>
            <Grid container spacing={0}>
                <Grid item xs={12} className={`${isFetching ? classes.opacityBlur : ''} ${classes.tableSec}`}>
                    { isFetching ? (
                        <Loader loading={isFetching} />
                    ) : null }
                    <AgGridTable
                        gridRef={gridRef}
                        columnDefs={COLUMN_DEFS}
                        rowData={rowDataUser}
                        changeSortingValue={() => {}}
                        onSelectionChanged={onSelectionChanged}
                        isClipboardEnabled
                        moduleName="OrgSettings"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                {/* <Grid item xs={5}>
                    <Box className={classes.inTableHeader} sx={{ mt: '60px', mb: '25px' }}>
                        <Typography variant="h3" component="h3">
                            Attributes
                            <Button
                                className={classes.undoButtnNew}
                                variant="outlined"
                            >
                                <AddIcon />
                                {' '}
                                New
                            </Button>
                            <Button className={classes.btngray} variant="outlined" style={{ marginRight: '10px' }}>
                                <CloudDownloadOutlinedIcon />
                                {' '}
                                Import
                            </Button>
                        </Typography>
                    </Box>
                    <AgGridTable
                        columnDefs={COLUMN_ATTRIBUTES}
                        rowData={attribute}
                        changeSortingValue={() => {}}
                    />
                </Grid> */}
                {/* <Grid item xs={6} sx={{ marginLeft: '8%' }}>
                    <Box className={classes.inTableHeader} sx={{ mt: '60px', mb: '25px' }}>
                        <Typography variant="h3" component="h3">
                            Unit Measure
                            <Button
                                className={classes.undoButtnNew}
                                variant="outlined"
                            >
                                <AddIcon />
                                {' '}
                                New
                            </Button>
                            <Button className={classes.btngray} variant="outlined" style={{ marginRight: '10px' }}>
                                <CloudDownloadOutlinedIcon />
                                {' '}
                                Import
                            </Button>
                        </Typography>
                    </Box>
                    <AgGridTable
                        columnDefs={COLUMN_UNIT_MEASURE}
                        rowData={unitMeasureData}
                        changeSortingValue={() => {}}
                    />
                </Grid> */}
            </Grid>
            {/* Coding Structure Section   */}
            {/* <Box className={classes.inTableHeader} sx={{ mt: '60px', mb: '25px' }}>
                <Typography variant="h3" component="h3" sx={{ width: '71%' }}>
                    Coding Structure
                    <Button
                        variant="outlined"
                        className={classes.btngray}
                        sx={{ textTransform: 'inherit' }}
                    >
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </Button>
                </Typography>
            </Box> */}
            {/* <Box component="div" className={classes.boxWrapformat}> */}
            {/* <Box component="div" className={classes.bggray}>
                    <Grid container spacing={2} xs={12}>
                        {/* First Row */}
            {/* <Grid item justifyContent="center" xs={2}>
                            <Typography className={classes.forheading} variant="h6" component="h6" sx={{ marginTop: '38px !important' }}>
                                WBS Format

                            </Typography>
                        </Grid>
                        <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}>
                            <TextField
                                sx={{ marginTop: '35px !important' }}
                                className={classes.InputHeight}
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="3 Levels"
                                fullWidth
                                size="small"
                                disabled
                            />

                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" component="h6" className={classes.digitext}>Digits per levels</Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <TextField label="" className={classes.InputHeight} placeholder="2" size="small" disabled />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="" className={classes.InputHeight} placeholder="1" size="small" disabled />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="" className={classes.InputHeight} placeholder="1" size="small" disabled />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="" className={classes.InputHeight} placeholder="" size="small" disabled />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="" className={classes.InputHeight} placeholder="" size="small" disabled />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="" className={classes.InputHeight} placeholder="" size="small" disabled />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} sx={{ ml: '20px' }}>
                            <Typography variant="h6" component="h6" className={classes.digitext}>Separator</Typography>
                            <FormControl sx={{ m: 0, minWidth: 165 }} size="small">
                                <InputLabel id="demo-select-small-label">None</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    label=""
                                    sx={{ height: '41px' }}
                                    fullWidth
                                    disabled
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                            <Typography variant="body2" component="span" className={classes.exptext}>(Example : 0000)</Typography>
                        </Grid>
                    </Grid>
                </Box> */}
            {/* // <Box component="div" className={classes.bgwhite}>
                //     <Grid container spacing={2} xs={12}>
                //         {/* Second Row */}
            {/* //         <Grid item justifyContent="center" xs={2}>
                //             <Typography className={classes.forheading} variant="h6" component="h6" sx={{ marginTop: '38px !important' }}>
                //                 COA Format
                //             </Typography>
                //         </Grid>
                //         <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}>
                //             <TextField
                //                 sx={{ marginTop: '35px !important' }}
                //                 className={classes.InputHeight}
                //                 id="outlined-basic"
                //                 variant="outlined"
                //                 placeholder="3 Levels"
                //                 fullWidth
                //                 size="small"
                //                 disabled
                //             />
//
                //         </Grid>
                //         <Grid item xs={4}>
                //             <Typography variant="h6" component="h6" className={classes.digitext}>Digits per levels</Typography>
                //             <Grid container spacing={1}>
                //                 <Grid item xs={2}>
                //                     <TextField label="" className={classes.InputHeight} placeholder="2" size="small" disabled />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <TextField label="" className={classes.InputHeight} placeholder="1" size="small" disabled />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <TextField label="" className={classes.InputHeight} placeholder="1" size="small" disabled />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <TextField label="" className={classes.InputHeight} placeholder="" size="small" disabled />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <TextField label="" className={classes.InputHeight} placeholder="" size="small" disabled />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <TextField label="" className={classes.InputHeight} placeholder="" size="small" disabled />
                //                 </Grid>
                //             </Grid>
                //         </Grid>
                //         <Grid item xs={3} sx={{ ml: '20px' }}>
                //             <Typography variant="h6" component="h6" className={classes.digitext}>Separator</Typography>
                //             <FormControl sx={{ m: 0, minWidth: 165 }} size="small">
                //                 <InputLabel id="demo-select-small-label">Space</InputLabel>
                //                 <Select
                //                     labelId="demo-select-small-label"
                //                     id="demo-select-small"
                //                     label=""
                //                     sx={{ height: '41px' }}
                //                     fullWidth
                //                     disabled
                //                 >
                //                     <MenuItem value="">
                //                         <em>None</em>
                //                     </MenuItem>
                //                     <MenuItem value={10}>Ten</MenuItem>
                //                     <MenuItem value={20}>Twenty</MenuItem>
                //                     <MenuItem value={30}>Thirty</MenuItem>
                //                 </Select>
                //             </FormControl>
                //             <br />
                //             <Typography variant="body2" component="span" className={classes.exptext}>(Example : 00 00 00)</Typography>
                //         </Grid>
                //     </Grid>
                // </Box>
            // </Box>
            */}
            <AddOrganisationModal
                open={openModal}
                setOpenAddOrganisationModal={setOpenAddOrgModalCallback}
                selectedUser={selectedUser}
            />
        </div>
    );
}

export default UserOrganisationSettings;
