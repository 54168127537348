import React, {
    useEffect, useState, useRef, useCallback, useContext,
} from 'react';
import {
    Box, Button, Typography,
}
    from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import useStyles from './Organisations.style';
import organizationQueries from '../../../../queries/organisations';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Pagination from '../../../../components/paginationTable';
import AddOrganisationModal from '../../components/AddOrganisationModal';
import Loader from '../../../../components/Loader';
import { AgGridReact } from 'ag-grid-react';
import OrganisationDetailType from '../../../../types/OrganisationDetailType';
import NotificationBar from '../../../../components/NotificationBar';
import NotificationBarType from '../../../../types/NotificationBarType';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import { dateFormat } from '../../../../helper/DateFormat';
import userQueries from '../../../../queries/users';
import { UserContext } from '../../../../store/context/userContext';
import { clearOrgIdFromLocalStorage } from '../../../../helper/StorageHelper';
import InvitationType from '../../../../types/InvitationTypes';

function Organisations() {
    const classes = useStyles();
    const [openModal, setOpenAddOrganisationModal] = useState(false);
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [rowData, setRowData] = useState<any[]>([]);
    const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);
    const [selectedOrgName, setSelectedOrgName] = useState<string>('');
    const [totalPages, setTotalPages] = useState(0);
    const gridRef = useRef<AgGridReact<OrganisationDetailType>>(null);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const userContext = useContext(UserContext);

    const columnDefs = [
        {
            field: 'name', type: 'string', initialWidth: 450, headerName: 'Name', flex: 1.5,
        },
        {
            field: 'project_count', type: 'number', initialWidth: 130, headerName: 'No of Projects',
        },
        {
            field: 'user_count', type: 'number', initialWidth: 130, headerName: 'No of Users',
        },
        {
            field: 'updated_at', type: 'string', initialWidth: 150, headerName: 'Last Activity Date and Time',
        },
        {
            field: 'data_size', type: 'string', initialWidth: 130, headerName: 'Data Size',
        },
    ];

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetOrganisationList',
        organizationQueries.GET_ORGANISATION_LIST(pageSkipValue, DEFAULT_PAGE_SIZE),
    );

    useEffect(() => {
        refetch();
    }, [pageSkipValue]);

    const { data: allUserDetails, refetch: refetchUser } = useGQLQuery(
        'GetUserRoleFetchs',
        userQueries.GET_USER_DETAILS,
        {},
        {
            enabled: false,
            onSuccess: (tempData: InvitationType) => {
                userContext?.setUserDetails(tempData.getAllUserDetails);
            },
        },
        '/userAuthentication',
    );

    useEffect(() => {
        const orgListData = data?.getOrganizationList;
        const tableData = orgListData?.data;
        const pageInfo = orgListData?.pageInfo;
        const fetchedData = tableData ? tableData.map((item) => {
            const date = dateFormat(parseInt(item.updated_at, 10));
            return {
                ...item,
                updated_at: date,
            };
        }) : [];
        const pages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
        setTotalPages(pages);
        setRowData(fetchedData);
    }, [isFetching]);

    const handleClick = useCallback(() => {
        setOpenAddOrganisationModal(true);
    }, []);

    const handleDeleteSuccess = () => {
        // If current selected organisation is deleted, then start the useAuthentication flow
        const currentOrg = userContext?.user?.default_org_id;
        if (selectedOrgs.includes(currentOrg || '')) {
            // Clear local storage
            clearOrgIdFromLocalStorage();
            // Call userAuthentication
            // refetchUser();
        }
        refetch();
        refetchUser();
        setSelectedOrgs([]);
        setSelectedOrgName('');
        setNotificationDetails({
            open: true,
            message: 'Organisation successfully deleted.',
            type: 'success',
        });
    };

    const { mutate, isLoading } = useGQLMutation(
        'DeleteOrganisation',
        organizationQueries.DELETE_ORGANISATION(JSON.stringify(selectedOrgs)),
        {
            onSuccess: handleDeleteSuccess,
        },
    );

    const handleDelete = () => {
        setOpenDeleteModel(true);
    };

    const onSelectionChanged = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const selectedRows = gridApi?.getSelectedRows();
            if (selectedRows && selectedRows.length) {
                const selectedOrgIds = selectedRows.map((org) => org.id);
                setSelectedOrgs(selectedOrgIds);
                setSelectedOrgName(selectedRows[0]?.name);
            } else {
                setSelectedOrgs([]);
                setSelectedOrgName('');
            }
        }
    };

    const onDeleteConfirmation = () => {
        mutate({});
        setOpenDeleteModel(false);
    };

    const setOpenAddOrgModalCallback = useCallback((isOpen: boolean) => setOpenAddOrganisationModal(isOpen), []);

    return (
        <div>
            <div className={classes.TableHeader}>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h3" component="h3">
                        Organisation List
                    </Typography>
                    {/* <Box className={classes.versonUndo}>
                        <Box className={classes.outlineButton}>
                            <Button>
                                <DataSaverOffOutlinedIcon />
                                {' '}
                                Reports
                            </Button>
                        </Box>
                    </Box> */}
                </Box>
                <Box className={classes.versonUndo}>
                    {!selectedOrgs.length && (
                        <Button
                            className={classes.undoButtnNew}
                            variant="outlined"
                            onClick={() => handleClick()}
                        >
                            <AddIcon />
                            {' '}
                            New
                        </Button>
                    )}
                    {
                        !!selectedOrgs.length && (
                            <Button
                                variant="outlined"
                                className={classes.redBtn}
                                onClick={() => handleDelete()}
                            >
                                <DeleteOutlineOutlinedIcon />
                                {' '}
                                Delete
                            </Button>
                        )
                    }
                </Box>

            </div>
            <Box sx={{ mt: '30px' }}>
                <div>
                    <Box className={classes.tableSec}>
                        <AgGridComponent
                            gridRef={gridRef}
                            columnDefs={columnDefs}
                            rowData={rowData}
                            changeSortingValue={() => {}}
                            onSelectionChanged={onSelectionChanged}
                            moduleName="OrgList"
                        />
                    </Box>
                </div>
            </Box>
            <Loader loading={isFetching || isLoading} />
            <AddOrganisationModal open={openModal} setOpenAddOrganisationModal={setOpenAddOrgModalCallback} refetchList={refetch} />
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
            {openDeleteModel && (
                <DeleteRowModel
                    open={openDeleteModel}
                    setOpenDelRow={setOpenDeleteModel}
                    deleteAction={onDeleteConfirmation}
                    orgName={selectedOrgName}
                    title="Organization"
                />
            )}
        </div>
    );
}

export default Organisations;
