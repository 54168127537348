import React from 'react';
import DonutChartStyle from './DonutChart.style';
import {
    PieChart, Pie, Cell, Legend, ResponsiveContainer,
} from 'recharts';
import { useDisplayCurrFormatter } from '../../hooks/useFormatCurrency';

interface DonutData {
    label: string,
    value: number,
}

interface CellDataType {
    chartData?: DonutData [],
    innerRd?: number,
    outerRd?: number,
    inHeader?: boolean,
    height?: number,
}

function DonutChart({
    chartData, innerRd, outerRd, inHeader, height,
}: CellDataType) {
    const colorArr = ['#4E73BE', '#DF8244', '#7EAA55', '#A5A5A5', '#937424', '#699AD0', '#f6c142'];
    const headerBarColors = ['#35B83D', '#484DFF'];
    const classes = DonutChartStyle();
    const chartLegend = !inHeader;
    const innrRadi = innerRd || 70;
    const outerRadi = outerRd || 90;
    const centerCoordinate = '50%';
    const formatCurrency = useDisplayCurrFormatter();

    // inHeader Flag used to apply styles in header bar donut chart
    const colors = inHeader ? headerBarColors : colorArr;

    return (
        <div className={inHeader ? classes.headerBarchartStyle : classes.chartSec}>
            {chartData && (
                <ResponsiveContainer width="100%" height={height}>
                    <PieChart>
                        <Pie
                            data={chartData}
                            dataKey="value"
                            nameKey="name"
                            cx={centerCoordinate}
                            cy="50%"
                            innerRadius={innrRadi}
                            outerRadius={outerRadi}
                            fill="#8884d8"
                        >
                            {chartData?.map((entry, index) => (
                                <Cell key={entry.value} style={{ outline: 'none' }} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        {chartLegend && chartData && (
                            <Legend
                                layout="vertical"
                                iconType="rect"
                                align="right"
                                verticalAlign="top"
                                payload={
                                    chartData?.map(
                                        (item, index) => ({
                                            id: item.label,
                                            type: 'square',
                                            value: `${item?.label} - ${formatCurrency(+(item.value))}`,
                                            color: colors[index % colors.length],
                                        }),
                                    )
                                }
                            />
                        )}
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default DonutChart;
