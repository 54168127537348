import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    inTableHeader: {
        // display: 'block',
        // alignItems: 'center',
        // '& p': {
        //     margin: '0px',
        // },
        '& h3': {
            fontWeight: '700',
            fontSize: '22px',
            color: '#1c1c1c',
            marginTop: '8px',
        },
        // '& button': {
        //     float: 'right',
        //     position: 'relative',
        //     top: '-5px',
        //     textTransform: 'none',
        //     fontSize: '13px',
        //     padding: '3.4px 10px',
        // },
        // '& svg': {
        //     fontSize: '20px',
        //     marginRight: '5px',
        // },
    },
    undoButtnNew: {
        fontSize: '12px',
        color: '#fff !important',
        border: '1px solid #007DFF !important',
        borderRadius: '4px',
        backgroundColor: '#007DFF !important    ',
        padding: '5px 10px !important',
        cursor: 'pointer',
        '& svg': {
            color: '#fff !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    delete: {
        color: '#B00000 !important',
        borderColor: '#B00000 !important',
        '& svg': {
            color: '#B00000 !important',
        },
    },
    disable: {
        backgroundColor: '#E8E8E8 !important',
        '& input.Mui-disabled': {
            WebkitTextFillColor: '#353535',
        },
    },
    formwrap: {
        '& h6': {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Inter-Medium',
            marginBottom: '4px',
            color: '#353535',
        },
        '& input': {
            height: '23.1px',
            color: '#353535',
            fontSize: '12px',
            fontWeight: '500',
            fontFamily: 'Inter-Medium',
            '& .MuiOutlinedInput-input.Mui-disabled': {
                color: 'red !important',
            },
        },
    },
    btngray: {
        color: '#787878 !important',
        borderColor: '#787878 !important',
        '& svg': {
            color: '#787878 !important',
        },
    },
}));
