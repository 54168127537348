import React, { useState, useEffect, useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { UserInformation } from './userInformation';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import united from '../../assets/united.svg';
import cad from '../../assets/cad.svg';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import NavbarStyle from './Navbar.style';
import NavbarTitle from './NavbarTitle';
import NavbarSelect from './NavbarSelect';
import UndoIcon from '@mui/icons-material/Undo';
import { PATH_RESTRICTED_CONFIG } from '../../Routes/path';
import { useLocation } from 'react-router-dom';
import { ProjectContext } from '../../store/context/projectContext';

const versions = [
    {
        label: 'VERSION 2.1',
        value: 2,
    },
    {
        label: 'Ten',
        value: 10,
    },
    {
        label: 'Twenty',
        value: 20,
    },
    {
        label: 'Thirty',
        value: 30,
    },
];

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

type Props = {
    open: boolean,
    setOpen: (open: boolean) => void,
};

function Navbar(props: Props) {
    const { open, setOpen } = props;
    const classes = NavbarStyle();
    const [version, setVersion] = useState('2');
    const [showProjectTitle, setShowProjectTitle] = useState<boolean>(false);
    const currentPath = useLocation();
    const projectContext = useContext(ProjectContext);

    const handleVersionChange = (event: SelectChangeEvent) => {
        setVersion(event.target.value);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        const path = currentPath?.pathname?.split('/')[1];
        const { show_submenus: showSubmenus } = PATH_RESTRICTED_CONFIG;
        if (showSubmenus.includes(`/${path}`)) {
            setShowProjectTitle(true);
        } else {
            setShowProjectTitle(false);
        }
    }, [currentPath]);

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Box className={classes.headerSectionLeft}>
                    {showProjectTitle && <NavbarTitle />}
                    <Box className={classes.versonUndo}>
                        {/* <NavbarSelect value={version} handleChange={handleVersionChange} items={versions} disabled /> */}
                        {/* <Box className={classes.undoButtn}>
                            <button type="button">
                                <UndoIcon />
                                Undo
                            </button>
                        </Box> */}
                    </Box>
                </Box>
                <Box className={classes.selectBoxFlag}>
                    { projectContext?.projectCurrencyData?.getprojectCurrency && showProjectTitle
                        ? <NavbarSelect css={classes.selectBoxFlagData} />
                        : null }
                    {/* <Box className={classes.notificationStl}>
                        <NotificationsNoneIcon />
                    </Box> */}
                </Box>
                <UserInformation />
            </Toolbar>
        </AppBar>
    );
}
export default Navbar;
