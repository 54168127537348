import React, {
    useState, useContext, useEffect,
} from 'react';
import { Box } from '@mui/material';
import ProjectsMapViewStyle from './ProjectsMap.style';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import projectQuery from '../../../../queries/projects';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import ProjectListType, { ProjectCellRendererType } from '../../../../types/ProjectListType';
import { UserContext } from '../../../../store/context/userContext';
import Loader from '../../../../components/Loader';
import {
    Link, useNavigate, useLocation, useOutletContext,
} from 'react-router-dom';
import { ProjectContext } from '../../../../store/context/projectContext';
import { PATH_DATABASE } from '../../../../Routes/path';
import Pagination from '../../../../components/paginationTable';
import AgGridComponent from '../../../../components/agGridTableComponent';
import MapView from '../../Components/MApView';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { ValueFormatterParams } from 'ag-grid-community';

interface Iprops {
    searchedText?: string;
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;

}

interface LocationState {
    state ?: {
        industryId?: string | undefined;
        regionId?: string | undefined;
        country?: string | undefined
        industry?: string | undefined;
    }
}

function CustomGroupRowRenderer(params: ValueFormatterParams) {
    const { node } = params;
    if (node) {
        const rowNode = node;
        const style = {
            height: '100%',
            fontSize: '13px',
            fontWeight: 500,
            color: '#353535',
            lineHeight: '27px',
            padding: '0px 10px',
        };
        return (
            <div>
                <span style={style}>
                    {rowNode.key}
                </span>
            </div>
        );
    }
}

function ProjectsMapView(props: Iprops) {
    const { searchedText, setSearchText } = props;
    const classes = ProjectsMapViewStyle();
    const [pageSkipValue, setPageSkipValue] = useState(0);

    const userCtx = useContext(UserContext);
    const navigate = useNavigate();
    const projectCtx = useContext(ProjectContext);
    const curLocation:LocationState = useLocation();
    const cont: OutletContext = useOutletContext();

    const organizationId = userCtx?.user?.default_org_id;
    const selectedIndustry = curLocation?.state?.industryId;
    const selectedRegion = curLocation?.state?.regionId;
    const country = curLocation?.state?.country;
    const industry = curLocation?.state?.industry;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleLink = (project: ProjectListType) => {
        projectCtx?.setProject(project);
        navigate(PATH_DATABASE.projectDashboard);
    };

    const nameCellRenderer = (params: ProjectCellRendererType) => {
        const { value } = params;
        const colName = params.colDef.field;
        let date = params.data?.base_date;
        date = date && date.toString();
        const formattedDate = date && date.split('-')[0];
        if (colName != null) {
            switch (colName) {
            case 'name':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params.data);
                        }}
                    >
                        {value}

                    </Link>
                );
            case 'base_date':
                return formattedDate;
            default:
                return value;
            }
        }
        return '';
    };

    const COLUMN_DEFS = [
        {
            field: 'client_number',
            type: 'string',
            initialWidth: 180,
            headerName: 'Project Number',
            cellRendererFramework: nameCellRenderer,
            enableValue: false,
        },
        {
            field: 'name',
            type: 'string',
            initialWidth: 350,
            headerName: 'Name',
            cellRendererFramework: nameCellRenderer,
            enableValue: false,
        },
        {
            field: 'estimate_type', type: 'string', initialWidth: 150, headerName: 'Estimate Type',
        },
        {
            field: 'industry.description',
            type: 'string',
            initialWidth: 180,
            headerName: 'Industry',
            cellRendererFramework: nameCellRenderer,
            enableValue: false,
        },
        {
            field: 'subindustry.description',
            type: 'string',
            initialWidth: 180,
            headerName: 'Sub-Industry',
            cellRendererFramework: nameCellRenderer,
            enableValue: false,
        },
        {
            field: 'country.name',
            type: 'string',
            initialWidth: 180,
            headerName: 'Country',
            cellRendererFramework: nameCellRenderer,
            initialRowGroup: true,
            enableValue: false,
        },
        {
            field: 'region', type: 'string', initialWidth: 180, headerName: 'Region',
        },
        {
            field: 'base_date', type: 'string', initialWidth: 150, headerName: 'Base Date', cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'consultant', type: 'string', initialWidth: 150, headerName: 'Consultant',
        },
        {
            field: 'owner', type: 'string', headerName: 'Owner', initialWidth: 200,
        },
    ];

    const { data, isFetching, refetch } = useGQLQuery(
        'GetProject',
        projectQuery.GET_PROJECTS(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            '',
            '',
            userCtx?.user?.default_org_id || '',
            selectedIndustry || '',
            selectedRegion || '',
        ),
        {},
        { onError: handleApiError },
    );

    useEffect(() => {
        refetch();
    }, [pageSkipValue, organizationId]);

    const projectData = data?.project;
    const tableData = projectData?.data;
    const pageInfo = projectData?.pageInfo;
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    const rowData = tableData && tableData.length ? tableData : [];

    const changeSortingValue = (str: string) => {};

    // Fetch new page data
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    return (
        <div>
            <Loader loading={isFetching} />
            {(selectedRegion || selectedIndustry) && (
                <Box className={classes.filterDesc}>
                    <span>Data filtered by: </span>
                    <span>
                        {country || industry }
                    </span>
                </Box>
            )}
            <Box className={classes.mapBox}>
                <MapView
                    mapData={rowData}
                />
            </Box>
            <Box className={classes.projectMapWidth}>
                <AgGridComponent
                    columnDefs={COLUMN_DEFS}
                    rowData={rowData}
                    changeSortingValue={changeSortingValue}
                    noSelection
                    isRangeSelectable
                    isGroupable
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    quickFilterText={searchedText}
                    moduleName="ProjectsMapView"
                    disableResizable
                    setSearchText={setSearchText}
                    defaultExpanded={5}
                    customGroupRowRenderer={CustomGroupRowRenderer}
                />
            </Box>
            {/* <Box><Pagination totalPages={totalPages} handlePageChange={handlePageChange} /></Box> */}
        </div>
    );
}

export default ProjectsMapView;
