import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, FormControl, MenuItem, Typography, DialogActions, DialogContent, DialogTitle,
    Dialog, TextField, Grid, Button, Select, SelectChangeEvent,
} from '@mui/material';
import NewProjectDialogStyle from './NewProjectModel.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface InviteProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}

function NewProjectDialog({ open, setOpen }: InviteProps) {
    const classes = NewProjectDialogStyle();
    const [country, setCountry] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setCountry(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open} onClose={handleClose}>
                <DialogTitle className={classes.dilogBoxSection}>Create Project</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Project Number
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Name*
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Consultant
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Client
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Owner
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Client Project Number
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Estimate Type
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Target Accuracy
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Version
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Base Date*
                            </Typography>
                            <TextField type="date" fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Country
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={country}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="" />
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Region
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Currency*
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Location
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Latitude
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Longitude
                            </Typography>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Industry
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={country}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="" />
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Sub-Industry
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={country}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="" />
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={2} className={classes.sectionTwoBody}>
                        <Grid item xs={5}>
                            <Typography variant="subtitle2" className={classes.labelTextFirst}>
                                Digits per level
                            </Typography>
                            <Box className={classes.sideTextInput}>
                                <Typography variant="body2" className={classes.labelSideText}>
                                    COA Format*
                                </Typography>
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Seperator
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                                <Select
                                    value={country}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Example
                            </Typography>
                            <Typography className={classes.mainTextLabel}>
                                000000
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="subtitle2" className={classes.labelTextFirst}>
                                Digits per level
                            </Typography>
                            <Box className={classes.sideTextInput}>
                                <Typography variant="body2" className={classes.labelSideText}>
                                    WBS Format*
                                </Typography>
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                                <TextField className={classes.smText} color="secondary" />
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Seperator
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: '100%', margin: 0 }}>
                                <Select
                                    value={country}
                                    onChange={handleChange}
                                    displayEmpty
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                >
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Example
                            </Typography>
                            <Typography className={classes.mainTextLabel}>
                                00 00 00
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default NewProjectDialog;
