import { useLocation, useNavigate } from 'react-router-dom';

export default function useCustomNavigate() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (to: string, replace = false, state = {}) => {
        if (!replace) {
            navigate(to, { state: { ...state, from: location.pathname } });
        } else {
            navigate(to, { replace });
        }
    };

    return handleNavigation;
}
