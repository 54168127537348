import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import {
    Box, Button, Grid, TextField, Typography,
    Container, DialogActions, DialogContent,
}
    from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddCurrencyStyle from './AddCurrency.style';
import { ADD_CURRENCY } from '../../../../queries/projectCurrency';
import { UserContext } from '../../../../store/context/userContext';
import { useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import ProjectCurrencyType from '../../../../types/ProjectCurrencyType';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { ProjectContext } from '../../../../store/context/projectContext';
import NotificationBarType from '../../../../types/NotificationBarType';
import NotificationBar from '../../../../components/NotificationBar';
import Loader from '../../../../components/Loader';

function AddCurrencyModal(props: {
    refetchProject : ()=> void
    currency: boolean;
    setCurrency: (currency: boolean) => void;
    setEditItem: (editItem: ProjectCurrencyType | undefined) => void;
  }): React.JSX.Element {
    /* State Declaraton */
    // Class & Props
    const classes = AddCurrencyStyle();
    const {
        currency, setCurrency, refetchProject, setEditItem,
    } = props;

    // Context
    const cont: OutletContext = useOutletContext();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);

    // Input Values
    const currencyCode = useRef<HTMLInputElement>(null);
    const currencyName = useRef<HTMLInputElement>(null);
    const currencyExchangeRate = useRef<HTMLInputElement>(null);
    const currencySymbol = useRef<HTMLInputElement>(null);

    const [, rerenderComponent] = useState(0);

    // On AnyInputChange
    const [inputError, setInputError] = useState('');

    // Below Notification can be handeled by cont-context.
    const [notificationDetails, setNotificationDetails] = React.useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });

    // Validate Input
    const validateInput = () => {
        let error = '';
        const code = currencyCode.current?.value;
        const codeLength = code?.length;
        if (!(codeLength && codeLength === 3 && code === code.toUpperCase())) {
            error = 'Error in Code field, length of code should be 3 and all characters in upper case';
            return error;
        }
        const nameLength = currencyName.current?.value.length;
        if (!(nameLength && nameLength > 3)) {
            error = 'Error in Name field, length should be greater than 3';
            return error;
        }
        const exchangeRate = currencyExchangeRate.current?.value;
        const exchangeRateLength = exchangeRate?.length;
        if (!(exchangeRateLength && Number(exchangeRate) && Number(exchangeRate) > 0)) {
            error = 'Error in Exchange Rate field, value should be numeric and greater than 0';
            return error;
        }
        const symbolLength = currencySymbol.current?.value.length;
        if (!(symbolLength && symbolLength >= 1)) {
            error = 'Error in Symbol field, symbol should contain atleast 1 character';
        }
        return error;
    };

    // API operations.
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };
    const handleAddCurrency = () => {
        refetchProject();
        setEditItem(undefined);
        setNotificationDetails({
            open: true,
            message: 'Currency added successfully.',
            type: 'success',
        });
    };
    const { mutate: AddCurrency, isLoading: isAdding } = useGQLMutation(
        'AddCurrency',
        ADD_CURRENCY(
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            currencyCode.current?.value || '',
            currencyName.current?.value || '',
            Number(currencyExchangeRate.current?.value) || 0,
            currencySymbol.current?.value || '',
        ),
        {
            onSuccess: handleAddCurrency,
            onError: handleApiError,
        },
        '/setting',
    );

    const handlecurrClose = () => {
        // currencyCode.current = null;
        // currencyName.current = '';
        // currencyExchangeRate.current = 0;
        // currencySymbol.current = '';
        setInputError('');
        setCurrency(false);
    };

    const handelCurrSave = () => {
        const error = validateInput();
        if (!error) {
            AddCurrency({});
            setCurrency(false);
        } else {
            setInputError(error);
        }
    };

    useEffect(() => {
        if (isAdding) return;
        setCurrency(false);
    }, [isAdding]);

    const inputChangedFn = () => {
        const error = validateInput();
        if (!error && inputError) {
            setInputError('');
        }
        // fire Event to rerender a component when input is changed.
        rerenderComponent(Math.random());
    };

    const isSaveDisabled = !(currencyCode.current?.value && currencyName.current?.value && currencyExchangeRate.current?.value
    && currencySymbol.current?.value);

    return (
        <div>
            <Loader loading={isAdding} />
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={currency}>
                <DialogTitle className={classes.dilogBoxSection}>Add Currency</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Container>
                        <Grid className={classes.addPopup} container spacing={4} sx={{ pt: '10px' }}>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Code</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    inputRef={currencyCode}
                                    label=""
                                    inputProps={{ maxLength: 3 }}
                                    className={classes.white}
                                    onChange={() => inputChangedFn()}
                                    placeholder="Currency Code"
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Name</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    className={classes.white}
                                    inputRef={currencyName}
                                    inputProps={{ maxLength: 40 }}
                                    onChange={() => inputChangedFn()}
                                    placeholder="Currency Name"
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Rate</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    className={classes.white}
                                    type="text"
                                    inputRef={currencyExchangeRate}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={() => inputChangedFn()}
                                    placeholder="Numeric Exchange Rate"
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Symbol</Typography>
                                <TextField
                                    fullWidth
                                    size="small"
                                    className={classes.white}
                                    type="text"
                                    inputRef={currencySymbol}
                                    inputProps={{ maxLength: 3 }}
                                    onChange={() => inputChangedFn()}
                                    placeholder="Currency Symbol"
                                />
                            </Grid>
                            {/* <Grid xs={12} md={12}>
                                <Typography component="span" variant="body2">
                                    <input type="checkbox" className={classes.checkbox} onChange={handleCheckboxChange} />
                                    Set as Base Currency
                                </Typography>
                            </Grid> */}
                        </Grid>
                        <span className={classes.errorMsg}>{inputError}</span>
                    </Container>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handlecurrClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button
                            variant="contained"
                            onClick={handelCurrSave}
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
        </div>
    );
}

export default AddCurrencyModal;
