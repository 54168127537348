type Code = number | string | undefined;

const getProjectLevel = (codeArr: Code[]) => {
    let levelCount = 0;
    codeArr.forEach((code) => {
        if (code !== 0) levelCount += 1;
    });
    return levelCount;
};

export const getIndustryLevel = (codeArr: Code[]) => {
    let levelCount = 0;
    codeArr.forEach((code) => {
        if (code == null || code === undefined || code === '') return;
        levelCount += 1;
    });
    return levelCount;
};

export default getProjectLevel;
