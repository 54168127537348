import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Button, IconButton, DialogActions,
} from '@mui/material';
import AgGridTable from '../../../../components/agGridTable';
import CloseIcon from '@mui/icons-material/Close';
import DeleteProjectModelStyle from './DeleteProjectModel.style';

interface InviteProps {
    open: boolean,
    setOpenDelProject: Dispatch<SetStateAction<boolean>>,
}

function DeleteProjectModel({ open, setOpenDelProject }: InviteProps) {
    const classes = DeleteProjectModelStyle();

    const handleClose = () => {
        setOpenDelProject(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open} onClose={handleClose}>
                <DialogTitle className={classes.dilogBoxSection}>Remove Projects</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={classes.dialogBody}>
                    <Typography className={classes.subHeader} variant="subtitle2">
                        Select Projects to Remove them to the Benchmark
                    </Typography>
                    <Box className={classes.projectListMain}>
                        <AgGridTable />
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledrmButton}>
                        <Button variant="contained" color="primary">
                            Remove
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DeleteProjectModel;
