import { makeStyles } from '@mui/styles';

const mapStyles = makeStyles(() => ({
    mapStyles: {
        '& .leaflet-container': {
            width: '100%',
            height: '40vh !important',
        },
        '& .leaflet-marker-icon': {
            border: 'unset',
        },
    },
    tooltipContainer: {
        backgroundColor: '#DA6970',
        border: '0.5px solid #DA6970',
        margin: 'unset',
        padding: '5px',
        '& h6': {
            color: '#fff',
            fontSize: '12px',
            fontWeight: 600,
        },
    },
}));

export default mapStyles;
