import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ProjectContext } from '../../store/context/projectContext';

function NavbarTitle() {
    const projectContext = useContext(ProjectContext);
    const {
        name, estimate_type: estimateType, region, country_name: country, owner,
    } = projectContext?.project || {};

    return (
        <Box>
            <Typography variant="h2" noWrap>
                {/* {!projectQuery.isLoading && project?.name} */}
                { name }
            </Typography>
            <Typography variant="body1" noWrap>
                {/* {!projectQuery.isLoading && project?.owner}, {!projectQuery.isLoading && project?.country} */}
                {estimateType}
                {' '}
                /
                {' '}
                {owner}
                {' '}
                /
                {region}
                ,
                {' '}
                {country}
            </Typography>
        </Box>
    );
}
export default NavbarTitle;
