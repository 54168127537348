import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import '@sencha/best-react-grid/dist/themes/grui.css';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Loader from '../../../../components/Loader';
import coaQueries from '../../../../queries/coa';
import Pagination from '../../../../components/paginationTable';
import COADifferent from './COADifferent.style';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { useOutletContext } from 'react-router-dom';
import TotalValueType from '../../../../types/TotalValueType';
import CoaColumn from './CoaColumn';
import { truthyCodeValidation } from '../../../../helper/validations';
import { COADataTypeExtended } from '../../../../types/CoaType';
import { ColumnDefs } from '../../../../types/AgGridTypes';

interface Props {
    getDefTotalValue: (totalValue: TotalValueType) => void,
    view?: string,
    searchText?: string
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

function CoaDefaultViews(props: Props) {
    const {
        view, getDefTotalValue, searchText, setSearchText, hideUnusedFlag, setIsFetching,
    } = props;
    const classes = COADifferent();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();
    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefs[]>([]);

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    // Function for total bottom row
    const createData = (params: TotalValueType) => [
        {
            description: 'TOTAL',
            labour_hours: params?.labour_hoursTotal === null ? '-' : (+params.labour_hoursTotal.toFixed(0)),
            labour: params?.labourTotal,
            // distributables: params?.distributableTotal,
            cons_equipment: params?.c_equipmentTotal,
            material: params?.materialTotal,
            // sustaining_cost: params?.sustaining_total,
            equipment: params?.equipmentTotal,
            subcontract: params?.subcontractTotal,
            indirect: params?.indirectTotal,
            total_cost: params?.total_costTotal,
        },
    ];

    // Fetch new page data
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    // Fetch API data
    const { data, isFetching, refetch } = useGQLQuery(
        `GetCoaInfo-${projectCtx?.project?.id || 0}`,
        coaQueries.GET_COA_INFO(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            '',
            '',
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
        {},
        { onError: handleApiError },
    );
    // For COA Default View...
    const coaData = data?.coa;
    const tableData = coaData?.data;
    const pageInfo = coaData?.pageInfo;
    const totalValue = coaData?.total_values;

    // For Bottom Total Value...
    let pinnedBottomRowData;
    if (totalValue) {
        pinnedBottomRowData = createData(totalValue);
    }

    useEffect(() => {
        if (totalValue) getDefTotalValue(totalValue);
    }, [totalValue]);

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const columnDefs = [
        {
            field: 'code',
            headerName: 'COA',
            cellRenderer: CoaColumn,
            sort: 'asc',
            initialWidth: 120,
            type: 'string',
        },
        {
            field: 'description',
            headerName: 'Account Description',
            cellRenderer: CoaColumn,
            initialWidth: 400,
            type: 'string',
        },
        {
            field: 'labour_hours',
            headerName: 'Labour Hours',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'labour',
            headerName: 'Labour',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 190,
            type: 'numericColumn',
        },
        {
            field: 'cons_equipment',
            headerName: 'C.Equipment',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'material',
            headerName: 'Material',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'subcontract',
            headerName: 'Sub-Contract',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
        },
        {
            field: 'equipment',
            headerName: 'Equipment',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 190,
            type: 'numericColumn',
        },
        {
            field: 'indirect',
            headerName: 'Indirect',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 190,
            type: 'numericColumn',
        },
        {
            field: 'total_cost',
            headerName: 'Total',
            cellRenderer: CoaColumn,
            initialHide: view !== 'Default View',
            initialWidth: 150,
            type: 'numericColumn',
            enableValue: true,
        },
    ];

    const generateColumnDefs = () => {
        ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].forEach((code, index) => {
            const flag = (tableData?.map((item) => ((item as COADataTypeExtended)[code])))?.some(truthyCodeValidation);
            if (flag) {
                columnDefs.push({
                    field: code,
                    headerName: `Code${index + 1}`,
                    initialWidth: 120,
                    cellRenderer: CoaColumn,
                    type: 'string',
                    initialHide: flag,
                    enableValue: false,
                });
            }
        });
        setNewColumnDefs(columnDefs);
    };

    useEffect(() => {
        setIsFetching(isFetching);
        generateColumnDefs();
    }, [isFetching]);

    const rowData = tableData ? tableData.map((item) => ({
        ...item,
    })) : [];
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    return (
        <div>
            {/* Page Header Start */}
            <Loader loading={isFetching} />
            <Box className={isFetching ? classes.opacityBlur : ''}>
                <AgGridComponent
                    columnDefs={newColumnDefs}
                    rowData={rowData}
                    changeSortingValue={() => { }}
                    pinnedBottomRowData={pinnedBottomRowData}
                    isClipboardEnabled
                    isExportEnabled
                    isPinnable
                    isRangeSelectable
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    isGroupable
                    CoaRowBgStyle
                    moduleName="coa_default"
                    colFormat={['labour', 'cons_equipment', 'material', 'subcontract', 'equipment', 'indirect', 'total_cost']}
                    footerFormat={['labour', 'cons_equipment', 'material', 'subcontract', 'equipment', 'indirect', 'total_cost']}
                    disableResizable
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
            </Box>
        </div>
    );
}

export default CoaDefaultViews;
