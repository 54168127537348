import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Grid, Button, IconButton, TextField, DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import restoreVersionStyle from './RestoreVersion.style';

interface InviteProps {
    open: boolean,
    setOpenVersion: Dispatch<SetStateAction<boolean>>,
    switchAction: () => void,
    code: string,
    name: string,
    prevCode: string,
}

function RestoreVersionModal({
    open, setOpenVersion, switchAction, code, name, prevCode,
}: InviteProps) {
    const classes = restoreVersionStyle();

    const handleClose = () => {
        setOpenVersion(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Restore</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                This action will save the current state of the estimate as Version
                                {` ${prevCode}`}
                                , and restore Version
                                {` ${code}`}
                                . The saved version
                                {` ${prevCode} `}
                                will not be overwritten.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Code
                            </Typography>
                            <TextField fullWidth color="secondary" value={code} disabled />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Name
                            </Typography>
                            <TextField fullWidth color="secondary" value={name} disabled />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={switchAction}>
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default RestoreVersionModal;
