import { makeStyles } from '@mui/styles';

const EditGenralStyle = makeStyles(() => ({
    undoButtnNew: {
        marginLeft: '15px',
        '& button': {
            fontSize: '14px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            fontWeight: '700',
            textTransform: 'none',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '5px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    popheight: {
        height: '500px',
    },
    white: {
        backgroundColor: '#fff',
    },
    datepic: {
        '& svg': {
            fontSize: '15px',
        },
    },
    addPopup: {
        paddingTop: '38px !important',
        width: 'calc(100% + 16px) !important',
        marginLeft: '-8px !important',
        paddingBottom: '20px',
        '& > div': {
            padding: '15px 10px',
        },
        '&  span': {
            fontSize: '16px',
        },
        '&  input': {
            height: '23px',
        },
    },

}));
export default EditGenralStyle;
