import React, { useContext, useState } from 'react';
import useStyles from './ResourcesHeader.style';
import {
    Box, Button, Typography, FormControl, MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddEditResources from '../AddEditResources';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import HideUnusedButton from '../../../../components/HideUnusedButton';
import exportExcel from '../../../../helper/ExportExcel';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';

interface Props {
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setHideUnusedFlag: React.Dispatch<React.SetStateAction<boolean>>;
    isResourceFetching: boolean;
    unitCostId?: string,
}

function ResourcesHeader(props: Props) {
    const {
        searchText, setSearchText, hideUnusedFlag, setHideUnusedFlag, isResourceFetching, unitCostId,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState('DefaultView');
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=resources`);
    };
    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };
    const handleClear = () => {
        setSearchText('');
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'resource',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    RESOURCE
                </Typography>
                <CostHeaderBar />
                <Box className={classes.outlineButton}>
                    <Button onClick={navigateToReports}>
                        <DataSaverOffOutlinedIcon />
                        {' '}
                        Reports
                    </Button>
                </Box>
                <QuickSearchFilter searchText={searchText} setSearchText={setSearchText} />
                {!searchText && !unitCostId
                 && <HideUnusedButton hideUnusedFlagVal={hideUnusedFlag} setHideUnusedFlag={setHideUnusedFlag} loadingData={isResourceFetching} />}
                {/* <Typography variant="h6">
                    <div className={classes.chartStyle}>
                        <span>Direct &nbsp; $351783006</span>
                        &nbsp;&nbsp;&nbsp;
                        <span>Indirect &nbsp; $351783006</span>
                        &nbsp;&nbsp;&nbsp;
                        <span>Total &nbsp; $351783006</span>
                        &nbsp;&nbsp;&nbsp;
                        <img src={capexIcon} alt="icon" />
                    </div>
                </Typography> */}
                {/* <Box className={classes.rightSection}>
                    <Box className={classes.outlineButton}>
                        <Button>
                            <BarChartIcon />
                            {' '}
                            Benchmark
                        </Button>
                    </Box>
                    <Box className={classes.outlineButton}>
                        <Button>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    <FormControl variant="filled">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={view}
                            label="view"
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            color: '#787878',
                                        },
                                        '& .Mui-selected': {
                                            color: '#021948',
                                            bgcolor: '#E6ECFA !important',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value="DefaultView">Default View</MenuItem>
                        </Select>
                    </FormControl>
                </Box> */}
            </Box>
            <Box className={classes.rightSection}>
                {/* <Box className={classes.outlineButton}>
                    <Button disabled>
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </Button>
                </Box> */}
                <Box className={classes.outlineButton}>
                    <Button onClick={handleExportExcel}>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box>
                {/* <Box className={classes.deleteButton}>
                    <Button>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </Button>
                </Box>
                <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </Button>
                </Box>
                {open && <AddEditResources open={open} setOpen={setOpen} />} */}
            </Box>
        </div>
    );
}

export default ResourcesHeader;
