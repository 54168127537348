import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import {
    Container,
    DialogActions,
    DialogContent,
} from '@mui/material';
import EditCoaStyle from './EditCOA.style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function EditCOAModal(props: {
    open: boolean;
    setOpen: (open: boolean) => void;
    edit: boolean;
    setEdit: (edit: boolean) => void;
    }): React.JSX.Element {
    const classes = EditCoaStyle();
    const {
        open, setOpen, edit, setEdit,
    } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleCloseCoaEdit = () => {
        setEdit(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {/* Add COA Popup */}
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={edit}
                    onClose={handleCloseCoaEdit}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="lg"
                    PaperProps={{
                        sx: {
                            width: '50%',
                            minHeight: 500,
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">
                        <Typography variant="h5" component="h5">
                            Edit COA
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                        <Container>
                            <Grid container spacing={3} sx={{ pt: '10px' }}>
                                <Grid xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <Typography
                                            variant="h6"
                                            className={classes.font14}
                                            component="span"
                                        >
                                            Direct / Indirect*
                                        </Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label=""
                                            className={classes.whiteSel}
                                        >
                                            <MenuItem value={10}>Direct</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Typography
                                        variant="h6"
                                        className={classes.font14}
                                        component="span"
                                    >
                                        Code (Format: 00.00.00)*
                                    </Typography>
                                    <TextField
                                        label=""
                                        className={classes.white}
                                        fullWidth
                                        placeholder="*"
                                    />
                                </Grid>
                                <Grid xs={12} md={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.font14}
                                        component="span"
                                    >
                                        Title*
                                    </Typography>
                                    <TextField
                                        label=""
                                        className={classes.white}
                                        fullWidth
                                        placeholder="Lorem Ipsum"
                                    />
                                </Grid>
                                <Grid xs={12} md={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.font14}
                                        component="span"
                                    >
                                        Description
                                    </Typography>
                                    <TextField
                                        label=""
                                        className={classes.white}
                                        placeholder="*"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Typography
                                        variant="h6"
                                        className={classes.font14}
                                        component="span"
                                    >
                                        Productivity Factor
                                    </Typography>
                                    <TextField
                                        label=""
                                        className={classes.white}
                                        placeholder="*"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContent>
                    <DialogActions sx={{ mr: '15px' }}>
                        <Button
                            className={classes.popBtn}
                            autoFocus
                            onClick={handleCloseCoaEdit}
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                        >
                            Cancel
                        </Button>
                        <Box className={classes.undoButtnNew}>
                            <Button
                                className={classes.popBtn}
                                onClick={handleClose}
                                autoFocus
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default EditCOAModal;
