import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_PROJECTIFO_BY_ID = (projectId: string, OrgId: string) => gql`
query {
  project_setting (
    project_id: ${addEscapeCharacters(projectId)}
    org_id: ${addEscapeCharacters(OrgId)}
  ) {
      id
      base_date
      wbs1
      wbs2
      wbs3
      wbs4
      wbs5
      wbs6
      coa1
      coa2
      coa3
      coa4
      coa5
      coa6
      hide_values
      coa_sep
      name
      client_number
      region
      owner
      consultant
      consultant_number
      estimate_type
      estimate_accuracy
      contingency_wbs
      contingency_pct
      currency
      wbs_sep
      created_at
      updated_at
      project_number
      total_cost
      version_id
      version_code
      industry_id
      industry {
        description
      }
      subindustry_id
      subindustry {
        description
      }
      currency_id
      currency_data {
        id
        code
        exchange_rate
      }
      is_active
      country_name
      country {
        name
      }
      location
      latitude
      longitude
      permission_id
  }
}`;

export default { GET_PROJECTIFO_BY_ID };
