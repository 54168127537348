import React, { useContext } from 'react';
import { UserContext } from '../store/context/userContext';

const useIsSuperAdmin = () => {
    const userContext = useContext(UserContext);

    const userRole = userContext && userContext.user ? userContext.user.userRole : '';

    return (userRole === 'SuperAdmin');
};

export default useIsSuperAdmin;
