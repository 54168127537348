import React, {
    createContext, ReactNode, useState, useMemo,
} from 'react';

import { OrgUserType } from '../../types';

interface Props {
  children: ReactNode
}

interface UserContextType {
  user: OrgUserType | undefined,
  setUserDetails: (user: OrgUserType | undefined) => void,
}

const UserContext = createContext<UserContextType | null>(null);

function UserContextProvider(props: Props) {
    const { children } = props;
    const [user, setUserDetails] = useState<OrgUserType | undefined>();

    const defaultValue = useMemo(() => ({
        user,
        setUserDetails,
    }), [user, setUserDetails]);

    return (
        <UserContext.Provider value={defaultValue}>
            {children}
        </UserContext.Provider>
    );
}

export { UserContext };

export default UserContextProvider;
