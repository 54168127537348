import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

export const DELETE_CURRENCY = (id: string, project_id: string, org_id: string) => gql`
mutation DeleteCurrency {
  deleteProjectCurrency(
    input: {
      project_currency_id: ${addEscapeCharacters(id)},
      project_id: ${addEscapeCharacters(project_id)},
      org_id: ${addEscapeCharacters(org_id)}
    }
  ) {
    message
}
}
`;

export const ADD_CURRENCY = (Org_id: string, project_id: string, code:string, name:string, exchange_rate:number, symbol:string) => gql`
mutation AddCurrency {
  addCurrencyToProject(
    input: {
      project_id:  ${addEscapeCharacters(project_id)}
      org_id: ${addEscapeCharacters(Org_id)}
      code: ${addEscapeCharacters(code)}
      name: ${addEscapeCharacters(name)}
      exchange_rate: ${exchange_rate}
      symbol: ${addEscapeCharacters(symbol)}
    }
  ) {
    message
  }
}
`;

export const GET_CURRENCY = (Org_id: string, project_id: string) => gql`
query GetProjectCurrency{
  getprojectCurrency (
    org_id: ${addEscapeCharacters(Org_id)}
    project_id: ${addEscapeCharacters(project_id)}
  ) {
  id
  code
  name
  exchange_rate
  total_cost
  symbol
  isBaseCurrency
  }
}
`;
