import { RowData } from '../types/AgGridTypes';

interface DynamicType {
    [key: string]: string | number | null,
}

interface DynamicTypeForUnitCost {
    [key: string]: DynamicType[],
}

const formatRowData = (
    rowData?: RowData[],
    colFormat?: string[],
    exchangeRate?: number,
    curLocation?: { pathname: string },
    masterDetail?: boolean,
    masterDataColFormat?: string[],
): RowData[] => {
    let updatedRowData: RowData[] = [];
    if (colFormat && colFormat.length && exchangeRate && rowData && rowData.length) {
        updatedRowData = rowData?.map((obj) => {
            const newObj = {};
            Object.keys(obj).forEach((key) => {
                if (colFormat.includes(key)) {
                    // display all the columns in WBS, COA, Package, schedule in whole numbers
                    if (key.startsWith('column_') || curLocation?.pathname === '/schedule'
                        || curLocation?.pathname === '/packages' || curLocation?.pathname === '/coaList' || curLocation?.pathname === '/wbs') {
                        (newObj as DynamicType)[key] = (obj as DynamicType)[key] === null
                            ? null : +((+((obj as DynamicType)[key] as string | number) * exchangeRate).toFixed(0));
                    } else if (key === 'total_cost' || key === 'total' || key === 'cost_in_estimate') {
                        // display total column in whole numbers
                        (newObj as DynamicType)[key] = (obj as DynamicType)[key] === null
                            ? null : +((+((obj as DynamicType)[key] as string | number) * exchangeRate).toFixed(0));
                    } else if (masterDetail && key === 'unit_cost_data' && masterDataColFormat && masterDataColFormat.length) {
                        (newObj as DynamicTypeForUnitCost)[key] = (obj as DynamicTypeForUnitCost)[key].map((uData) => {
                            const newUnitObj = {};
                            Object.keys(uData).forEach((uDatakey) => {
                                if (masterDataColFormat.includes(uDatakey)) {
                                    if (uDatakey === 'total_cost' || uDatakey === 'total' || uDatakey === 'cost_in_estimate') {
                                        (newUnitObj as DynamicType)[uDatakey] = (uData)[uDatakey] === null
                                            ? null : +((+((uData)[uDatakey] as string | number) * exchangeRate).toFixed(0));
                                    } else {
                                        (newUnitObj as DynamicType)[uDatakey] = (uData)[uDatakey] === null
                                            ? null : +((+((uData)[uDatakey] as string | number) * exchangeRate).toFixed(2));
                                    }
                                } else {
                                    (newUnitObj as DynamicType)[uDatakey] = (uData)[uDatakey];
                                }
                            });
                            return newUnitObj;
                        });
                    } else {
                        // display all the column values fixed to 2 decimal place
                        (newObj as DynamicType)[key] = (obj as DynamicType)[key] === null
                            ? null : +((+((obj as DynamicType)[key] as string | number) * exchangeRate).toFixed(2));
                    }
                } else {
                    (newObj as DynamicType)[key] = (obj as DynamicType)[key];
                }
            });
            return newObj;
        });
    }
    return updatedRowData;
};

export default formatRowData;
