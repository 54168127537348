import React, {
    createContext, ReactNode, useState, useMemo,
} from 'react';

interface Props {
  children: ReactNode
}

interface LoginContextType {
  loginToken: string,
  setLoginToken: (token: string) => void,
}

const LoginContext = createContext<LoginContextType | null>(null);

function LoginContextProvider(props: Props) {
    const { children } = props;
    const [loginToken, setLoginToken] = useState<string>('');

    const defaultValue = useMemo(() => ({
        loginToken,
        setLoginToken,
    }), [loginToken, setLoginToken]);

    return (
        <LoginContext.Provider value={defaultValue}>
            {children}
        </LoginContext.Provider>
    );
}

export { LoginContext };

export default LoginContextProvider;
