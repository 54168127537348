import React from 'react';
import { Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme } from '@mui/material/styles';
import typography from './typography';
import breakpoints from './breakpoints';
import colorSchemes from './colorSchemes';
import '../css/style.css';

const theme = experimental_extendTheme({
    breakpoints,
    colorSchemes,
    typography,
});

type ThemeConfigProps = {
  children: React.ReactNode
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
    return (
        <CssVarsProvider theme={theme}>
            {children}
        </CssVarsProvider>
    );
}
