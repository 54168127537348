import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

// the sort hard coded is as per clients requirement in UAT feed back and should be removed in next phase

const GET_ESTIMATE_DETAILS = (
    skip: number,
    take: number,
    orgId: string,
    projectId: string,
    versionId: string,
    estimateFilter: string,
    searchText?: string,
    filters?: string,
    sortModel?: string,
    rowGroupModel?: string,
    groupKeys?: string,
    exchangeRate?: number,
) => {
    const searchTerm = searchText ? `searchTerm: ${addEscapeCharacters(searchText)}` : '';
    const filterVals = filters ? `filters: ${JSON.stringify(filters)}` : '';
    const sortVals = sortModel && sortModel !== '[]' ? `sortModel: ${JSON.stringify(sortModel)}` : '';
    const rowGroupVals = rowGroupModel ? `rowGroupModel: ${JSON.stringify(rowGroupModel)}` : '';
    const groupKeyVals = groupKeys ? `groupKeys: ${JSON.stringify(groupKeys)}` : '';
    const wbsId = estimateFilter ? `wbs_id: ${addEscapeCharacters(estimateFilter)}` : '';
    return gql`
    query GetEstimateDetails {
        estimate_detail(skip: ${skip}, take: ${100}, 
        ${searchTerm}, org_id: ${addEscapeCharacters(orgId)}, ${filterVals}, ${sortVals}, ${rowGroupVals},${groupKeyVals},
        exchangeRate: ${exchangeRate},
        project_id: ${addEscapeCharacters(projectId)}, 
        version_id: ${addEscapeCharacters(versionId)}
        ${wbsId}
        ) {
          pageInfo {
            skip
            take
            hasNextPage
            totalcount
          }
          data {
            estimatedData {
              id
              labels
              sequence
              description
              detailed_description
              quantity
              ur_code
              unit_cost
              total_unit_cost
              item_type
              total_cost
              wbs_id
              coa_id
              case_id
              phase_id
              package_labour_id
              package_construction_equip_id
              package_material_id
              package_permanent_equip_id
              package_subcontract_id
              package_indirect_id
              unit_rate_id
              item_type
              detailed_description
              base_quantity
              design_growth
              unit_of_measure
              wastage_factor
              prod_factor
              markup_labour
              markup_construction_equip
              markup_material
              markup_permanent_equip
              markup_subcontract
              unit_man_hours
              unit_labour_cost
              unit_consequip_cost
              unit_material_cost
              unit_pequipment_cost
              unit_subcontract_cost
              unit_indirect_cost
              labour_hours
              labour_cost
              construction_equip_cost
              material_cost
              permanent_equip_cost
              subcontract_cost
              indirect_cost
              wbs {
                code
                description
                wbs_level
                code1
                code2
                code3
                code4
                first_parent
                second_parent
                third_parent
                forth_parent
                fifth_parent
                self_code_desc
              }
              coa {
                code
                coa_level
                code1
                code2
                first_parent
                second_parent
                third_parent
                forth_parent
                fifth_parent
                self_code_desc
                description
              }
              case {
                code
                name
              }
              phase {
                code
                name
              }
              package_labour {
                code
              }
              package_construction {
                code
              }
              package_material {
                code
              }
              package_permanent {
                code
              }
              package_subcontract {
                code
              }
              package_indirect {
                code
              }
              unit_rate {
                code
              }
            }
            total {
              total_man_hours
              labour_cost
              construction_equip_cost
              material_cost
              equipment_cost
              total_cost
            }
          }
          }
    }`;
};

export default {
    GET_ESTIMATE_DETAILS,
};
