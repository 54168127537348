import { makeStyles } from '@mui/styles';

const EstimateStyle = makeStyles({
    mainEstmate: {
        padding: '0px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 262px) !important',
            },
        },
    },
    mainEstmate2: {
        padding: '0px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 238px) !important',
            },
        },
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
    filterDesc: {
        marginBottom: '5px',
        '& span': {
            '&:nth-child(1)': {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
            '&:nth-child(2)': {
                fontSize: '14px',
                fontFamily: 'Inter-Medium',
                color: 'rgb(72, 77, 255)',
            },
        },
    },
});

export default EstimateStyle;
