import { Alert, AlertColor, Snackbar } from '@mui/material';
import React from 'react';

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void,
    message: string,
    type: AlertColor | undefined,
}

export default function NotificationBar(props: Props) {
    const {
        open, setOpen, message, type,
    } = props;
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
            <Alert onClose={() => setOpen(false)} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
