import React from 'react';
import imageUnAvai from '../../../../assets/unavailable.svg';
import PageUnavailabe from './PageUnavailable.style';

function PageUnavailable() {
    const classes = PageUnavailabe();
    return (
        <div>
            <div className={classes.container}>
                <div>
                    <img src={imageUnAvai} alt="Page Unavailable" />
                </div>
                <div>
                    <h2 className={classes.heading}>Page Unavailable</h2>
                    <p className={classes.paragraph}>
                        Currently, there are no options to display as this space will
                        be populated with various customisation options once you create a project.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PageUnavailable;
