import React, {
    Dispatch, SetStateAction, useContext,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Button, DialogActions,
} from '@mui/material';
import DeactivateIcon from '../../../../assets/deactivateUser.svg';
import DeleteRowModelStyle from './DeleteRowModel.style';
import { UserContext } from '../../../../store/context/userContext';

interface InviteProps {
    open: boolean,
    setOpenDelRow: Dispatch<SetStateAction<boolean>>,
    setConfirmDeleteModal: Dispatch<SetStateAction<boolean>>,
    userName?: string | '',
}

function DeleteRowModel(
    {
        open, setOpenDelRow, setConfirmDeleteModal, userName,
    }: InviteProps,
) {
    const classes = DeleteRowModelStyle();
    const userContext = useContext(UserContext);

    const selectedOrgName = (userContext?.user?.orgnizations?.length)
        && userContext.user.orgnizations.find((org) => org.id === userContext?.user?.default_org_id);

    const handleClose = () => {
        setOpenDelRow(false);
    };
    const handleDeleteRow = () => {
        setOpenDelRow(false);
        setConfirmDeleteModal(true);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Deactivate User</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Box className={classes.centerBody}>
                        <Box className={classes.projectListMain}>
                            <img src={DeactivateIcon} alt="graph" />
                        </Box>
                        <Typography className={classes.subHeader} variant="h3">
                            Deactivate user
                            {' '}
                            &apos;
                            <span>{userName}</span>
                            &apos;
                            {' '}
                            from organization
                            {' '}
                            &apos;
                            <span>{selectedOrgName && selectedOrgName?.name}</span>
                            &apos;?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledrmButton}>
                        <Button variant="contained" color="primary" onClick={handleDeleteRow}>
                            Deactivate
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DeleteRowModel;
