import React, { useContext } from 'react';
import { CellRendererType } from '../../../../types/AgGridTypes';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import COADifferent from './COADifferent.style';
import { ProjectContext } from '../../../../store/context/projectContext';
import getProjectLevel from '../../../../helper/ProjectLevel';

function CoaColumn(params: CellRendererType) {
    const formatCurrency = useDisplayCurrFormatter();
    const classes = COADifferent();
    const projectCtx = useContext(ProjectContext);

    const selectedProject = projectCtx?.project;

    const projectLevel = getProjectLevel([
        selectedProject?.coa1,
        selectedProject?.coa2,
        selectedProject?.coa3,
        selectedProject?.coa4,
        selectedProject?.coa5,
        selectedProject?.coa6,
    ]);

    const colName = params?.colDef?.field;
    if (params?.value != null) {
        const { value } = params;
        if (params?.node?.rowPinned) {
            return (
                <span>
                    {(colName === 'labour_hours' || colName === 'description')
                        ? params?.value.toLocaleString()
                        : formatCurrency(params?.value)}
                </span>
            );
        }
        if (colName && colName.startsWith('column_')) {
            switch (colName) {
            case `${colName}`:
                return formatCurrency(params?.value);
            default:
                return params?.value;
            }
        }
        switch (colName) {
        case 'code':
            if (params?.data?.level === 1) {
                return (
                    <span className={classes.boldText}>
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            return params?.value.toLocaleString();
        case 'description':
            if (params?.data?.level === 1) {
                return (
                    <span className={projectLevel === 6 ? classes.headingText : classes.boldText}>
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data?.level === 2) {
                return (
                    <span className={projectLevel === 2 ? classes.normalText : classes.boldText}>
                    &emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data?.level === 3) {
                return (
                    <span className={projectLevel <= 3 ? classes.normalText : classes.boldText}>
                        &emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data?.level === 4) {
                return (
                    <span className={projectLevel <= 4 ? classes.normalText : classes.boldText}>
                        &emsp;&emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            } if (params?.data?.level === 5) {
                return (
                    <span className={projectLevel <= 5 ? classes.normalText : classes.boldText}>
                        &emsp;&emsp;&emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            if (params?.data?.level === 6) {
                return (
                    <span className={classes.normalText}>
                        &emsp;&emsp;&emsp;&emsp;&emsp;
                        {params?.value.toLocaleString()}
                    </span>
                );
            }
            return params?.value.toLocaleString();
        case 'labour_hours':
            return value && formatCurr(+((+value).toFixed(0)), 'en-US');
        case 'cons_equipment':
        case 'equipment':
        case 'material':
        case 'total_cost':
        case 'indirect':
        case 'subcontract':
        case 'labour':
            return formatCurrency(params?.value);
        default:
            return params?.value;
        }
    } else if (params?.value === null) {
        return '-';
    }
    return '';
}

export default CoaColumn;
