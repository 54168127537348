import React from 'react';
import AppDrawerStyle from './AppDrawer.style';
import { List } from '@mui/material';
import { AppDrawerAccordion } from './AppDrawerAccordion';
import { AppDrawerItem } from './AppDrawerItem';
import { useLocation } from 'react-router-dom';
import useIsSuperAdmin from '../../hooks/isSuperAdmin';
import { SUPER_ADMIN_ROUTES } from './AppDrawerMenuConfig';

type Props = {
    items: Array<Item>,
};

type Item = {
    title: string,
    path: string,
    icon?: string,
    visible: boolean,
    items?: Array<Item>
};

export function AppDrawerSubMenuItem(props : Props) {
    const { items } = props;
    const classes = AppDrawerStyle();
    const router = useLocation();
    const isSuperAdmin = useIsSuperAdmin();

    return (
        <List className={classes.leftBarLink}>
            {
                items.map((item: Item) => {
                    // Restriction for routes that are only allowed to super admin
                    if (!isSuperAdmin) {
                        if (SUPER_ADMIN_ROUTES.includes(item.path)) {
                            return null;
                        }
                    }
                    return (
                        (item.items && item.visible)
                            ? (<AppDrawerAccordion key={item.title} title={item.title} items={item.items} path={router.pathname} />)
                            : (
                                <AppDrawerItem
                                    key={item.title}
                                    title={item.title}
                                    path={item.path}
                                    icon={item.icon}
                                    isActive={router.pathname === item.path}
                                    visible={item.visible}
                                />
                            )
                    );
                })
            }
        </List>
    );
}
