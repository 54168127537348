import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, Typography, DialogActions, DialogContent, DialogTitle,
    Dialog, TextField, Grid, Button,
} from '@mui/material';
import useStyles from './DuplicateRowModal.style';

interface InviteProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}

function DuplicateRowModal({ open, setOpen }: InviteProps) {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open} onClose={handleClose}>
                <DialogTitle className={classes.dilogBoxSection}>Duplicate Row</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Typography variant="subtitle2" className={classes.labelText}>
                        Code
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField fullWidth color="secondary" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DuplicateRowModal;
