import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

export const CHANGE_PROJECT_CURRENCY = (Org_id: string, ProjectId: string, CurrencyId: string) => gql`
mutation ChangeCurrency {
    changeProjectCurrency(
      input: {
        project_id: ${addEscapeCharacters(ProjectId)}
        org_id: ${addEscapeCharacters(Org_id)}
        currency_id: ${addEscapeCharacters(CurrencyId)}
      }
    ) {
      message
    }
}
`;

export const CHANGE_PROJECT_CURRENCY_VARIABLES = () => gql`
mutation ChangeCurrency($project_id: String!, $org_id: String!, $currency_id: String!) {
    changeProjectCurrency(
      input: {
        project_id: $project_id
        org_id: $org_id
        currency_id: $currency_id
      }
    ) {
      message
    }
}
`;
