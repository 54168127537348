import { makeStyles } from '@mui/styles';

const CostHeaderBarStyle = makeStyles(() => ({
    chartStyle: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '25px',
        height: '35px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '2.5px 12px',
        maxWidth: '445px',
    },
    costSec: {
        maxWidth: '130px',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '11px',
        fontWeight: '600',
    },
    value: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '13px',
    },
}));

export default CostHeaderBarStyle;
