import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    inTableHeader: {
        '& h3': {
            fontWeight: '700',
            fontSize: '22px',
            color: '#1c1c1c',
            marginTop: '8px',
        },
    },
    tableSec: {
        marginTop: '30px',
        '& .ag-details-row': {
            '& .ag-theme-alpine': {
                '&:nth-child(1)': {
                    height: '100% !important',
                },
            },
        },
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 240px) !important',
            },
        },
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
    errorStatus: {
        padding: '3px 25px',
        backgroundColor: '#FFCACA',
        borderRadius: '10px',
        textTransform: 'capitalize',
    },
    successStatus: {
        padding: '3px 25px',
        backgroundColor: '#8cd583',
        borderRadius: '10px',
        textTransform: 'capitalize',
    },
}));
