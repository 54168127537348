export const PATH_AUTH = {
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    customLogin: '/invite-user',
};

export const PATH_PAGE = {
    maintenance: '/maintenance',
    page404: '/404',
    page500: '/500',
    contactAdmin: '/contact-admin',
    invitationAccepted: '/invitation-accepted',
};

export const PATH_DATABASE = {
    dashboard: '/',
    reports: '/reports',
    industries: '/industries',
    organisations: '/organisations',
    commodities: '/commodities',
    projects: '/projects',
    userSettings: '/userSettings',
    projectDashboard: '/projectDashboard',
};

export const PATH_CMFR = {
    capex: {
        root: '/capex',
        reports: '/projectReports',
        wbs: '/wbs',
        wbsBenchmarking: '/wbsBenchmarking',
        wbsDifferent: '/wbsDifferent',
        wbsList: '/wbsList',
        coa: '/coaList',
        coaBenchmarking: '/coaBenchmarking',
        coaDifferent: '/coaDifferent',
        schedule: '/schedule',
        estimate: '/estimate',
        unit: '/unitrate',
        resources: '/resources',
        labour: '/labour',
        equipment: '/equipment',
        packages: '/packages',
        settings: {
            root: '/settings',
            general: '/general',
            home: '/general#',
            version: '/general#version',
            structure: '/general#structure',
            currency: '/general#currency',
            location: '/general#location',
        },
    },
};

export const PATH_SETTINGS = {
    organisation: '/organisation',
    organisationlist: '/organisationlist',
    user: '/user',
    importLog: '/importlogs',
};

export const ROLE_RESTRICTED_CONFIG = {
    super_admin: [PATH_DATABASE.organisations],
    admin: [PATH_DATABASE.organisations],
    user: [PATH_DATABASE.organisations],
};

const { capex } = PATH_CMFR;
export const PATH_RESTRICTED_CONFIG = {
    show_submenus: [
        PATH_DATABASE.projectDashboard, capex.root, capex.wbs, capex.wbsBenchmarking, capex.wbsDifferent, capex.wbsList, capex.coa,
        capex.coaBenchmarking, capex.coaDifferent, capex.schedule, capex.estimate, capex.unit, capex.resources, capex.labour,
        capex.equipment, capex.packages, capex.settings.root, capex.settings.general, capex.settings.version, capex.settings.structure,
        capex.settings.currency, capex.settings.location, capex.reports,
    ],
};
