import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box, Container, DialogActions, DialogContent,
} from '@mui/material';
import AddProjectModelStyle from './AddProjects.style';

function AddProjectsModal(props: {
    open: boolean;
    setOpen: (open: boolean) => void;
    addpro: boolean;
    setAddPro: (coa: boolean) => void;
  }): React.JSX.Element {
    const classes = AddProjectModelStyle();
    const {
        open, setOpen, addpro, setAddPro,
    } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickAddpro = () => {
        setAddPro(true);
    };
    const handleCloseAddpro = () => {
        setAddPro(false);
    };
    return (
        <div>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={addpro}
                    onClose={handleCloseAddpro}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="lg"
                    PaperProps={{
                        sx: {
                            width: '70%',
                            maxHeight: 500,
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">
                        <Typography variant="h5" component="h5">
                            Add Projects
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ p: '0px', backgroundColor: '#F9F9F9', m: '0px' }}>
                        <Container>
                            <Typography variant="h5" component="h5" sx={{ color: '#8F8F8F', m: '25px 5px 25px' }}>
                                Select Projects to add them to Benchmark
                            </Typography>
                            {/* <AgGridTable  height={350} /> */}
                            <table className={classes.tbl}>
                                <tr>
                                    <th>
                                        {' '}
                                        <input type="checkbox" />
                                        {' '}
                                    </th>
                                    <th>Name</th>
                                    <th>Estimate Type</th>
                                    <th>Industry</th>
                                    <th>Country</th>
                                    <th>Base Date</th>
                                    <th>Total Cost</th>
                                </tr>
                                <tr>
                                    <td>
                                        {' '}
                                        <input type="checkbox" />
                                        {' '}
                                    </td>
                                    <td>Underground Rehabilitation</td>
                                    <td> Class 2 Construction</td>
                                    <td>Mining & Minerals </td>
                                    <td>Canda</td>
                                    <td>2016</td>
                                    <td>$14,105,100</td>
                                </tr>
                                <tr>
                                    <td>
                                        {' '}
                                        <input type="checkbox" />
                                        {' '}
                                    </td>
                                    <td>Underground Rehabilitation</td>
                                    <td> Class 2 Construction</td>
                                    <td>Mining & Minerals </td>
                                    <td>Canda</td>
                                    <td>2016</td>
                                    <td>$14,105,100</td>
                                </tr>
                                <tr>
                                    <td>
                                        {' '}
                                        <input type="checkbox" />
                                        {' '}
                                    </td>
                                    <td>Underground Rehabilitation</td>
                                    <td> Class 2 Construction</td>
                                    <td>Mining & Minerals </td>
                                    <td>Canda</td>
                                    <td>2016</td>
                                    <td>$14,105,100</td>
                                </tr>
                                <tr>
                                    <td>
                                        {' '}
                                        <input type="checkbox" />
                                        {' '}
                                    </td>
                                    <td>Underground Rehabilitation</td>
                                    <td> Class 2 Construction</td>
                                    <td>Mining & Minerals </td>
                                    <td>Canda</td>
                                    <td>2016</td>
                                    <td>$14,105,100</td>
                                </tr>
                                <tr>
                                    <td>
                                        {' '}
                                        <input type="checkbox" />
                                        {' '}
                                    </td>
                                    <td>Underground Rehabilitation</td>
                                    <td> Class 2 Construction</td>
                                    <td>Mining & Minerals </td>
                                    <td>Canda</td>
                                    <td>2016</td>
                                    <td>$14,105,100</td>
                                </tr>

                            </table>
                        </Container>
                    </DialogContent>
                    <DialogActions sx={{ mr: '15px' }}>
                        <Button
                            className={classes.popBtn}
                            autoFocus
                            onClick={handleCloseAddpro}
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                        >
                            Cancel
                        </Button>
                        <Box className={classes.undoButtnNew}>
                            <Button
                                className={classes.popBtn}
                                onClick={handleCloseAddpro}
                                autoFocus
                                variant="contained"
                                sx={{ textTransform: 'none', mr: '0px' }}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default AddProjectsModal;
