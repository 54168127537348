import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import Preload from './Preload';
import { PATH_AUTH, PATH_PAGE } from './path';
import { ForgetPassword } from '../screens/ForgetPassword';
import Signup from '../screens/Signup';
import CustomInviteLogin from '../screens/CustomInviteLogin';
import ContactAdmin from '../screens/ContactAdmin';
import InvitationConfirmation from '../screens/InvitationConfirmation';

const Login = Preload(lazy(() => import(/* webpackChunkName: "loginModule" */'../screens/Login')));

export default function PublicRouter() {
    // More routes to be added later for forget password, reset password, sign up
    // The components for sign-up, reset password, forget password can be combined into one module
    // so that there is only one dynamic chunk getting created for all this
    // For now adding lazy loading to Login component only.
    const PublicRoutes = [
        {
            path: PATH_AUTH.login,
            element: <Login />,
        },
        {
            path: PATH_AUTH.forgotPassword,
            element: <ForgetPassword />,
        },
        {
            path: PATH_AUTH.signup,
            element: <Signup />,
        },
        {
            path: PATH_AUTH.customLogin,
            element: <CustomInviteLogin />,
        },
        {
            path: PATH_PAGE.contactAdmin,
            element: <ContactAdmin />,
        },
        {
            path: PATH_PAGE.invitationAccepted,
            element: <InvitationConfirmation />,
        },
    ];

    return PublicRoutes;
}
