import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_PROJECTS = (
    skip: number,
    take: number,
    sort: string,
    sortOrder: string,
    orgId: string,
    // searchedText: string,
    selectedIndustry: string,
    selectedRegion: string,
) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    // const searchParam = searchedText ? `searchKeyword: ${addEscapeCharacters(searchedText)}` : '';
    const searchParam = '';
    const industryId = selectedIndustry ? `industry_id: ${addEscapeCharacters(selectedIndustry)}` : '';
    const regionId = selectedRegion ? `country_id: ${addEscapeCharacters(selectedRegion)}` : '';
    return gql`
    query  GetProject {
      project(skip: ${skip}, take: ${take},${sortType}, filter: {
        org_id: ${addEscapeCharacters(orgId)},
        ${searchParam},
        ${industryId},
        ${regionId}
      }) {
        pageInfo {
          skip
          take
          totalcount
          hasNextPage
        }
        data {
          id
          base_date
          wbs1
          wbs2
          wbs3
          wbs4
          wbs5
          wbs6
          coa1
          coa2
          coa3
          coa4
          coa5
          coa6
          hide_values
          coa_sep
          name
          client_number
          region
          owner
          consultant
          consultant_number
          estimate_type
          estimate_accuracy
          contingency_wbs
          contingency_pct
          currency
          wbs_sep
          created_at
          updated_at
          project_number
          total_cost
          version_id
          version_data {
            id
          }
          version_code
          industry_id
          industry {
            description
          }
          subindustry_id
          subindustry {
            description
          }
          country {
            name
          }
          currency_id
          currency_data {
            id
            code
            exchange_rate
            symbol
          }
          is_active
          country_name
          latitude
          location
          longitude
          permission_id
        }
      }
    }`;
};

const PROJECTS_ASSIGNMENTS_FOR_USER = gql`
mutation ($orgId: String!, $userId: String!, $project_assignment: [ProjectEditPermission!]!) {
  editProjectAssignmentsforUser(
    input: {
      orgId: $orgId
      userId: $userId
      project_assignment: $project_assignment
    }
  ){
    message
    error
    message
  }
}
`;

const DELETE_PROJECT = (orgId: string, projId: string) => gql`
  mutation {
    delete_project(
      input: {
        org_id: ${addEscapeCharacters(orgId)},
        project_id: ${addEscapeCharacters(projId)}
      }
    ) {
      message
    }
  }`;

export default {
    GET_PROJECTS,
    PROJECTS_ASSIGNMENTS_FOR_USER,
    DELETE_PROJECT,
};
