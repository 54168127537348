const FONT_FAMILY = 'Inter-Medium';

const typography = {
    fontFamily: FONT_FAMILY,
    h1: {
        fontSize: '25px',
    },
    h2: {
        fontSize: '22px',
    },
    h3: {
        fontSize: '21px',
    },
    h4: {
        fontSize: '20px',
    },
    h5: {
        fontSize: '18px',
    },
    h6: {
        fontSize: '11px',
    },
    body2: {
        fontSize: '16px',
    },
    body1: {
        fontSize: '12px',
    },
    subtitle1: {
        fontSize: '16px',
    },
    subtitle2: {
        fontSize: '15px',
    },
};

export default typography;
