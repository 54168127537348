import { gql } from 'graphql-request';

const SUBSCRIBE_ALL_USER_DETAILS = gql`
subscription {
  getAllUserDetails {
    user_id
    email
    name
    email_verified
    userRole
    default_org_id
    orgnizations {
      id
      name
      display_name
      role_id
    }
  }
}
`;

export default {
    SUBSCRIBE_ALL_USER_DETAILS,
};
