/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import NotificationBar from '../components/NotificationBar';
import { AlertColor } from '@mui/material';

// @ts-ignore
export const withSnackbar = (WrappedComponent) => function SnackbarHOC() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("I'm a custom snackbar");
    const [severity, setSeverity] = useState<AlertColor | undefined>(
        'success',
    ); /** error | warning | info */

    const showMessage = (userMessage: string, userseverity: AlertColor | undefined) => {
        setMessage(userMessage);
        setSeverity(userseverity);
        setOpen(true);
    };

    return (
        <>
            <WrappedComponent snackbarShowMessage={showMessage} />
            <NotificationBar
                open={open}
                setOpen={setOpen}
                message={message}
                type={severity}
            />
        </>
    );
};
