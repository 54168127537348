import { makeStyles } from '@mui/styles';

const WBSDifferent = makeStyles({
    heirarchystyl: {
        fontSize: '15.33px',
    },
    headingText: {
        fontSize: '13px',
        fontFamily: 'Inter-Bold',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    boldText: {
        fontSize: '13px',
        fontFamily: 'Inter-Medium',
        fontWeight: 600,
    },
    normalText: {
        fontSize: '13px',
        fontFamily: 'Inter',
        fontWeight: 400,
    },
});
export default WBSDifferent;
