import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    resourceBody: {
        marginTop: '30px',
    },
    selectionSec: {
        display: 'flex',
        alignItems: 'center',
    },
    outlineButton: {
        marginRight: '10px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '0.5px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
    newButton: {
        marginRight: '10px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
        },
    },
    tableSec: {
        marginTop: '30px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 330px) !important',
            },
        },
        '& .ag-right-aligned-cell': {
            // textAlign: 'unset',
        },
    },
    tableSec2: {
        marginTop: '30px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 306px) !important',
            },
        },
        '& .ag-right-aligned-cell': {
            // textAlign: 'unset',
        },
    },
    loaderContainer: {
        height: '60vh',
    },
    opacityBlur: {
        opacity: '50%',
    },
    filterDesc: {
        marginBottom: '5px',
        '& span': {
            '&:nth-child(1)': {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
            '&:nth-child(2)': {
                fontSize: '14px',
                fontFamily: 'Inter-Medium',
                color: 'rgb(72, 77, 255)',
            },
        },
    },
}));
