import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, List, Typography } from '@mui/material';
import { AppDrawerItem } from './AppDrawerItem';
import AppDrawerStyle from './AppDrawer.style';

type Props = {
    title: string,
    path: string,
    items: Array<ItemProps>,
};

type ItemProps = {
    title: string,
    path: string,
    visible: boolean,
    items?: Array<ItemProps>,
    isHashLink?: string,
    hashLinkTo?: string,
};

export function AppDrawerAccordion(props : Props) {
    const {
        title, items, path,
    } = props;
    const classes = AppDrawerStyle();
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        const expand = newExpanded ? panel : false;
        setExpanded(expand);
    };
    return (
        <Box className={classes.projectListAccordian}>
            <Accordion expanded={expanded === ('panel1')} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List className={classes.leftBarLinkProject}>
                        {
                            items.map((item: ItemProps) => (
                                item.items
                                    ? (<AppDrawerAccordion key={item.title} title={item.title} items={item.items} path={path} />)
                                    : (
                                        <AppDrawerItem
                                            key={item.title}
                                            title={item.title}
                                            path={item.path}
                                            isActive={path === item.path}
                                            visible={item.visible}
                                            isHashLink={item.isHashLink}
                                            hashLinkTo={item.hashLinkTo}
                                        />
                                    )
                            ))
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
