import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { clearAccessTokenOnLogout } from '../../../helper/StorageHelper';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center',
        margin: '40px 0px',
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
}));

function ContactAdministrator() {
    const { logout } = useAuth0();
    const classes = useStyles();
    // const userContext = useContext(UserContext);
    // const navigate = useCustomNavigate();
    // // useEffect(() => {
    // //     const isRedirectToHome = userContext?.user?.default_org_id !== '' || userContext?.user?.default_org_id !== null;
    // //     if (isRedirectToHome) {
    // //         navigate('/');
    // //     }
    // // }, []);

    const handleLogout = () => {
        clearAccessTokenOnLogout();
        logout({ logoutParams: { returnTo: window.location.origin } })
            .then(() => {})
            .catch(() => {});
    };

    return (
        <div className={classes.container}>
            <h2>Contact Administrator</h2>
            <p>
                Your account has been disabled, please contact the administrator.
            </p>
            <Button variant="outlined" className={classes.outlineButton} onClick={handleLogout}>Logout</Button>
        </div>
    );
}

export default ContactAdministrator;
