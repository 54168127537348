import React, {
    MutableRefObject,
    forwardRef,
    useContext, useRef, useState,
} from 'react';
import {
    Designer, Viewer as ReportViewer, ReportInfo, Viewer,
} from '@grapecity/activereports-react';
import { ReportItem } from '@grapecity/activereports/lib/node_modules/@grapecity/ar-js-rdl';
import { getAccessTokenFromLocalStorage, getBackendEndpoint } from '../../helper/StorageHelper';
import { UserContext } from '../../store/context/userContext';
import { ProjectContext } from '../../store/context/projectContext';
import { Box, Button } from '@mui/material';
import ReportStyle from './Report.style';
import { ReportType } from '../../types/Reports';
import { capitalizeFirstLetter } from '../../helper/validations';
import { useLocation, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../Routes/path';
import { getAllModuleQueryDetails } from './projectQueryDetails';
import getOrgQueryDetails from './organisationQueryDetails';
import { OutletContext } from '../../types/OrganisationDetailType';
import getQueryDetailsByDataset from './helper';
import { DocumentChangedEventArgs } from '@grapecity/activereports/reportdesigner';

interface Props {
    name: string,
    module: string,
    saveReport: (report: ReportType) => void,
    isEditMode: boolean,
    selectedReport: ReportType | null,
    getRunTimeReport:(doc: DocumentChangedEventArgs) => void,
    viewMode: boolean,
    setViewMode: React.Dispatch<React.SetStateAction<boolean>>,
}

const Report = forwardRef<Designer | null, Props>((props: Props, ref) => {
    const {
        name, module, saveReport, isEditMode, selectedReport, getRunTimeReport,
        viewMode, setViewMode,
    } = props;
    const classes = ReportStyle();
    const cont: OutletContext = useOutletContext();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;
    const orgID = userCtx?.user?.default_org_id;
    const [preservePreviewReport, setpreservePreviewReport] = useState<ReportInfo>();
    const { pathname } = useLocation();
    // const reportCtx = useContext(ReportContext);
    const queryDetails = pathname.includes(PATH_CMFR.capex.reports) ? getAllModuleQueryDetails(
        orgID || '',
        projectCtx?.project?.id || '',
        projectCtx?.project?.version_id || '',
        exchangeRate || 1,
    ) : getOrgQueryDetails(
        orgID || '',
        projectCtx?.project?.id || '',
        projectCtx?.project?.version_id || '',
        module,
    );

    const dataSource = [{
        id: '1',
        template: {
            Name: 'Main-BE',
            ConnectionProperties: {
                DataProvider: 'JSON',
                ConnectString: `endpoint=${getBackendEndpoint()};Header$Authorization=Bearer ${getAccessTokenFromLocalStorage() || ''};`,
            },
        },
        title: 'main-backend',
        datasets: queryDetails.map((item, index) => {
            const body = {
                operationName: item.operationName,
                query: item.query,
            };

            const endpoint = item.endpoint ? item.endpoint : '/list';
            return {
                id: index.toString(),
                title: item.module ? item.module : capitalizeFirstLetter(module),
                template: {
                    Name: item.module ? item.module : capitalizeFirstLetter(module),
                    Query: {
                        DataSourceName: 'Main-BE',
                        CommandText: `uri=${endpoint};jpath=$${item.jsPath};method=POST;body=${JSON.stringify(body)};`
                            + 'Header$Content-Type=application/json;',
                    },
                    Fields: item.fields,
                },
            };
        }),
    }];

    const viewerRef = useRef() as MutableRefObject<Viewer>;

    const onRender = (report: ReportInfo) => {
        // Add check for empty table, show notification
        let tableHasDataset = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        const ReportItems = report?.definition?.ReportSections?.[0].Body?.ReportItems;
        const { DataSets } = report.definition;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ReportItems?.forEach((item: ReportItem) => {
            if (item.Type === 'table' && (!DataSets || !DataSets.length)) {
                tableHasDataset = false;
            }
        });
        if (tableHasDataset) {
            if (isEditMode) {
                setpreservePreviewReport(report);
            }
            setViewMode(true);
            viewerRef.current?.Viewer.open(report.definition)
                .then((res) => {})
                .catch((err) => console.log(err));
        } else {
            setpreservePreviewReport(report);
            cont.showNotificationBar('Adding dataset is mandatory for tables.', 'error');
        }
        return Promise.resolve();
    };

    const onSaveAs = (info: ReportInfo) => {
        // Add check for empty table, show notification
        let tableHasDataset = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        const ReportItems = info?.definition?.ReportSections[0].Body?.ReportItems;
        const { DataSets } = info.definition;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ReportItems?.forEach((item: ReportItem) => {
            if (item.Type === 'table' && (!DataSets || !DataSets.length)) {
                tableHasDataset = false;
            }
        });

        if (tableHasDataset) {
            // Call mutation API to create report
            saveReport({
                id: isEditMode ? info.id || '' : '',
                display_name: isEditMode ? info.displayName : name,
                module_name: module,
                data: JSON.stringify(info.definition),
            });
        } else {
            setpreservePreviewReport(info);
            cont.showNotificationBar('Adding dataset is mandatory for tables.', 'error');
        }
        return Promise.resolve({ id: info.id || '', displayName: JSON.stringify(info.definition) });
    };

    const dynamicReportData = (isEditMode || selectedReport)
        ? JSON.parse(JSON.stringify(
            preservePreviewReport
                ? preservePreviewReport.definition
                : JSON.parse(selectedReport?.data || ''),
        )) as (ReportInfo['definition'])
        : {};
    if (dynamicReportData
        && dynamicReportData.DataSources
    ) {
        dynamicReportData.DataSources.forEach((dSource) => {
            if (dSource.ConnectionProperties) {
                // eslint-disable-next-line no-param-reassign
                dSource.ConnectionProperties.ConnectString = `endpoint=${getBackendEndpoint()}`
                + `;Header$Authorization=Bearer ${getAccessTokenFromLocalStorage() || ''};`;
            }
        });
    }

    // Dynamic orgID and projectID in datasets
    if (dynamicReportData && dynamicReportData.DataSets) {
        dynamicReportData.DataSets.forEach((dSet) => {
            const datasetName = dSet.Name;
            if (dSet.Query) {
                // eslint-disable-next-line no-param-reassign
                dSet.Query.CommandText = getQueryDetailsByDataset(
                    orgID || '',
                    projectCtx?.project?.id || '',
                    projectCtx?.project?.version_id || '',
                    datasetName,
                    exchangeRate as number,
                );
            }
        });
    }

    const LightTheme = {
        Dark1: '#000000',
        Dark2: '#1a1a1a',
        Light1: '#3da7a8',
        Light2: '#Black',
        Accent1: 'MediumTurquoise',
        Accent2: 'Gold',
        Accent3: 'DeepPink',
        Accent4: '#557bd2',
        Accent5: '#8acc1f',
        Accent6: '#c3ebec',
        MajorFontFamily: 'Montserrat',
        MinorFontFamily: 'Montserrat',
    };
    const DarkTheme = {
        Dark1: '#000000',
        Dark2: '#f1f1f1',
        Light1: '#40cddf',
        Light2: '#e6e6e6',
        Accent1: 'MediumTurquoise',
        Accent2: 'Gold',
        Accent3: 'DeepPink',
        Accent4: '#557bd2',
        Accent5: '#8acc1f',
        Accent6: '#409dcc',
        MajorFontFamily: 'Arial, sans-serif',
        MinorFontFamily: 'Arial, sans-serif',
    };
    return (
        <>
            <div className={classes.designerHost} style={{ display: viewMode ? 'none' : 'block' }}>
                <Designer
                    ref={ref}
                    onRender={(report) => onRender(report as ReportInfo)}
                    onSave={(report) => onSaveAs(report as ReportInfo)}
                    onSaveAs={isEditMode ? (report) => onSaveAs(report as ReportInfo) : undefined}
                    dataSources={dataSource}
                    report={
                        (isEditMode || selectedReport)
                            ? { id: selectedReport?.id, displayName: name, definition: dynamicReportData }
                            : { id: 'report.rdlx-json', displayName: name }
                    }
                    documentChanged={getRunTimeReport}
                    hotkeysEnabled
                    themeList={
                        [{
                            id: 'LightTheme.rdlx-json-theme',
                            displayName: 'LightTheme',
                            info: LightTheme,
                        },
                        {
                            id: 'DarkTheme.rdlx-json-theme',
                            displayName: 'DarkTheme',
                            info: DarkTheme,
                        }]
                    }
                />
            </div>
            { viewMode ? (
                <Box className={classes.outlineButton}>
                    <Button variant="outlined" onClick={() => setViewMode(false)}>Back</Button>
                </Box>
            ) : null }
            <div className={classes.viewerHost} hidden={!viewMode}>
                <ReportViewer ref={viewerRef} />
            </div>
            {' '}

        </>
    );
});

Report.displayName = 'Report';

export default Report;
