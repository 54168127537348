import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    tableSec: {
        marginTop: '30px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 245px) !important',
            },
        },
    },
    loaderContainer: {
        height: '60vh',
    },
    headerCont: {
        display: 'flex',
    },
}));
