/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import mapStyle from './LeafletMap.style';

interface Iprops {
    children?: any
    zoomLevel: number;
}

function LeafletMap(props: Iprops) {
    const { children, zoomLevel } = props;
    const classes = mapStyle();
    return (
        <div className={classes.mapContainer}>
            <MapContainer
                center={[51.505, -0.09]}
                zoom={zoomLevel}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                style={{ height: '200px', width: '100%' }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {children}
            </MapContainer>
        </div>
    );
}

export default LeafletMap;
