/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { ReportInfo } from '@grapecity/activereports/reportdesigner';
import { ReportType } from '../types/Reports';

/**
 * A helper function which accepts a sectionNumber & subReportName.
 * SectionNumber is used for naming the section
 * and subReportName will mostly be ID, witch will be later used to get JSON data.
 * @param sectionNumber number
 * @param subReportName string
 * @returns ReportSection
 */
const getNewSubReportSection = (sectionNumber: number, subReportName: string, report: ReportType) => {
    const reportObj = JSON.parse(report.data) as (ReportInfo['definition']);
    const section2: ReportInfo['definition']['ReportSections'] = [{
        Type: 'Continuous',
        Name: `ContinuousSection${sectionNumber}`,
        DisplayName: subReportName,
        PageHeader: reportObj.ReportSections[0].PageHeader,
        PageFooter: reportObj.ReportSections[0].PageFooter,
        Page: {
            PageWidth: '8.5in',
            PageHeight: '11in',
            RightMargin: '0in',
            LeftMargin: '0in',
            TopMargin: '0in',
            BottomMargin: '0in',
            Columns: 1,
            ColumnSpacing: '0in',
        },
        Width: '8.4in',
        Body: {
            Height: '10.8in',
            ReportItems: [
                {
                    Type: 'subreport',
                    Name: 'MergingReport',
                    ZIndex: 1,
                    ReportName: subReportName,
                    Top: '0in',
                    Width: '8.4in',
                    Height: '10.8in',
                },
            ],
        },
    }];
    return section2;
};

export default getNewSubReportSection;
