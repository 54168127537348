import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const DELETE_VERSION = (ver_id: string, project_id: string) => gql`
mutation{
    deleteVersion(version_id: ${addEscapeCharacters(ver_id)}, project_id: ${addEscapeCharacters(project_id)}) {
        message
    }
  }
`;

export default { DELETE_VERSION };
