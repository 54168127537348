import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    inTableHeader: {
        display: 'block',
        alignItems: 'center',
        marginTop: '8px',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c',
        },
        '& button': {
            float: 'right',
            position: 'relative',
            top: '-5px',
            textTransform: 'none',
            padding: '3.4px 11px !important',
            fontSize: '13px',
        },
        '& svg': {
            fontSize: '20px',
            marginRight: '5px',
        },
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
    undoButtnNew: {
        fontSize: '12px',
        color: '#fff !important',
        border: '1px solid #007DFF !important',
        borderRadius: '4px',
        backgroundColor: '#007DFF !important    ',
        padding: '5px 10px !important',
        cursor: 'pointer',
        '& svg': {
            color: '#fff !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    undoButtnNewSelected: {
        fontSize: '12px',
        color: '#787878 !important',
        border: '1px solid #787878 !important',
        borderRadius: '4px',
        backgroundColor: '#FFF !important    ',
        padding: '5px 10px !important',
        cursor: 'pointer',
        marginRight: '15px !important',
        '& svg': {
            color: '#787878 !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    boxWrapformat: {
        width: '67%',
        marginTop: '35px',
    },
    bggray: {
        backgroundColor: '#F8F8F8',
        border: '#ddd 1px solid',
        borderBottom: 'none',
        padding: '25px',
        width: '100%',
    },
    forheading: {
        color: '#353535',
        fontSize: '20px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        width: '100%',
    },
    bgwhite: {
        backgroundColor: '#f0f0f0',
        border: '#ddd 1px solid',
        width: '100%',
        borderTop: 'none',
        fontWeight: 'bold',
        padding: '25px',
    },
    digitext: {
        fontSize: '15px !important',
        marginBottom: '10px !important',
    },
    exptext: {
        color: '#aaa',
        fontSize: '14px !important',
    },
    heading: {
        fontWeight: '700 !important',
        fontSize: '19.24px !important',
        lineHeight: '2 !important',
        margin: '50px 0px 0px 0px !important',
        width: '80%',
    },
    btnAcn: {
        padding: '5px 10px !important',
        marginRight: '10px !important',
    },
    InputHeight: {
        '& div': {
            '& input': {
                height: '24px !important',
                fontSize: '16px',
                color: '#acacac',
            },
        },
    },
    btngray: {
        color: '#787878 !important',
        border: '#787878 1px solid !important',
        '& svg': {
            color: '#787878 !important',
        },
    },
    undoButtnNewSelectedRed: {
        fontSize: '12px',
        color: '#B00000 !important',
        border: '1px solid #B00000 !important',
        borderRadius: '4px',
        backgroundColor: '#FFF !important    ',
        padding: '5px 10px !important',
        cursor: 'pointer',
        marginRight: '15px !important',
        '& svg': {
            color: '#B00000 !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    tableSec: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 295px) !important',
            },
        },
    },
}));
