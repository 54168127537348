import { useAuth0 } from '@auth0/auth0-react';
import { clearAccessTokenOnLogout } from '../helper/StorageHelper';

export const useLogout = () => {
    const { logout: auth0Logout } = useAuth0();

    const logout = () => {
        auth0Logout({ logoutParams: { returnTo: window.location.origin } })
            .then(() => clearAccessTokenOnLogout()).catch(() => {});
    };

    return logout;
};
