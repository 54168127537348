import React from 'react';
import { PanelResizeHandle } from 'react-resizable-panels';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import useStyles from './ResizeIcon.style';

export default function ResizeIcon({
    id,
}: {
  id?: string;
}) {
    const classes = useStyles();
    return (
        <PanelResizeHandle
            id={id}
        >
            <div className={classes.dragIcon}>
                <DragIndicatorIcon />
            </div>
        </PanelResizeHandle>
    );
}
