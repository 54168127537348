import { makeStyles } from '@mui/styles';

const GenralSettingStyle = makeStyles(() => ({
    undoButtnNew: {
        marginLeft: '15px',
        '& button': {
            fontSize: '14px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            fontWeight: '700',
            textTransform: 'none',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '5px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    heading: {
        fontSize: '24px !important',
        marginBottom: '10px !important',
    },
    Subheading: {
        fontSize: '15px',
        color: '#9C9C9C',
    },
}));
export default GenralSettingStyle;
