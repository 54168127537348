import React, {
    Dispatch, SetStateAction,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Grid, Button, IconButton,
} from '@mui/material';
import wbsIcon from '../../../../assets/wbsIcon.svg';
import coaIcon from '../../../../assets/coaIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import BenchmarkingOptModelStyle from './BenchmarkingModel.style';

interface InviteProps {
    open: boolean,
    setOpenBenchmark: Dispatch<SetStateAction<boolean>>,
}

function BenchmarkingOptModel({ open, setOpenBenchmark }: InviteProps) {
    const classes = BenchmarkingOptModelStyle();

    const handleClose = () => {
        setOpenBenchmark(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open} onClose={handleClose}>
                <DialogTitle className={classes.dilogBoxSection}>Benchmarking Options</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={classes.dialogBody}>
                    <Grid container className={classes.sectionBody}>
                        <Grid item xs={6} className={classes.leftdivCenter}>
                            <img src={wbsIcon} alt="graph" />
                            <Typography>Compare costs of WBS Areas to similar projects using the Generic WBS</Typography>
                            <Button variant="outlined">WBS Benchmarking</Button>
                        </Grid>
                        <Grid item xs={6} className={classes.rigntdivCenter}>
                            <img src={coaIcon} alt="graph" />
                            <Typography>Compare unit rates to similar projects using the Generic COA </Typography>
                            <Button variant="outlined">Unit Rate Benchmarking</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default BenchmarkingOptModel;
