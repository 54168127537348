import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_SCHEDULE_BY_ORGID = (skip: number, take: number, orgId: string, projectId: string, versionId: string, hideUnusedFlag?: boolean) => gql`
  query GetScheduleDataByOrgId {
    schedule(skip: ${skip},
      take: ${take},
      filter:{   
        project_id: ${addEscapeCharacters(projectId)}
        org_id: ${addEscapeCharacters(orgId)}
        version_id: ${addEscapeCharacters(versionId)}
      },
      hide_unused_flag: ${hideUnusedFlag}){
      pageInfo {
        skip
        take
        totalcount
        hasNextPage
      }
      data{
        scheduleData {
          id
          start_date
          end_date
          code
          name
          labour_hours
          labour
          c_equipment
          material
          subcontract
          equipment
          distributable
          total_cost
          created_at
          updated_at
          package {
            id
            code
            name
            created_at
            updated_at
          }
        }
        total {
          labour_hoursTotal
          labourTotal
          c_equipmentTotal
          materialTotal
          subcontractTotal
          equipmentTotal
          distributableTotal
          total_costTotal
        }
      }
    }
  }`;

export default {
    GET_SCHEDULE_BY_ORGID,
};
