import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import HideUnusedButtonStyle from './HideUnusedButton.style';

type Props = {
    hideUnusedFlagVal: boolean,
    setHideUnusedFlag: (hideUnusedFlagVal: boolean) => void,
    loadingData?: boolean,
};
function HideUnusedButton(props: Props) {
    const classes = HideUnusedButtonStyle();
    const { hideUnusedFlagVal, setHideUnusedFlag, loadingData = false } = props;

    const hideUnused = () => {
        if (loadingData) return;

        if (hideUnusedFlagVal) {
            setHideUnusedFlag(false);
        } else {
            setHideUnusedFlag(true);
        }
    };

    return (
        <Box className={classes.selectionSec}>
            <Box className={hideUnusedFlagVal ? classes.visibilityOutlineButton : classes.visibilityOffOutlineButton}>
                <Button onClick={() => hideUnused()}>
                    {hideUnusedFlagVal ? (
                        <>
                            <VisibilityOutlined />
                            Show All Item
                        </>
                    ) : (
                        <>
                            <VisibilityOffOutlined />
                            Hide Unused Item
                        </>
                    )}
                </Button>
            </Box>
        </Box>
    );
}
export default HideUnusedButton;
