import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

export const GET_REGION = (orgId: string) => gql`
query GetRegion {
    regions(org_id: ${addEscapeCharacters(orgId)}) {
      region
      country
      project_count
      total_cost
      is_country
      country_id
      latitude
      longitude
      areProjectsExcluded
    }
  }  
`;
