import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    dragIcon: {
        '& svg': {
            backgroundColor: '#ece8e8',
            borderRadius: '8px',
            padding: '14px 0px',
        },
    },
}));
