import React, {
    useEffect, useState, useContext,
} from 'react';
import { Box } from '@mui/material';
import { ColumnDefs } from '../../../../types/AgGridTypes';
import AgGridComponent from '../../../../components/agGridTableComponent';
import wbsQueries from '../../../../queries/wbs';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import Loader from '../../../../components/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import WbsCustom from './WbsCustom';
import { JSONParse } from '../../../../helper/StorageHelper';
import { truthyCodeValidation } from '../../../../helper/validations';

interface PhaseRenderer {
    labour_hoursTotal: number,
    labourTotal: number,
    distributableTotal: number,
    c_equipmentTotal: number,
    materialTotal: number,
    sustaining_total: number,
    equipmentTotal: number,
    subcontractTotal: number,
    indirectTotal: number,
    total_costTotal: number,
    name: string,
    total_cost: string | number,
}

type PhaseObject = {
    description: string;
    total_cost: number;
    [key: string]: string | number;
};

interface Props {
    view?: string,
    searchText?:string,
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;

}

export default function WBSPhaseView(props:Props) {
    const {
        view, searchText, setSearchText, hideUnusedFlag, setIsFetching,
    } = props;
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefs[]>([]);
    const [colHeader, setColHeader] = useState<string[]>(['total_cost']);
    const cont: OutletContext = useOutletContext();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const createPhaseData = (params: PhaseRenderer[], total_costTotal:number) => {
        const result:PhaseObject = {
            description: 'TOTAL',
            total_cost: total_costTotal && +((total_costTotal * exchangeRate).toFixed(0)),
        };
        for (let i = 0; i < params.length; i += 1) {
            result[`column_${params[i].name}`] = params[i].total_cost && (+(params[i].total_cost) * exchangeRate).toFixed(0);
        }
        return [result];
    };

    const projectId = projectCtx?.project?.id || '';

    const { data: GetPhaseData, isFetching, refetch } = useGQLQuery(
        `GetWbsPhase-${projectId || 0}`,
        wbsQueries.GET_WBS_PHASE_INFO(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
        {},
        { onError: handleApiError },
    );
    // For WBS Phase view...
    const phaseViewData = GetPhaseData?.wbsphase;
    const tablePhaseData = phaseViewData?.data;
    const tablePhaseViewData:PhaseObject[] = tablePhaseData && tablePhaseData !== undefined ? JSONParse(tablePhaseData.toString()) : [];
    const phasePageInfo = phaseViewData?.pageInfo;
    const phaseTotalValue:PhaseRenderer[] = phaseViewData?.total_values && phaseViewData?.total_values !== undefined
        ? JSONParse(phaseViewData?.total_values.toString()) : [];
    // For Bottom Total Value..
    let pinnedBottomRowData;
    if (phaseTotalValue && phaseTotalValue.length > 0) {
        const totalCostValue: number = tablePhaseViewData.reduce((accumulator, currentItem) => accumulator + (+currentItem.total_cost), 0);
        pinnedBottomRowData = createPhaseData(phaseTotalValue, totalCostValue);
    }

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const columnDefs = [
        {
            field: 'code',
            headerName: 'WBS',
            initialWidth: 130,
            cellRenderer: WbsCustom,
            type: 'string',
            sort: 'asc',
        },
        {
            field: 'description',
            headerName: 'Title',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Phase View',
            initialWidth: 400,
            type: 'string',
            enableValue: false,
        },
        {
            field: 'total_cost',
            headerName: 'Total',
            cellRenderer: WbsCustom,
            pinned: 'right',
            initialHide: view !== 'Phase View',
            initialWidth: 150,
            maxWidth: 400,
            type: 'numericColumn',
            enableValue: true,
        },
    ];

    const setPhaseColumnFunc = () => {
        if (tablePhaseViewData && tablePhaseViewData.length > 0 && view === 'Phase View') {
            setColHeader(['total_cost']);
            const tempArr: string[] = [];
            Object.keys(tablePhaseViewData[0]).forEach((item) => {
                if (item.startsWith('column_')) {
                    const ak = item.split('_')[1].replaceAll('_', ' ');
                    tempArr.push(`${item}`);
                    columnDefs.push(
                        {
                            field: `${item}`,
                            headerName: ak,
                            cellRenderer: WbsCustom,
                            initialHide: view !== 'Phase View',
                            initialWidth: 150,
                            type: 'numericColumn',
                            enableValue: true,
                        },
                    );
                    setNewColumnDefs(columnDefs);
                }
            });
            setColHeader([...colHeader, ...tempArr]);
        }
    };

    const generateColumnDefs = () => {
        const anotherNewColumnDefs = [...columnDefs];
        ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].forEach((code, index) => {
            const flag = (tablePhaseViewData?.map((item) => ((item)[code])))?.some(truthyCodeValidation);
            if (flag) {
                anotherNewColumnDefs.push({
                    field: code,
                    headerName: `Code${index + 1}`,
                    initialWidth: 130,
                    cellRenderer: WbsCustom,
                    type: 'string',
                    initialHide: flag,
                    enableValue: false,
                });
            }
        });
        setNewColumnDefs(anotherNewColumnDefs);
    };

    useEffect(() => {
        setNewColumnDefs(columnDefs);
    }, [view]);

    useEffect(() => {
        setPhaseColumnFunc();
        generateColumnDefs();
    }, [view, isFetching]);

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    useEffect(() => {
        setIsFetching(isFetching);
    }, [isFetching]);

    const totalPages = (phasePageInfo && phasePageInfo.totalcount) ? Math.ceil(phasePageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    return (
        <div>
            {/* Page Header Start */}
            <Loader loading={isFetching} />
            <Box>
                <AgGridComponent
                    columnDefs={newColumnDefs}
                    rowData={tablePhaseViewData}
                    changeSortingValue={() => {}}
                    pinnedBottomRowData={pinnedBottomRowData}
                    isClipboardEnabled
                    isExportEnabled
                    isRangeSelectable
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    isGroupable
                    WbsRowBgStyle
                    moduleName="wbs_phaseview"
                    colFormat={colHeader}
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
            </Box>
        </div>
    );
}
