import React, { useEffect, useContext, useState } from 'react';
import useStyles from './CommoditiesHeader.style';
import {
    Box, Button, Typography, FormControl, MenuItem, Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import AddEditCommodities from '../AddEditCommodities';
import DuplicateRowModal from '../DuplicateRowModal';
import { PATH_DATABASE } from '../../../../Routes/path';
import { useNavigate, useOutletContext } from 'react-router-dom';
import OrgCostHeaderBar from '../../../../components/OrgCostHeaderBar';
import SearchFilter from '../../../../components/searchFilter/SearchFilter';
import SelectDropdown from '../../../../components/DropdownSelect';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import HideUnusedButton from '../../../../components/HideUnusedButton';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { ROLES } from '../../../../constants';
import { useUserOrganization, useUserRole } from '../../../../hooks/useUserRole';
import CommoditiesListType from '../../../../types/CommoditiesListType';
import exportExcel from '../../../../helper/ExportExcel';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { UserContext } from '../../../../store/context/userContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';

interface CommodityData {
    commodities_ddo?: {
        data: CommoditiesListType[];
    };
}

interface IMenuOption {
    value: string
    label?: string
}

interface SelectCode {
    value: string
    code1: string
    code?: string
    label?: string
}

interface Props {
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setHideUnusedFlag: React.Dispatch<React.SetStateAction<boolean>>;
    handleClickOpenImport: () => void;
    handleDelete: () => void;
    selectedCommodities: string[];
    commodityData?: CommodityData | undefined;
    handleSelectCode: (commodity: CommoditiesListType) => void;
    selectCode: SelectCode;
    isGlobalSearchActive: boolean;
    isCommoditiesLoading: boolean;
}

function CommoditiesHeader(props: Props) {
    const {
        searchText, setSearchText, hideUnusedFlag, setHideUnusedFlag, handleClickOpenImport, handleDelete, selectedCommodities,
        commodityData, handleSelectCode, selectCode, isGlobalSearchActive, isCommoditiesLoading,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [hideUnused, setHideUnused] = useState(true);
    const [openDuplicateRow, setOpenDuplicateRow] = useState(false);
    const navigate = useNavigate();
    const loggedInUserRole = useUserRole();
    const [selectMenuState, setSelectMenuState] = useState<IMenuOption[]>([]);
    const selectMenuOptions = ['1 Site Developement', '2 Concrete'];
    const userOrg = useUserOrganization();
    const userCtx = useContext(UserContext);
    const cont: OutletContext = useOutletContext();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const clickDuplicateRow = () => {
        setOpenDuplicateRow(true);
    };

    const navigateToReports = () => {
        navigate(`${PATH_DATABASE.reports}?module=commodities`);
    };

    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'commodities',
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    const handleCommoditiesChange = (value: string) => {
        if (isCommoditiesLoading) return;
        const findCommodity = commodityData?.commodities_ddo?.data.find((c) => c.id === value);
        if (findCommodity) handleSelectCode(findCommodity);
    };

    const handleArrow = (type: string) => {
        let commodityIndex = -1;
        if (isCommoditiesLoading) return;
        if (commodityData?.commodities_ddo?.data && commodityData?.commodities_ddo?.data?.length > 0) {
            commodityIndex = commodityData?.commodities_ddo?.data.findIndex((c) => c.id === selectCode.value);
            if (commodityIndex === undefined || commodityIndex === -1) return;
            const commodity = type === 'next' ? commodityData?.commodities_ddo?.data[commodityIndex + 1]
                : commodityData?.commodities_ddo?.data[commodityIndex - 1];
            if (commodity) {
                handleSelectCode(commodity);
            }
        }
    };

    useEffect(() => {
        if (commodityData?.commodities_ddo?.data && commodityData?.commodities_ddo?.data?.length > 0) {
            const menuOptions = commodityData?.commodities_ddo?.data.map((d) => ({
                value: d.id, label: d.self_code_desc,
            }));
            setSelectMenuState(menuOptions);
        }
        if (!commodityData) setSelectMenuState([]);
    }, [commodityData]);

    return (
        <div className={classes.TableHeader}>
            {open && <AddEditCommodities open={open} setOpen={setOpen} />}
            {openDuplicateRow && <DuplicateRowModal open={openDuplicateRow} setOpen={setOpenDuplicateRow} />}
            <Box className={classes.inTableHeader}>
                <Tooltip title={userOrg && userOrg?.gcoa_label}>
                    <Typography variant="h2" noWrap>
                        {userOrg?.gcoa_label}
                    </Typography>
                </Tooltip>
                <OrgCostHeaderBar />
                <Box className={classes.rightSection}>
                    {/* <Box className={classes.outlineButton}>
                        <Button>
                            <BarChartIcon />
                            Benchmark
                        </Button>
                    </Box>
                    <Box className={classes.outlineButton}>
                        <Button>
                            <DataSaverOffOutlinedIcon />
                            Reports
                        </Button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            Reports
                        </Button>
                    </Box>
                    <SelectDropdown
                        view={isGlobalSearchActive ? '' : selectCode}
                        menuOptions={selectMenuState}
                        handleChange={handleCommoditiesChange}
                        handleArrow={handleArrow}
                    />
                    <SearchFilter setSearchText={setSearchText} searchText={searchText} />
                    {!isGlobalSearchActive
                    && (
                        <HideUnusedButton
                            hideUnusedFlagVal={hideUnusedFlag}
                            setHideUnusedFlag={setHideUnusedFlag}
                            loadingData={isCommoditiesLoading}
                        />
                    )}
                </Box>
            </Box>
            <Box className={classes.rightSection}>
                <Box className={classes.outlineButton}>
                    {(userRole?.role === 'Admin' || userRole?.role === 'Super Admin') && !selectedCommodities.length && (
                        <Box className={classes.outlineButton}>
                            <Button onClick={() => handleClickOpenImport()}>
                                <CloudDownloadOutlinedIcon />
                                Import
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box className={classes.outlineButton}>
                    <Button onClick={handleExportExcel}>
                        <CloudUploadOutlinedIcon />
                        Export
                    </Button>
                </Box>
                <Box className={classes.deleteButton}>
                    {(userRole?.role === 'Admin' || userRole?.role === 'Super Admin') && !!selectedCommodities.length && (
                        <Button
                            variant="outlined"
                            onClick={() => handleDelete()}
                        >
                            <DeleteOutlineOutlinedIcon />
                            {' '}
                            Delete
                        </Button>
                    )}
                </Box>
                {/* <Box className={classes.outlineButton}>
                    <Button>
                        <EditOutlinedIcon />
                        Edit
                    </Button>
                </Box> */}
                {/* <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        New
                    </Button>
                </Box> */}
            </Box>
        </div>
    );
}

export default CommoditiesHeader;
