import { Core } from '@grapecity/activereports';

/**
 * Date parser function for activeReportJs
 * @param value
 * @returns
 */
function parseDate(value: string) {
    return new Date(+value).toDateString();
}

const customCode = [
    {
        name: 'parseDate',
        body: parseDate,
        info: {
            description: 'Format a millisecond value to date string',
            example: 'Code.parseDate(Milliseconds)',
            syntax: 'Code.parseDate(<string>)',
        },
    },
];

Core.CustomCode.registerFunctions(customCode);

Core.setLicenseKey(process.env.REACT_APP_ACTIVE_REPORT_JS_LICENSE_KEY || '');
