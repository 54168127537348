import React from 'react';
import {
    Box, FormControl, Button, Grid, InputLabel, MenuItem, Select, TextField, Typography, Stack, DialogContent, DialogActions,
}
    from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import FormatModelStyle from './FormatModel.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function FomatModal(props: {
    editFormat: boolean;
    setEditFormat: (editFormat: boolean) => void;
  }): React.JSX.Element {
    const {
        editFormat, setEditFormat,
    } = props;

    const handleFormatOpen = () => {
        setEditFormat(true);
    };
    const handleFormatClose = () => {
        setEditFormat(false);
    };
    const classes = FormatModelStyle();
    return (
        <div>
            <Dialog
                open={editFormat}
                onClose={handleFormatClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '60%',
                        minHeight: 320,
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h5" component="h5">
                        Edit Project Location
                    </Typography>

                </DialogTitle>
                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Box component="div" className={classes.boxWrapformat}>
                        <Box component="div" className={classes.bggray}>
                            <Grid container spacing={2} xs={12}>
                                {/* First Row */}
                                <Grid item justifyContent="center" xs={2}>
                                    <Typography className={classes.forheading} variant="h6" component="h6" sx={{ marginTop: '38px !important' }}>
                                        WBS Format

                                    </Typography>
                                </Grid>
                                <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}>
                                    <TextField
                                        sx={{ marginTop: '35px !important' }}
                                        className={classes.InputWhite}
                                        id="outlined-basic"
                                        variant="outlined"
                                        placeholder="3 Levels"
                                        fullWidth
                                        size="small"
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h6" component="h6" className={classes.digitext}>Digits per levels</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="2" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="1" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="1" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="" size="small" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} sx={{ ml: '20px' }}>
                                    <Typography variant="h6" component="h6" className={classes.digitext}>Separator</Typography>
                                    <FormControl sx={{ m: 0, minWidth: 230 }} size="small">
                                        {/* <InputLabel id="demo-select-small-label">None</InputLabel> */}
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            fullWidth
                                            className={classes.InputWhite}
                                            IconComponent={KeyboardArrowDownIcon}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <Typography variant="body2" component="span" className={classes.exptext}>(Example : 0000)</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box component="div" className={classes.bgwhite}>
                            <Grid container spacing={2} xs={12}>
                                {/* Second Row */}
                                <Grid item justifyContent="center" xs={2}>
                                    <Typography className={classes.forheading} variant="h6" component="h6" sx={{ marginTop: '38px !important' }}>
                                        COA Format
                                    </Typography>
                                </Grid>
                                <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}>
                                    <TextField
                                        sx={{ marginTop: '35px !important' }}
                                        className={classes.InputWhite}
                                        id="outlined-basic"
                                        variant="outlined"
                                        placeholder="3 Levels"
                                        fullWidth
                                        size="small"
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h6" component="h6" className={classes.digitext}>Digits per levels</Typography>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="2" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="1" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="1" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="" size="small" />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label="" className={classes.InputWhite} placeholder="" size="small" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} sx={{ ml: '20px' }}>
                                    <Typography variant="h6" component="h6" className={classes.digitext}>Separator</Typography>
                                    <FormControl sx={{ m: 0, minWidth: 230 }} size="small">
                                        {/* <InputLabel id="demo-select-small-label">Space</InputLabel> */}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className={classes.InputWhite}
                                            fullWidth
                                            IconComponent={KeyboardArrowDownIcon}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <Typography variant="body2" component="span" className={classes.exptext}>(Example : 00 00 00)</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mr: '20px' }}>
                    <Box>
                        <Button autoFocus onClick={handleFormatClose} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button className={classes.undoButtnNew} onClick={handleFormatClose} autoFocus variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FomatModal;
