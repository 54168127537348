import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center',
        margin: '40px 0px',
    },
}));

function InvitationConfirmation() {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h2>Invitation Accepted!</h2>
            <p>
                You have accepted the invitation to join the organisation.
                {' '}
                <a href="/">Click Here</a>
                {' '}
                to login.
            </p>
        </div>
    );
}

export default InvitationConfirmation;
