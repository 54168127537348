import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ProjectSettingsTabContext } from '../../store/context/projectSettingsTabContext';
import { useUserOrganization } from '../../hooks/useUserRole';
import { Tooltip, Typography } from '@mui/material';
import AppDrawerStyle from './AppDrawer.style';

const StyledLink = styled(Link)`
  color: #1C1C1C;
  text-decoration: none;
`;

const StyledHashLink = styled(HashLink)`
    color: #1C1C1C;
    text-decoration: none;
`;

type Props = {
    title: string,
    path: string,
    icon?: string,
    isActive: boolean,
    visible: boolean,
    isHashLink?: string,
    hashLinkTo?: string,
};

type TabType = {
    general: boolean;
    version: boolean;
    structure: boolean;
    currency: boolean;
    location: boolean;
};

const HASH_LINK_TABS = ['general', 'version', 'currency', 'structure', 'location'];

export function AppDrawerItem(props : Props) {
    const {
        title, path, icon, isActive, visible, isHashLink, hashLinkTo,
    } = props;
    const classes = AppDrawerStyle();
    const projectSettingsContext = useContext(ProjectSettingsTabContext);
    const tabSelected = projectSettingsContext?.tabSelected;
    const userOrg = useUserOrganization();

    const handleHashRoute = () => {
        if (tabSelected) {
            Object.keys(tabSelected).forEach((key) => {
                tabSelected[key as keyof TabType] = false;
            });
        }
    };

    if (!visible) {
        return null;
    }

    // Hashed link creation for Project settings tabs
    if (isHashLink) {
        const tabName = title.toLowerCase();
        const isTabSelected = HASH_LINK_TABS.includes(tabName) && tabSelected && tabSelected[tabName];

        // Adding offset to avoid element getting hidden under header
        const scrollWithOffset = (el: HTMLElement) => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -80;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        };

        return (
            <StyledHashLink
                smooth
                to={hashLinkTo || ''}
                scroll={(el: HTMLElement) => scrollWithOffset(el)}
            >
                <ListItemButton selected={isTabSelected}>
                    {icon ? (
                        <ListItemIcon>
                            <img src={icon} alt={title} />
                        </ListItemIcon>
                    ) : null}
                    <ListItemText primary={title} />
                </ListItemButton>
            </StyledHashLink>
        );
    }

    const getTitle = () => {
        if (title === 'Industries') {
            return (
                <Typography variant="h6" noWrap>
                    <Tooltip title={userOrg && userOrg?.gwbs_label}>
                        <ListItemText primary={userOrg?.gwbs_label || title} className={classes.sidebarLink} />
                    </Tooltip>
                </Typography>
            );
        }
        if (title === 'Commodities') {
            return (
                <Typography variant="h6" noWrap>
                    <Tooltip title={userOrg && userOrg?.gcoa_label}>
                        <ListItemText primary={userOrg?.gcoa_label || title} className={classes.sidebarLink} />
                    </Tooltip>
                </Typography>
            );
        }
        return <ListItemText primary={title} />;
    };

    return (
        <StyledLink to={path} onClick={handleHashRoute}>
            <ListItemButton selected={isActive}>
                {icon ? (
                    <ListItemIcon>
                        <img src={icon} alt={title} />
                    </ListItemIcon>
                ) : null}
                {getTitle()}
            </ListItemButton>
        </StyledLink>
    );
}
