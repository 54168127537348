import React, { useContext, useEffect, useState } from 'react';
import {
    Box, TextField, Typography,
}
    from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Pagination from '../../../../components/paginationTable';
import useStyles from './UserSettings.style';
import Grid from '@mui/material/Grid';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { UserContext } from '../../../../store/context/userContext';
import { useOutletContext } from 'react-router-dom';
import { useUserRole } from '../../../../hooks/useUserRole';
import { ROLES, DEFAULT_PAGE_SIZE } from '../../../../constants';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { formatCurr } from '../../../../hooks/useFormatCurrency';
import orgQueries from '../../../../queries/organisations';
import { OrgUserCellRendererType } from '../../../../types/OrgUserProjects';

function UserSettings() {
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const userId = userContext?.user?.user_id;
    const orgId = userContext?.user?.default_org_id;
    const matchingOrganization = userContext?.user?.orgnizations?.find((org) => org.id === orgId);
    const orgName = matchingOrganization?.name;
    const userOrgRole = useUserRole();
    const [skipValue, setSkipValue] = useState(0);
    const cont: OutletContext = useOutletContext();
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const getUserRole = () => {
        const userRole = ROLES.find((role) => role.id === userOrgRole);
        return userRole?.role;
    };

    const { data, isFetching, refetch: refetchData } = useGQLQuery(
        `GetOrganisationProjects-${0}`,
        orgQueries.GET_ORGANISATIONS_USER_PROJECTS(skipValue, DEFAULT_PAGE_SIZE, orgId || '', userId || ''),
        {},
        {
            onError: handleApiError,
        },
        '/setting',
    );

    const apiData = data?.getorganduserprojects;
    const userData = apiData?.data;
    const rowData = (userData && userData.length) ? userData.filter((item) => item.permission_id !== 0) : [];
    const pageInfo = apiData?.pageInfo;
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const newSkipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setSkipValue(newSkipValue);
    };

    useEffect(() => {
        refetchData();
    }, [skipValue]);

    useEffect(() => {
        if (orgId && userId) {
            refetchData();
        }
    }, [orgId, userId]);

    const customCellRenderer = (params: OrgUserCellRendererType) => {
        const colName = params?.colDef?.field;
        if (params.value !== null) {
            switch (colName) {
            case 'permission_id':
                if (params?.value === 0) return 'NONE';
                if (params?.value === 1) return 'VIEW';
                if (params?.value === 2) return 'EDIT';
                break;
            case 'base_date':
                return new Date(params?.value).getFullYear();
            case 'total_cost':
                return `${params?.data?.currency_data?.symbol || '$'}${formatCurr(+params.value.toFixed(0), 'en-US')}`;
            case 'title':
                return params?.value || 'Title';
            default:
                return params?.value;
            }
        } else if (params?.value === null) {
            return '-';
        }
        return '';
    };

    const columnDefs = [
        {
            field: 'country_name', type: 'string', initialWidth: 180, headerName: 'Country',
        },
        {
            field: 'region', type: 'string', initialWidth: 180, headerName: 'Region',
        },
        {
            field: 'name', type: 'string', initialWidth: 400, headerName: 'Name',
        },
        {
            field: 'title', type: 'string', initialWidth: 150, headerName: 'Title', cellRenderer: customCellRenderer,
        },
        {
            field: 'owner', type: 'string', initialWidth: 180, headerName: 'Owner',
        },
        {
            field: 'consultant', type: 'string', initialWidth: 150, headerName: 'Consultant',
        },
        {
            field: 'total_cost',
            type: 'numericColumn',
            initialWidth: 180,
            headerName: 'Total',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'base_date', type: 'string', initialWidth: 120, headerName: 'Year', cellRenderer: customCellRenderer,
        },
        {
            field: 'version_code', type: 'number', initialWidth: 100, headerName: 'Version',
        },
        {
            field: 'permission_id', type: 'number', initialWidth: 130, headerName: 'Permission', cellRenderer: customCellRenderer,
        },
    ];

    return (
        <div>
            <div>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h3">
                        User Settings
                    </Typography>
                    {/* <Button
                            className={classes.delete}
                            variant="outlined"
                            sx={{ mr: '10px' }}
                        >
                            <DeleteOutlineOutlinedIcon />
                            {' '}
                            Deactivate Account
                        </Button>
                        <Button
                            variant="outlined"
                            className={classes.btngray}
                            sx={{ mr: '10px' }}
                        >
                            <EditOutlinedIcon />
                            {' '}
                            Edit User Name
                        </Button>
                        <Button
                            variant="outlined"
                            className={classes.btngray}
                            sx={{ mr: '10px' }}
                        >
                            <LockOutlinedIcon />
                            {' '}
                            Change Password
                        </Button> */}
                </Box>
                <Box sx={{ mt: '38px', mb: '25px' }}>
                    <Grid container spacing={0} xs={4} className={classes.formwrap}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Organisation Name</Typography>
                            <TextField
                                label=""
                                className={classes.disable}
                                fullWidth
                                placeholder={orgName}
                                value={orgName}
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: '20px' }}>
                            <Typography variant="h6">User Role</Typography>
                            <TextField
                                label=""
                                fullWidth
                                className={classes.disable}
                                placeholder={getUserRole()}
                                value={getUserRole()}
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: '20px' }}>
                            <Typography variant="h6">Email Address</Typography>
                            <TextField
                                label=""
                                fullWidth
                                className={classes.disable}
                                value={userContext?.user?.email}
                                placeholder={userContext?.user?.email}
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: '20px' }}>
                            <Typography variant="h6">User Name</Typography>
                            <TextField
                                label=""
                                fullWidth
                                className={classes.disable}
                                value={userContext?.user?.name}
                                placeholder={userContext?.user?.name}
                                size="small"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: '20px' }}>
                            <Typography variant="h6">Password</Typography>
                            <TextField
                                type="password"
                                label=""
                                fullWidth
                                className={classes.disable}
                                value="*********"
                                size="small"
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.inTableHeader} sx={{ mt: '70px' }}>
                    <Typography variant="h3">
                        Projects
                    </Typography>
                    <Box sx={{ mt: '26px' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <AgGridComponent
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                    changeSortingValue={() => {}}
                                    sortOrder=""
                                    noSelection
                                    isLoading={isFetching}
                                    disableResizable
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </div>
        </div>
    );
}

export default UserSettings;
