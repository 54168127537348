import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    projectListMain: {
        marginTop: '30px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 253px) !important',
            },
        },
        '& .ag-row-level-0': {
            backgroundColor: '#88B0FF !important',
            fontWeight: '600 !important',
        },
    },
    opacityBlur: {
        opacity: '50%',
    },
}));
