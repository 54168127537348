import { GET_ALL_INDUSTRY, GET_INDUSTRIES } from '../../queries/industries';
import commoditiesQuery from '../../queries/commodities';
import { DEFAULT_REPORT_TAKE_VALUE, DEFAULT_PAGE_SIZE } from '../../constants';
import projectRecentQuery from '../../queries/DashboardRecentProject';
import { GET_REGION } from '../../queries/region';
import projectQuery from '../../queries/projects';

interface FieldType {
    Name: string,
    DataField: string,
}

interface ReportQueryDetails {
    query: string,
    jsPath: string,
    operationName?: string,
    fields: FieldType[],
    module?: string,
    endpoint?: string,
}

const getOrgQueryDetails = (orgId: string, projectId: string, versionId: string, module: string) => {
    let queryDetails = [] as ReportQueryDetails[];
    switch (module) {
    case 'industries':
        queryDetails.push({
            query: GET_ALL_INDUSTRY(
                0,
                DEFAULT_PAGE_SIZE,
                orgId,
                false,
            ),
            jsPath: '.data.all_industries.data.*',
            fields: [
                // { Name: 'id', DataField: 'id' },
                { Name: 'code', DataField: 'code' },
                { Name: 'description', DataField: 'description' },
                { Name: 'attr_label', DataField: 'attr_label' },
                { Name: 'unit', DataField: 'unit' },
                // { Name: 'level', DataField: 'level' },
                { Name: 'project_count', DataField: 'project_count' },
                // { Name: 'projects', DataField: 'projects' },
                { Name: 'total_cost', DataField: 'total_cost' },
            ],
        });
        break;
    case 'commodities':
        queryDetails.push({
            query: commoditiesQuery.GET_COMMODITIES_WITH_PROJECTS_BY_ORGID(
                orgId,
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                false,
            ),
            jsPath: '.data.commodities_detail.data.*',
            fields: [
                // { Name: 'id', DataField: 'id' },
                { Name: 'code', DataField: 'code' },
                { Name: 'description', DataField: 'description' },
                // { Name: 'attr_label', DataField: 'attr_label' },
                // { Name: 'level', DataField: 'level' },
                // { Name: 'commodities_count', DataField: 'commodities_count' },
                // { Name: 'projects', DataField: 'projects' },
                { Name: 'unit', DataField: 'unit' },
                { Name: 'total_cost', DataField: 'total_cost' },
            ],
        });
        break;
    case 'dashboard':
        // Recent projects data
        queryDetails.push({
            module: 'Recent Projects',
            query: projectRecentQuery.GET_RECENT_PROJECTS(0, DEFAULT_REPORT_TAKE_VALUE, '', orgId),
            jsPath: '.data.project.data.*',
            fields: [
                // { Name: 'id', DataField: 'id' },
                { Name: 'client_number', DataField: 'client_number' },
                { Name: 'name', DataField: 'name' },
                { Name: 'estimate_type', DataField: 'estimate_type' },
                { Name: 'industryDescription', DataField: 'industry.description' },
                // { Name: 'subindustry_id', DataField: 'subindustry_id' },
                { Name: 'subindustry', DataField: 'subindustry.description' },
                { Name: 'country', DataField: 'country.name' },
                { Name: 'region', DataField: 'region' },
                { Name: 'base_date', DataField: 'base_date' },
                // { Name: 'wbs1', DataField: 'wbs1' },
                // { Name: 'wbs2', DataField: 'wbs2' },
                // { Name: 'wbs3', DataField: 'wbs3' },
                // { Name: 'wbs4', DataField: 'wbs4' },
                // { Name: 'wbs5', DataField: 'wbs5' },
                // { Name: 'wbs6', DataField: 'wbs6' },
                // { Name: 'coa1', DataField: 'coa1' },
                // { Name: 'coa2', DataField: 'coa2' },
                // { Name: 'coa3', DataField: 'coa3' },
                // { Name: 'coa4', DataField: 'coa4' },
                // { Name: 'coa5', DataField: 'coa5' },
                // { Name: 'coa6', DataField: 'coa6' },
                // { Name: 'hide_values', DataField: 'hide_values' },
                // { Name: 'coa_sep', DataField: 'coa_sep' },
                { Name: 'consultant', DataField: 'consultant' },
                { Name: 'owner', DataField: 'owner' },
                // { Name: 'consultant_number', DataField: 'consultant_number' },
                // { Name: 'estimate_accuracy', DataField: 'estimate_accuracy' },
                // { Name: 'contingency_wbs', DataField: 'contingency_wbs' },
                // { Name: 'currency', DataField: 'currency' },
                // { Name: 'wbs_sep', DataField: 'wbs_sep' },
                // { Name: 'created_at', DataField: 'created_at' },
                // { Name: 'updated_at', DataField: 'updated_at' },
                // { Name: 'project_number', DataField: 'project_number' },
                { Name: 'total_cost', DataField: 'total_cost' },
                // { Name: 'estimate_accuracy', DataField: 'estimate_accuracy' },
                // { Name: 'version_id', DataField: 'version_id' },
                { Name: 'currency_data', DataField: 'currency_data.code' },
                { Name: 'version_code', DataField: 'version_code' },
                // { Name: 'industry_id', DataField: 'industry_id' },
                // { Name: 'currency_id', DataField: 'currency_id' },
                // { Name: 'is_active ', DataField: 'is_active' },
            ],
        });

        // Regions data
        queryDetails.push({
            module: 'Regions info',
            query: GET_REGION(orgId),
            jsPath: '.data.regions.*',
            fields: [
                'country',
                'region',
                'project_count',
                'total_cost'].map((item) => ({ Name: item, DataField: item })),
        });

        // Industries data
        queryDetails.push({
            module: 'Industries info',
            query: GET_INDUSTRIES(orgId),
            jsPath: '.data.user_industries.*',
            fields: [
                'code',
                'description',
                'project_count',
                'sub_industry',
                'total_cost'].map((item) => ({ Name: item, DataField: item })),
        });

        break;
    case 'projects':
        // Projects data
        queryDetails.push({
            module: 'Projects',
            query: projectQuery.GET_PROJECTS(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                '',
                '',
            ),
            jsPath: '.data.project.data.*',
            fields: [
                { Name: 'client_number', DataField: 'client_number' },
                { Name: 'name', DataField: 'name' },
                { Name: 'estimate_type', DataField: 'estimate_type' },
                { Name: 'industryDescription', DataField: 'industry.description' },
                // { Name: 'subindustry_id', DataField: 'subindustry_id' },
                { Name: 'subindustry', DataField: 'subindustry.description' },
                { Name: 'country', DataField: 'country.name' },
                { Name: 'region', DataField: 'region' },
                // { Name: 'id', DataField: 'id' },
                { Name: 'base_date', DataField: 'base_date' },
                // { Name: 'wbs1', DataField: 'wbs1' },
                // { Name: 'wbs2', DataField: 'wbs2' },
                // { Name: 'wbs3', DataField: 'wbs3' },
                // { Name: 'wbs4', DataField: 'wbs4' },
                // { Name: 'wbs5', DataField: 'wbs5' },
                // { Name: 'wbs6', DataField: 'wbs6' },
                // { Name: 'coa1', DataField: 'coa1' },
                // { Name: 'coa2', DataField: 'coa2' },
                // { Name: 'coa3', DataField: 'coa3' },
                // { Name: 'coa4', DataField: 'coa4' },
                // { Name: 'coa5', DataField: 'coa5' },
                // { Name: 'coa6', DataField: 'coa6' },
                // { Name: 'hide_values', DataField: 'hide_values' },
                // { Name: 'coa_sep', DataField: 'coa_sep' },
                { Name: 'consultant', DataField: 'consultant' },
                { Name: 'owner', DataField: 'owner' },
                // { Name: 'consultant_number', DataField: 'consultant_number' },
                // { Name: 'estimate_accuracy', DataField: 'estimate_accuracy' },
                // { Name: 'contingency_wbs', DataField: 'contingency_wbs' },
                // { Name: 'currency', DataField: 'currency' },
                // { Name: 'wbs_sep', DataField: 'wbs_sep' },
                // { Name: 'created_at', DataField: 'created_at' },
                // { Name: 'updated_at', DataField: 'updated_at' },
                // { Name: 'project_number', DataField: 'project_number' },
                { Name: 'total_cost', DataField: 'total_cost' },
                // { Name: 'estimate_accuracy', DataField: 'estimate_accuracy' },
                // { Name: 'version_id', DataField: 'version_id' },
                { Name: 'currency_data', DataField: 'currency_data.code' },
                { Name: 'version_code', DataField: 'version_code' },
                // { Name: 'industry_id', DataField: 'industry_id' },
                // { Name: 'currency_id', DataField: 'currency_id' },
                // { Name: 'is_active ', DataField: 'is_active' },
            ],
        });

        break;
    default:
        queryDetails = [{
            query: '',
            jsPath: '',
            operationName: '',
            fields: [],
        }];
    }

    return queryDetails;
};

export default getOrgQueryDetails;
