// COA page styles
import { makeStyles } from '@mui/styles';

const CoaBanchStyle = makeStyles(() => ({

    tabelFoot: {
        '& .ag-row-level-0': {
            backgroundColor: '#88B0FF !important',
        },
        '& .ag-row-level-1': {
            backgroundColor: '#C1D6FF !important',
        },
        '& .ag-checked': {
            '&:after': {
                color: '#2c2c2c !important',
            },
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '5.5px 32px 5.5px 12px !important',
            fontSize: '13px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#323232',
        },
    },
    undoButtn: {
        marginLeft: '15px',
        '& button': {
            fontSize: '10px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '7px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '2px',
            },
        },
    },
    undoButtnNew: {
        marginLeft: '15px',
        '& button': {
            fontSize: '10px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
                 0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '7px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    popBtn: {
        fontSize: '16px !important',
        fontWeight: '500 !important',
        padding: '5px 20px !important',
    },
    popheight: {
        height: '500px',
    },
    white: {
        '& input': {
            backgroundColor: '#fff',
            padding: '11.5px 14px',
            fontSize: '15px !important',
        },
    },
    whiteSel: {
        backgroundColor: '#fff',
        height: '44px !important',
        fontSize: '15px !important',
    },
    TableHeader: {
        margin: '0px',
        color: '#021948',
        padding: '2px 0px 2px 0px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: 'none',
                opacity: '1',
            },
        },
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    font14: {
        fontSize: '14px !important',
    },
    dView: {
        background: '#fff !important',
        border: '#8d8d8d 1px solid',
        borderRadius: '3px !important',
        '& :hover': {
            background: '#fff !important',
            border: '#8d8d8d 1px solid',
            borderRadius: '3px',
        },
    },
    chartStyle: {
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '9.52px',
        padding: '2.5px 12px',
        '& svg': {
            verticalAlign: 'text-bottom',
        },
        '& span': {
            verticalAlign: 'middle',
        },
    },
    deleteBtn: {
        border: '1px solid #B00000 !important',
        color: '#B00000 !important',
        '& svg': {
            color: '#B00000 !important',
        },
    },
    dTextBox: {
        margin: '5px !important',
        background: '#fff',
    },
}));
export default CoaBanchStyle;
