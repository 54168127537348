import React, { useContext } from 'react';
import { UserContext } from '../store/context/userContext';
import { OrganisationType } from '../types';

const useUserRole = () => {
    const userContext = useContext(UserContext);

    const userRole = userContext && userContext.user && userContext.user.orgnizations
        ? userContext?.user?.orgnizations.find((org) => org.id === userContext.user?.default_org_id)?.role_id : '';

    return userRole;
};

const useUserOrganization = () => {
    const userContext = useContext(UserContext);

    const userOrg = userContext && userContext.user && userContext.user.orgnizations
        ? userContext?.user?.orgnizations.find((org) => org.id === userContext.user?.default_org_id) : {} as OrganisationType;

    return userOrg;
};

export {
    useUserRole,
    useUserOrganization,
};
