import React, {
    useState, useEffect, useContext, useMemo,
} from 'react';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import wbsQueries from '../../queries/wbs';
// import UserContext from '../../context/UserContext';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AgGridTableStyle from '../agGridTableComponent/AgGridTable.style';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';

interface OrgUsersType {
    refetch: () => void
}

interface CellDataType {
    orgHierarchy: string[],
    wbs: string,
    labour_hours: number,
    total_cost: number,
    description: string,
}

interface CellRendererType {
    data: CellDataType & { [colId: string]: number, },
    column: {
        colId: string
    },
    value: number,
    node: {
        footer: boolean
    },
}

const Main = styled('main')({
    flexGrow: 1,
    // height: '80vh',
    overflow: 'auto',
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    margin: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
}));

function AgGridTable(props: { height?: number }) {
    const classes = AgGridTableStyle();
    // const userCtx = useContext(UserContext);
    // const [wbs, setWbs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { height } = props;

    // const orgUsersQuery = useGQLQuery(
    //     'GetWbsDataByOrgId',
    //     wbsQueries.GET_WBS_BY_ORGID({ org_id: userCtx?.org_id }),
    //     {},
    //     {
    //         onSuccess: (data: any) => {
    //             // setWbs(data.wbs);
    //             setIsLoading(false);
    //         },
    //         onError: (error: any) => {
    //             setIsLoading(false);
    //             console.log('ERROR', error);
    //         },
    //         enabled: false,
    //         retry: false,
    //         refetchOnWindowFocus: false,
    //     },
    // ) as OrgUsersType;

    // useEffect(() => {
    //     if (userCtx?.org_id) {
    //         orgUsersQuery.refetch();
    //     }
    // }, [userCtx]);

    const colors = ['#35B83D', '#484DFF'];

    const formatNumber = (number: number) => Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    const customCellRenderer = (params: CellRendererType) => {
        const { colId } = params.column;
        const { data } = params;
        switch (colId) {
        case 'ag-Grid-AutoColumn':
            if (data && data.orgHierarchy.length === 1) {
                if (data.description && data.description !== '') {
                    return <b>{data.description.toUpperCase()}</b>;
                }
                return <b>{data.description}</b>;
            } if (data && data.orgHierarchy.length === 2) {
                return <span style={{ fontWeight: '600' }}>{data.description}</span>;
            } if (!data && params.node.footer) {
                return <b>TOTAL</b>;
            }
            return data ? data.description : '';
            break;
        case 'wbs':
            if (data && data.orgHierarchy.length === 1) {
                return <b>{data.wbs}</b>;
            } if (!data && params.node.footer) {
                return '';
            }
            return data ? data.wbs : '';
            break;
        case 'labour_hours':
            if (!data && params.node.footer) {
                return params.value ? <b>{formatNumber(params.value)}</b> : '-';
            }
            return data && data.labour_hours ? data.labour_hours : '-';
            break;
        case 'total_cost':
            if (!data && params.node.footer) {
                return params.value ? (
                    <b>
                        $
                        {formatNumber(params.value)}
                    </b>
                ) : '-';
            }
            return data && data.total_cost ? (
                <b>
                    $
                    {formatNumber(data.total_cost)}
                </b>
            ) : '-';
            break;
        default:
            if (!data && params.node.footer) {
                return params.value ? (
                    <b>
                        $
                        {formatNumber(params.value)}
                    </b>
                ) : '-';
            }
            return data && data[colId] ? `$${formatNumber(data[colId])}` : '-';
        }
    };

    const groupCellRenderer = (params: CellRendererType) => {
        const { data } = params;
        if (data && data.orgHierarchy.length === 1) {
            if (data.description && data.description !== '') {
                return <b>{data.description.toUpperCase()}</b>;
            }
            return <b>{data.description}</b>;
        } if (data && data.orgHierarchy.length === 2) {
            return (
                <span style={{ fontWeight: '600' }}>
&emsp;
                    {data.description}
                </span>
            );
        } if (!data && params.node.footer) {
            return <b>TOTAL</b>;
        }
        return data ? (
            <span>
&emsp;&emsp;
                {data.description}
            </span>
        ) : '';
    };

    const columnDefs = [
        {
            field: 'blank', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 60, cellRenderer: customCellRenderer,
        },
        { field: 'wbs', maxWidth: 90, cellRenderer: customCellRenderer },
        {
            field: 'labour_hours', type: 'numericColumn', width: 130, headerName: 'Labour hours', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'labour', type: 'numericColumn', width: 130, aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'c_equipment', type: 'numericColumn', width: 130, headerName: 'C.Equipment', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'material', type: 'numericColumn', width: 130, aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'subcontract', type: 'numericColumn', width: 130, aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'equipment', type: 'numericColumn', width: 130, aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'distributables', type: 'numericColumn', width: 130, aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
        {
            field: 'total_cost', type: 'numericColumn', width: 130, headerName: 'Total Cost', aggFunc: 'sum', cellRenderer: customCellRenderer,
        },
    ];

    const rowData = [
        {
            wbs: 1000,
            description: 'SITE ACCESS & DEVELOPMENT',
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT'],
        },
        {
            wbs: 1100,
            description: 'General Site Development',
            labour_hours: 14232,
            labour: 1502861,
            c_equipment: 409346,
            material: 564268,
            subcontract: 0,
            equipment: 0,
            distributables: 425953,
            total_cost: 2902429,
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT', 'General Site Development'],
        },
        {
            wbs: 1120,
            description: 'Site Ditches & Safety Berms',
            labour_hours: 1380,
            labour: 145759,
            c_equipment: 136666,
            material: 63871,
            subcontract: 0,
            equipment: 0,
            distributables: 59563,
            total_cost: 405860,
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT', 'General Site Development', 'Site Ditches & Safety Berms'],
        },
        {
            wbs: 1140,
            description: 'Site Utility Supply Corridor',
            labour_hours: 11991,
            labour: 1266233,
            c_equipment: 1230464,
            material: 562770,
            subcontract: 0,
            equipment: 0,
            distributables: 526228,
            total_cost: 3585697,
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT', 'General Site Development', 'Site Utility Supply Corridor'],
        },
        {
            wbs: 1200,
            description: 'Access Roads',
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT', 'Access Roads'],
        },
        {
            wbs: 1600,
            description: 'Power Supply/Generation',
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT', 'Power Supply/Generation'],
        },
        {
            wbs: 1700,
            description: 'Water Supply',
            orgHierarchy: ['SITE ACCESS & DEVELOPMENT', 'Water Supply'],
        },
        {
            wbs: 7000,
            description: 'SERVICES AND UTILITIES FACILITIES',
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES'],
        },
        {
            wbs: 7100,
            description: 'HV Power',
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'HV Power'],
        },
        {
            wbs: 7200,
            description: 'Site-Wide Electrical Distribution',
            labour_hours: 23528,
            labour: 2484551,
            c_equipment: 377302,
            material: 1792465,
            subcontract: 0,
            equipment: 3164542,
            distributables: 800543,
            total_cost: 8619405,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Site-Wide Electrical Distribution'],
        },
        {
            wbs: 7300,
            description: 'Water Systems',
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Water Systems'],
        },
        {
            wbs: 7310,
            description: 'Raw Water',
            labour_hours: 6873,
            labour: 725759,
            c_equipment: 94224,
            material: 446259,
            subcontract: 0,
            equipment: 626273,
            distributables: 217793,
            total_cost: 2110311,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Water Systems', 'Raw Water'],
        },
        {
            wbs: 7315,
            description: 'River Water',
            labour_hours: 11634,
            labour: 1228525,
            c_equipment: 153225,
            material: 565299,
            subcontract: 0,
            equipment: 2032060,
            distributables: 334892,
            total_cost: 4314002,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Water Systems', 'River Water'],
        },
        {
            wbs: 7320,
            description: 'Fresh Water',
            labour_hours: 1614,
            labour: 170399,
            c_equipment: 20993,
            material: 218450,
            subcontract: 0,
            equipment: 6985,
            distributables: 70493,
            total_cost: 487322,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Water Systems', 'Fresh Water'],
        },
        {
            wbs: 7330,
            description: 'Basins/Ponds/Dams',
            labour_hours: 6010,
            labour: 634700,
            c_equipment: 398902,
            material: 594511,
            subcontract: 0,
            equipment: 6985,
            distributables: 280035,
            total_cost: 1908149,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Water Systems', 'Basins/Ponds/Dams'],
        },
        {
            wbs: 7340,
            description: 'Fire Water',
            labour_hours: 16198,
            labour: 1710495,
            c_equipment: 235491,
            material: 945999,
            subcontract: 0,
            equipment: 2723498,
            distributables: 497421,
            total_cost: 6112905,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Water Systems', 'Fire Water'],
        },
        {
            wbs: 7400,
            description: 'Wastewater Treatment',
            labour_hours: 11291,
            labour: 1192358,
            c_equipment: 322726,
            material: 585942,
            subcontract: 0,
            equipment: 1408852,
            distributables: 363955,
            total_cost: 3873836,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Wastewater Treatment'],
        },
        {
            wbs: 7500,
            description: 'Vehicle Fueling Site',
            labour_hours: 9820,
            labour: 1022201,
            c_equipment: 159989,
            material: 385981,
            subcontract: 0,
            equipment: 1511781,
            distributables: 275752,
            total_cost: 3355707,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Vehicle Fueling Site'],
        },
        {
            wbs: 7600,
            description: 'Steam & Heat Generation',
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Steam & Heat Generation'],
        },
        {
            wbs: 7610,
            description: 'Natural Gas',
            labour_hours: 1571,
            labour: 160189,
            c_equipment: 15473,
            material: 147827,
            subcontract: 0,
            equipment: 1511781,
            distributables: 55640,
            total_cost: 379130,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Steam & Heat Generation', 'Natural Gas'],
        },
        {
            wbs: 7700,
            description: 'Communications & Security System',
            labour_hours: 1571,
            labour: 160189,
            c_equipment: 15473,
            material: 147827,
            subcontract: 0,
            equipment: 1511781,
            distributables: 55640,
            total_cost: 379130,
            orgHierarchy: ['SERVICES AND UTILITIES FACILITIES', 'Communications & Security System'],
        },
        {
            wbs: 8000,
            description: 'INFRASTRUCTURE FACILITIES',
            orgHierarchy: ['INFRASTRUCTURE FACILITIES'],
        },
        {
            wbs: 8200,
            description: 'On-Site Roads, Parking',
            orgHierarchy: ['INFRASTRUCTURE FACILITIES', 'On-Site Roads, Parking'],
        },
        {
            wbs: 8210,
            description: 'Runaway Road & Ditches',
            labour_hours: 230,
            labour: 24331,
            c_equipment: 26415,
            material: 15378,
            subcontract: 0,
            equipment: 0,
            distributables: 11373,
            total_cost: 77498,
            orgHierarchy: ['INFRASTRUCTURE FACILITIES', 'On-Site Roads, Parking', 'Runaway Road & Ditches'],
        },
        {
            wbs: 8220,
            description: 'Heavy Duty Vehicle Road (HDV)',
            labour_hours: 1006,
            labour: 106197,
            c_equipment: 115572,
            material: 112170,
            subcontract: 0,
            equipment: 0,
            distributables: 57437,
            total_cost: 391378,
            orgHierarchy: ['INFRASTRUCTURE FACILITIES', 'On-Site Roads, Parking', 'Heavy Duty Vehicle Road (HDV)'],
        },
        {
            wbs: 8222,
            description: '#1 Pad Heavy Duty Vehicle',
            orgHierarchy: ['INFRASTRUCTURE FACILITIES', 'On-Site Roads, Parking', '#1 Pad Heavy Duty Vehicle'],
        },
        {
            wbs: 8230,
            description: 'Light Duty Vehicle Road (LDV)',
            labour_hours: 16,
            labour: 1705,
            c_equipment: 1850,
            material: 1077,
            subcontract: 0,
            equipment: 0,
            distributables: 797,
            total_cost: 5432,
            orgHierarchy: ['INFRASTRUCTURE FACILITIES', 'On-Site Roads, Parking', 'Light Duty Vehicle Road (LDV)'],
        },
    ];

    const defaultColDef = useMemo(() => ({
        flex: 2,
        sortable: true,
        filter: true,
        resizable: true,
    }), []);

    const gridOptions: GridOptions = {
        rowHeight: 27,
        enableRangeSelection: true,
        copyHeadersToClipboard: true,
        rowData,
        columnDefs,
        autoGroupColumnDef: {
            headerName: 'Description',
            headerClass: 'groupParentHeader',
            minWidth: 300,
            width: 300,
            cellRenderer: groupCellRenderer,
            cellRendererParams: {
                suppressCount: true,
                // innerRenderer: customCellRenderer,
            },
        },
        treeData: true, // enable Tree Data mode
        animateRows: true,
        groupDefaultExpanded: -1, // expand all groups by default
        getDataPath: (data: CellDataType) => data.orgHierarchy,
        suppressAggFuncInHeader: true,
    };

    const onGridReady = (params: GridReadyEvent) => {
        const { columnApi, api } = params;
        columnApi.moveColumns(['blank', 'wbs'], 0);
    };
    return (
        <Box className={classes.tabelFoot}>
            <Main>
                <Item>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <div className="ag-theme-alpine" style={{ height }}>
                            <AgGridReact
                                onGridReady={onGridReady}
                                gridOptions={gridOptions}
                                defaultColDef={defaultColDef}
                                groupIncludeFooter={false}
                                groupIncludeTotalFooter
                            />
                        </div>
                    </Box>
                </Item>
            </Main>
        </Box>
    );
}

export default AgGridTable;
