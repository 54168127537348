import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    rightLogout: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            color: '#4C4C4C',
            fontSize: '13px',
            fontFamily: 'Inter-Medium',
            fontWeight: '600',
            lineHeight: '16px',
            textTransform: 'capitalize',
            display: 'block',
            maxWidth: '180px',
        },
        '& svg': {
            color: '#1C1C1C',
        },
    },
    rightLogoutAdmin: {
        display: 'block',
        color: '#B3B3B3 !important',
    },
    rightLogoutAdminMain: {
        marginLeft: '10px',
        cursor: 'pointer',
    },
    userRole: {
        color: '#B3B3B3 !important',
        fontFamily: 'Inter-Medium !important',
        fontWeight: '500 !important',
        marginTop: '4px',
    },
    rightLogoutButoon: {
        '& button': {
            minWidth: 'auto',
        },
        '& svg': {
            color: '#323232',
            fontSize: '22px',
        },
    },
    menuStyle: {
        '& li': {
            color: '#8F8F8F',
            '& a': {
                color: '#8F8F8F',
                textDecoration: 'none',
            },
        },
    },
}));

export default useStyles;
