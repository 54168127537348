import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import '@sencha/best-react-grid/dist/themes/grui.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import CoaListStyle from './COAList.style';
import AddCoaModel from '../../components/AddCOAModal';
import EditCoaModel from '../../components/EditCOAModal';
import DuplicateRowModal from '../../components/DuplicateRowModal';
import CostHeaderBar from '../../../../components/costHeaderBar';
import TotalValueType from '../../../../types/TotalValueType';
import CoaDefaultViews from '../COADifferentView/CoaDefaultViews';
import CoaPhaseViews from '../COADifferentView/CoaPhaseViews';
import CoaCaseViews from '../COADifferentView/CoaCaseViews';
import CoaVersionViews from '../COADifferentView/CoaVersionViews';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import exportExcel from '../../../../helper/ExportExcel';
import { OutletContext } from '../../../../types/OrganisationDetailType';
import HideUnusedButton from '../../../../components/HideUnusedButton';

const Main = styled('main')({
    flexGrow: 1,
    overflow: 'auto',
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    margin: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
}));

function COAListView() {
    const classes = CoaListStyle();
    const [open, setOpen] = React.useState<boolean>(false);
    const [coa, setCoa] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);
    const [dup, setDup] = React.useState<boolean>(false);
    const [view, setView] = useState<string>('Default View');
    const [phaseValue, setPhaseValue] = useState<TotalValueType>();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
        setHideUnusedFlag(true);
        setIsFetching(true);
    };

    const getDefTotalValue = (par: TotalValueType) => {
        if (par !== undefined) {
            setPhaseValue(par);
        }
    };

    // const dataFetching = (p: boolean) => {
    //     setisFetchingData(p);
    // };

    const viewsCompo = () => {
        if (view === 'Default View') {
            return (
                <CoaDefaultViews
                    view={view}
                    getDefTotalValue={getDefTotalValue}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    hideUnusedFlag={hideUnusedFlag}
                    setIsFetching={setIsFetching}
                />
            );
        }
        if (view === 'Phase View') {
            return (
                <CoaPhaseViews
                    view={view}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    hideUnusedFlag={hideUnusedFlag}
                    setIsFetching={setIsFetching}
                />
            );
        }
        if (view === 'Case View') {
            return (
                <CoaCaseViews
                    view={view}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    hideUnusedFlag={hideUnusedFlag}
                    setIsFetching={setIsFetching}
                />
            );
        }
        if (view === 'Version View') {
            return (
                <CoaVersionViews
                    view={view}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    hideUnusedFlag={hideUnusedFlag}
                    setIsFetching={setIsFetching}

                />
            );
        }
        return [];
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=coa`);
    };
    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };
    const handleClear = () => {
        setSearchText('');
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'coa',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    return (
        <div>
            {/* Add COA Popup */}
            <AddCoaModel
                open={open}
                setOpen={setOpen}
                coa={coa}
                setCoa={setCoa}
            />
            {/* Edit COA Popup */}
            <EditCoaModel
                open={open}
                setOpen={setOpen}
                edit={edit}
                setEdit={setEdit}
            />
            {/* Duplicate Popup */}
            <DuplicateRowModal
                open={open}
                setOpen={setOpen}
                dup={dup}
                setDup={setDup}
            />
            {/* Page Header */}
            <Box className={classes.tabelFoot}>
                <Main>
                    <Item>
                        <Box sx={{ height: '100%', width: '100%' }}>
                            {/* Page Header Start */}
                            <div className={classes.TableHeader}>
                                <Box className={classes.inTableHeader}>
                                    <Typography variant="h2">
                                        COA
                                    </Typography>
                                    <CostHeaderBar />
                                    <Box className={classes.versonUndo}>
                                        {/* <Box className={classes.undoButtn}>
                                            <Button>
                                                <BarChartOutlinedIcon />
                                                {' '}
                                                Benchmark
                                            </Button>
                                        </Box> */}
                                        <Box className={classes.outlineButton}>
                                            <Button onClick={navigateToReports}>
                                                <DataSaverOffOutlinedIcon />
                                                {' '}
                                                Reports
                                            </Button>
                                        </Box>
                                        <FormControl variant="filled">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={view}
                                                label="view"
                                                onChange={handleChange}
                                                IconComponent={KeyboardArrowDownIcon}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            '& .MuiMenuItem-root': {
                                                                color: '#787878',
                                                            },
                                                            '& .Mui-selected': {
                                                                color: '#021948',
                                                                bgcolor: '#E6ECFA !important',
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value="Default View">
                                                    Default View
                                                </MenuItem>
                                                <MenuItem value="Phase View">Phase View</MenuItem>
                                                <MenuItem value="Case View">Case View</MenuItem>
                                                <MenuItem value="Version View">Version View</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <QuickSearchFilter searchText={searchText} setSearchText={setSearchText} />
                                    {!searchText
                                    && (
                                        <HideUnusedButton
                                            hideUnusedFlagVal={hideUnusedFlag}
                                            setHideUnusedFlag={setHideUnusedFlag}
                                            loadingData={isFetching}
                                        />
                                    )}
                                </Box>
                                <Box className={classes.outlineButton}>
                                    <Button onClick={handleExportExcel}>
                                        <CloudUploadOutlinedIcon />
                                        Export
                                    </Button>
                                </Box>
                            </div>
                            <Box className={classes.tableSec}>
                                {viewsCompo()}
                            </Box>
                        </Box>
                    </Item>
                </Main>
            </Box>
        </div>
    );
}

export default COAListView;
