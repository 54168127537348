import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '10px !important',
            maxWidth: '360px',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dilogBoxSectionText: {
        fontSize: '16px !important',
        color: '#050505 !important',
        padding: '16px 0px',
    },

    dilogBoxSectionFooter: {
        padding: '16px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        '& button': {
            fontSize: '15px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '8px 33px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px !important',
        '& button': {
            fontSize: '15px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '8px 33px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    labelSideText: {
        color: '#353535',
        marginRight: '4px !important',
    },
    mainTextLabel: {
        fontSize: '18px !important',
        fontWeight: '600 !important',
        color: '#353535',
        padding: '6px 0px',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '32px 32px 50px !important',
        '& input': {
            padding: '6px 12px',
            height: '27px',
            fontSize: '14px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            '&:placehoder': {
                color: '#6A6A6A',
            },
        },
    },

    labelText: {
        marginBottom: '4px !important',
        color: '#353535',
    },
    labelTextFirst: {
        marginBottom: '9px !important',
        marginLeft: '112px !important',
        color: '#353535',
    },
    smText: {
        width: '41px !important',
        marginLeft: '10px !important',
    },
    sideTextInput: {
        alignItems: 'center',
        display: 'flex',
    },
}));
