import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_LABOUR_AND_CREW_INFO = (orgId: string, projectId: string, versionId: string) => gql`
query {
  labour_crew_rates(project_id: ${addEscapeCharacters(projectId)},
  org_id: ${addEscapeCharacters(orgId)},
  version_id: ${addEscapeCharacters(versionId)}) {
    data {
      code1
      code2
      code3
      code4
      code5
      code6
      code
      level
      account_description
      labour_hours
      labour_rate
      const_distribution
      sub_total
      const_equip
      total
      prod_factor
    },
    total_values {
      total_labour_hours
      total_labour_rate
      total_const_distribution
      total_sub_total
      total_const_equip
      total_total
      total_prod_factor
    }
  }
}`;

export default { GET_LABOUR_AND_CREW_INFO };
