import React, { useContext } from 'react';
import OrgCostHeaderBarStyle from './OrgCostHeaderBar.style';
import { Box } from '@mui/material';
import { formatCurr } from '../../hooks/useFormatCurrency';
import { OrganisationContext } from '../../store/context/organisationContext';

export default function OrgCostHeaderBar() {
    const classes = OrgCostHeaderBarStyle();
    const orgCtx = useContext(OrganisationContext);

    const orgCost = orgCtx?.totalOrgCost;
    const totalProj = orgCtx?.totalProjects;

    return (
        <Box className={classes.chartStyle}>
            <span>
                Total Projects -
                &nbsp;
                {totalProj?.project_count}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: '#353535' }}>
                Total Value -
                &nbsp;
                {orgCost?.total ? `$${formatCurr(+orgCost.total, 'en-US')}` : 0}
            </span>
        </Box>
    );
}
