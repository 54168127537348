/* eslint-disable no-useless-escape */
const EMAIL_REGEX = /^\S+@\S+\.\S+$/;
const USERNAME_MAX_LENGTH_ALLOWED = 64;

function emailValidation(email: string) {
    return !!email.match(EMAIL_REGEX);
}

function addEscapeCharacters(str: string | null) {
    return str ? `\"${str}\"` : '';
}

function usernameValidation(username: string) {
    return username.length <= USERNAME_MAX_LENGTH_ALLOWED;
}

function generateRandom() {
    const r = (Math.random() + 1).toString(36).substring(7);
    return r;
}

const truthyCodeValidation = (value: any) => Boolean(value);

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// Allow input if not more than one space in a row
function checkSpace(value: string) {
    return !/\s{2,}/.test(value);
}

export {
    emailValidation,
    addEscapeCharacters,
    usernameValidation,
    generateRandom,
    truthyCodeValidation,
    capitalizeFirstLetter,
    checkSpace,
};
