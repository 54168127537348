import React, { useContext, useEffect, useState } from 'react';
import ResourcesHeader from '../../components/PackageHeader';
import { Box } from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import useStyles from './Package.style';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import GET_PACKAGES from '../../../../queries/packages'; // Import your GraphQL query
import Loader from '../../../../components/Loader';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import { useDisplayCurrFormatter, formatCurr } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';

interface CellDataType {
  orgHierarchy: string[];
  wbs: string;
  labour_hours: number;
  total_cost: number;
  description: string;
}

interface CellRendererType {
  data: CellDataType & { [colId: string]: number };
  colDef: {
    field: string
},
  column: {
    colId: string;
  };
  value: number;
  node: {
    footer: boolean;
  };
}

interface Totals {
    labour_hoursTotal: number;
    labourTotal: number;
    c_equipmentTotal: number;
    materialTotal: number;
    subcontractTotal: number;
    equipmentTotal: number;
    distributableTotal: number;
    total_costTotal: number;
    sustaining_total: number;
}

function Package() {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const formatCurrency = useDisplayCurrFormatter();
    const [searchText, setSearchText] = useState<string>('');

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);

    const formatNumber = (number: number) => Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const customCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        switch (colName) {
        case 'c_equipment':
            return params.value || params.value === 0 ? `${formatCurrency(params.value.toFixed(2))}` : '-';
        case 'material':
            return params.value || params.value === 0 ? `${formatCurrency(params.value)}` : '-';
        case 'subcontract':
            return params.value || params.value === 0 ? `${formatCurrency(params.value)}` : '-';
        case 'equipment':
            return params.value || params.value === 0 ? `${formatCurrency(params.value)}` : '-';
        case 'distributable':
            return params.value || params.value === 0 ? `${formatCurrency(params.value.toFixed(2))}` : '-';
        case 'labour':
            return params.value || params.value === 0 ? `${formatCurrency(params.value)}` : '-';
        case 'labour_hours':
            return params.value || params.value === 0 ? formatCurr(+(+params.value).toFixed(0), local) : '-';
        case 'total_cost':
            return params.value || params.value === 0 ? `${formatCurrency(params.value)}` : '-';
        default:
            return params.value || params.value === 0 ? `${formatCurrency(params.value)}` : '-';
        }
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetPackage',
        GET_PACKAGES.GET_PACKAGES(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
    );

    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };
    const packageData = data?.package?.data?.packageData;
    const pageInfo = data?.package?.pageInfo;
    const totalValue = data?.package?.data?.total;
    const tableData = packageData && packageData.length ? packageData : [];

    const createData = (params: Totals | undefined) => [
        {
            name: 'TOTAL',
            labour_hours: params?.labour_hoursTotal,
            labour: params?.labourTotal,
            material: params?.materialTotal,
            // sustaining_cost: params?.sustaining_total,
            equipment: params?.equipmentTotal,
            subcontract: params?.subcontractTotal,
            total_cost: params?.total_costTotal,
            distributables: params?.distributableTotal,
            cons_equipment: params?.c_equipmentTotal,
        },
    ];
    const pinnedBottomRowData = createData(totalValue);

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
    const columnDefs = [
        {
            field: 'code',
            initialWidth: 130,
            headerName: 'Code',
            aggFunc: 'sum',
            type: 'string',
        },
        {
            field: 'name',
            initialWidth: 200,
            headerName: 'Package Name',
            aggFunc: 'sum',
            type: 'string',
        },
        {
            field: 'labour_hours',
            initialWidth: 200,
            headerName: 'Labour hours',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'labour',
            initialWidth: 150,
            headerName: 'Labour',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'cons_equipment',
            initialWidth: 200,
            headerName: 'C.Equipment',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'material',
            initialWidth: 150,
            headerName: 'Material',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'subcontract',
            initialWidth: 200,
            headerName: 'Subcontract',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'equipment',
            initialWidth: 150,
            headerName: 'Equipment',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'distributables',
            initialWidth: 200,
            headerName: 'Distributables',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'total_cost',
            initialWidth: 150,
            headerName: 'Total Cost',
            aggFunc: 'sum',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
    ];

    return (
        <Box>
            <ResourcesHeader
                search={search}
                setSearchText={setSearchText}
                searchText={searchText}
                hideUnusedFlag={hideUnusedFlag}
                setHideUnusedFlag={setHideUnusedFlag}
                isPackageFetching={isFetching}
            />
            <Box className={classes.tableSec}>
                <AgGridComponent
                    columnDefs={columnDefs}
                    rowData={tableData}
                    changeSortingValue={() => {}}
                    pinnedBottomRowData={pinnedBottomRowData}
                    isRangeSelectable
                    isGroupable
                    isExportEnabled
                    isClipboardEnabled
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    moduleName="Package"
                    isPinnable
                    colFormat={['labour', 'c_equipment', 'material', 'subcontract', 'equipment', 'distributables', 'total_cost', 'cons_equipment']}
                    footerFormat={['labour', 'c_equipment', 'material', 'subcontract', 'equipment', 'distributables', 'total_cost', 'cons_equipment']}
                    disableResizable
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
                <Loader loading={isFetching} />
            </Box>
        </Box>
    );
}
export default Package;
