import { makeStyles } from '@mui/styles';

const HideUnusedButtonStyle = makeStyles(() => ({
    selectionSec: {
        display: 'flex',
        alignItems: 'center',
    },
    visibilityOutlineButton: {
        marginLeft: '19px',
        '& button': {
            fontSize: '12px',
            color: '#007DFF',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3.5px 11.62px',
            height: '30px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter',
            '& svg': {
                color: '#007DFF',
                fontSize: '18px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    visibilityOffOutlineButton: {
        marginLeft: '19px',
        '& button': {
            fontSize: '12px',
            color: '#787878 !important',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            border: '1px solid #787878',
            padding: '3.5px 11.62px',
            height: '30px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter',
            '& svg': {
                color: '#787878',
                fontSize: '18px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
}));

export default HideUnusedButtonStyle;
