import { makeStyles } from '@mui/styles';

const RestoreVersionModalStyle = makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '6px !important',
            width: '50%',
            maxWidth: '738px !important',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    labelText: {
        marginBottom: '6px !important',
        color: '#353535',
    },
    secondHead: {
        padding: '30px 0px 14px 0px',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '0px !important',
        '& input': {
            padding: '6px',
            height: '22px',
            fontSize: '14px',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
        },
    },
    sectionBody: {
        padding: '32px !important',
        '& p': {
            color: '#787878 !important',
            padding: '32px',
        },
        '& h4': {
            color: '#787878 !important',
            fontWeight: '600 !important',
        },
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '14px',
            height: '22px !important',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
        },
        '& button': {
            textTransform: 'unset !important',
            fontSize: '16px !important',
            border: '1px solid #787878 !important',
            color: '#787878 !important',
            fontFamily: 'Inter-Medium !important',
        },
    },
    dilogBoxSectionFooter: {
        padding: '10px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
}));

export default RestoreVersionModalStyle;
