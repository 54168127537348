import React from 'react';
import {
    Box, Button, Typography,
    Container, DialogActions, DialogContent,
}
    from '@mui/material';

import Dialog from '@mui/material/Dialog';
import GeneralStyle from './SaveGeneral.style';

function SaveGeneralModal(props: {
    save: boolean;
    setSave: (open: boolean) => void;
  }): React.JSX.Element {
    const classes = GeneralStyle();
    const {
        save, setSave,
    } = props;

    const handleSaveOpen = () => {
        setSave(true);
    };
    const handleSaveClose = () => {
        setSave(false);
    };

    return (
        <div>
            <Dialog
                open={save}
                onClose={handleSaveClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '32%',
                        minHeight: 150,
                    },
                }}
            >
                <DialogContent sx={{ p: '30px 6px', backgroundColor: '#fff' }}>
                    <Container>
                        <Typography component="h4" variant="h4" className={classes.heading}>
                            Are you sure you want to save the changes ?
                        </Typography>
                        <Typography component="span" variant="body2" className={classes.Subheading}>
                            These changes aren’t permanent and you can modify them later.
                        </Typography>
                    </Container>

                </DialogContent>
                <DialogActions sx={{ mr: '20px' }}>
                    <Box>
                        <Button autoFocus onClick={handleSaveClose} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box className={classes.undoButtnNew}>
                        <Button onClick={handleSaveOpen} autoFocus variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SaveGeneralModal;
