import React, {
    createContext, ReactNode, useState, useMemo, useEffect, useContext,
} from 'react';
import {
    JSONParse, JSONParseArray, getDecodedItemDataFromLocalStorage, setEncodedItemDataToLocalStorage,
} from '../../helper/StorageHelper';
import { QueryReturnData, useGQLQuery } from '../../hooks/useGQLQuery';
import costQueries from '../../queries/orgCost';
import projectCs from '../../queries/case';
import { GET_CURRENCY } from '../../queries/projectCurrency';
import OrgCostType from '../../types/OrgCostType';
import { UserContext } from './userContext';
import projectInfo from '../../queries/projectInfo';
import { OutletContext, QueryErrorResponse } from '../../types/OrganisationDetailType';
import { useOutletContext } from 'react-router-dom';
import ProjectListType from '../../types/ProjectListType';
import CaseType from '../../types/CaseType';

interface Props {
    children: ReactNode
  }

interface ProjectContextProps {
    project: ProjectListType | undefined;
    setProject: (project: ProjectListType) => void;
    projectCurrencyData: QueryReturnData | undefined;
    setProjectCurrencyData: (projectCurrency: QueryReturnData) => void;
    refetchCurrency: () => void;
    orgTotalCost: OrgCostType | undefined;
    refetchProject: () => void;
    isProjectFetching: boolean;
    projectCaseData: CaseType[] | undefined;
    refetchCase: () => void;
}

const ProjectContext = createContext<ProjectContextProps | null>(null);
function ProjectContextProvider(props: Props) {
    const { children } = props;
    /* ---------------------- All State and const variables ----------------------- */
    const cont: OutletContext = useOutletContext();
    const localStorageProject = getDecodedItemDataFromLocalStorage('project_data') || undefined;
    const orgCostLS = getDecodedItemDataFromLocalStorage('org-cost') || undefined;
    const caseLS = getDecodedItemDataFromLocalStorage('project_case') || undefined;
    const defaultOrgId = useContext(UserContext)?.user?.default_org_id;
    const [project, setProjectState] = useState<ProjectListType>(JSONParse<ProjectListType>(localStorageProject));
    const [orgTotalCost, setOrgTotalCost] = useState<OrgCostType>(JSONParse<OrgCostType>(orgCostLS));
    const [projectCaseData, setProjectCase] = useState<CaseType[]>(JSONParseArray(caseLS));

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    /* ----------------------- Fetch Currency Data -------------------------------- */
    const { data: currencyData, isFetching, refetch: refetchCurrency } = useGQLQuery(
        `ProjectCurrency-${project?.id || ''}`,
        GET_CURRENCY(defaultOrgId || '', project?.id || ''),
        {},
        {
            enabled: !!project?.id && !!defaultOrgId,
        },
        '/setting',
    );

    const { data: orgCost, isFetching: orgCostFetching, refetch: refetchProjCost } = useGQLQuery(
        `GetProjectCost-${project.version_id}`,
        costQueries.GET_PROJECT_COST(
            defaultOrgId || '',
            project?.id || '',
            project?.version_id || '',
        ),
        {},
        { enabled: false },
        '/list',
    );

    const { data: projectCase, isFetching: isCaseFetching, refetch: refetchCase } = useGQLQuery(
        `GetProjectCase-${project.version_id || 0}`,
        projectCs.GET_PROJECT_CASE(
            defaultOrgId || '',
            project?.id || '',
            project?.version_id || '',
        ),
        {},
        {
            enabled: false,
            onError: handleApiError,
        },
        '/setting',
    );

    useEffect(() => {
        if (!isCaseFetching && projectCase && projectCase.case) {
            setEncodedItemDataToLocalStorage('project_case', JSON.stringify(projectCase.case));
            setProjectCase(projectCase.case);
        }
    }, [isCaseFetching]);

    useEffect(() => {
        if (project && project.id) {
            // Re-fetch cost header bar data when project changes
            refetchProjCost();
            refetchCase();
        }
    }, [project]);

    /* ----------------------- Set new Project -------------------------------- */
    // Set it in localstorage & also refetch the available currencies.
    const setProject = (data: ProjectListType) => {
        setEncodedItemDataToLocalStorage('project_data', JSON.stringify(data));
        setProjectState(data);
        refetchCurrency();
    };

    /* ----------------------- ReFetch Project -------------------------------- */
    const { isFetching: isProjectFetching, refetch: refetchProject } = useGQLQuery(
        'GetProjectInfoDataById',
        projectInfo.GET_PROJECTIFO_BY_ID(project?.id || '', defaultOrgId || ''),
        {
        },
        {
            onError: handleApiError,
            onSuccess: (data) => {
                setProject(data.project_setting);
            },
            enabled: false,
        },
        '/setting',
    );

    const [projectCurrencyData, setProjectCurrencyData] = useState<QueryReturnData>();

    // After currencies are fetched set data
    useEffect(() => {
        if (currencyData?.getprojectCurrency && !isFetching) {
            const baseIndex = currencyData?.getprojectCurrency.findIndex((item) => item.isBaseCurrency);
            const baseItem = currencyData?.getprojectCurrency.splice(baseIndex, 1);
            currencyData?.getprojectCurrency.unshift(...baseItem);
            setProjectCurrencyData(currencyData);
        }
    }, [isFetching]);

    // After org cost are fetched set data to state
    useEffect(() => {
        if (orgCost?.projectCost && !orgCostFetching) {
            setEncodedItemDataToLocalStorage('org-cost', JSON.stringify(orgCost?.projectCost));
            setOrgTotalCost(orgCost?.projectCost);
        }
    }, [orgCostFetching]);

    const defaultValue = useMemo(() => ({
        project,
        setProject,
        projectCurrencyData,
        setProjectCurrencyData,
        refetchCurrency,
        orgTotalCost,
        refetchProject,
        isProjectFetching,
        projectCaseData,
        refetchCase,
    }), [project, setProject]);

    return (
        <ProjectContext.Provider value={defaultValue}>
            {children}
        </ProjectContext.Provider>
    );
}

export { ProjectContext };
export default ProjectContextProvider;
