import React, { useContext, Dispatch, SetStateAction } from 'react';
import {
    Box, Button, Grid, TextField, Typography,
    Container, DialogActions, DialogContent,
}
    from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EditCurrencyStyle from './EditCurrency.style';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import { CHANGE_PROJECT_CURRENCY } from '../../../../queries/changeCurrency';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import ProjectCurrencyType from '../../../../types/ProjectCurrencyType';
import NotificationBarType from '../../../../types/NotificationBarType';
import NotificationBar from '../../../../components/NotificationBar';
import Loader from '../../../../components/Loader';

function EditCurrencyModal(props: {
    editCurrency: boolean;
    refetchCurrency: () => void
    setEditCurrency: (editCurrency: boolean) => void;
    editItem: ProjectCurrencyType | undefined;
    setEditItem: (editItem: ProjectCurrencyType | undefined) => void;
    setProjectCurrencyId: Dispatch<SetStateAction<string>>,
  }): React.JSX.Element {
    const classes = EditCurrencyStyle();
    const {
        editCurrency, setEditCurrency, editItem, refetchCurrency, setEditItem, setProjectCurrencyId,
    } = props;

    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const [toBeBaseCurrency, setToBeBaseCurrency] = React.useState(editItem?.isBaseCurrency);
    const [notificationDetails, setNotificationDetails] = React.useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });

    const handleEditSuccess = () => {
        refetchCurrency();
        setNotificationDetails({
            open: true,
            message: 'Base currency successfully updated.',
            type: 'success',
        });
        setEditItem(undefined);
        setProjectCurrencyId('');
        setToBeBaseCurrency(editItem?.isBaseCurrency || false);
    };

    const { mutate: changeCurrency, isLoading } = useGQLMutation(
        'ChangeCurrency',
        CHANGE_PROJECT_CURRENCY(userCtx?.user?.default_org_id || '', projectCtx?.project?.id || '', editItem?.id || ''),
        {
            onSuccess: handleEditSuccess,
        },
        '/setting',
    );
    const handleCheckBox = () => {
        setToBeBaseCurrency(!toBeBaseCurrency);
    };

    const handleEdit = () => {
        if (toBeBaseCurrency) {
            changeCurrency({});
        }
        setEditCurrency(false);
    };

    const handleCloseModel = () => {
        setEditCurrency(false);
        setToBeBaseCurrency(editItem?.isBaseCurrency || false);
    };

    return (
        <div>
            <Loader loading={isLoading} />
            <Dialog
                open={editCurrency}
                onClose={handleCloseModel}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '70%',
                    },
                }}
            >
                <DialogTitle className={classes.dilogBoxSection}>
                    Edit Currency
                </DialogTitle>
                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Container>
                        <Grid className={classes.addPopup} container spacing={4} sx={{ pt: '10px' }}>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Code</Typography>
                                <TextField
                                    label=""
                                    className={classes.white}
                                    fullWidth
                                    placeholder="CAD"
                                    size="small"
                                    value={editItem?.code}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Name</Typography>
                                <TextField
                                    label=""
                                    className={classes.white}
                                    fullWidth
                                    placeholder="Can Dollars"
                                    size="small"
                                    value={editItem?.name}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <Typography variant="h6" component="span">Rate</Typography>
                                <TextField
                                    label=""
                                    fullWidth
                                    placeholder="1"
                                    size="small"
                                    disabled
                                    value={editItem?.exchange_rate}
                                />
                            </Grid>
                            {/* <Grid xs={12} md={12}>
                                <Typography component="span" variant="body2">
                                    <input type="checkbox" className={classes.checkbox2} checked={toBeBaseCurrency} onChange={handleCheckBox} />
                                    Set as Base Currency
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button onClick={handleCloseModel} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button onClick={handleEdit} variant="contained" color="primary">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
        </div>
    );
}

export default EditCurrencyModal;
