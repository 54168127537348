import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_PROJECT_PHASE = (orgId: string, projectId: string, versionId: string) => gql`
query GetProjectPhase {
    phase (
      org_id:${addEscapeCharacters(orgId)},
      project_id: ${addEscapeCharacters(projectId)},
      version_id: ${addEscapeCharacters(versionId)},
    ) {
        code
        name
        total_cost
      }
}`;

export default { GET_PROJECT_PHASE };
