import { makeStyles } from '@mui/styles';

const UnAvailable = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    heading: {
        color: '#AAA',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '45px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '0px',
    },
    paragraph: {
        width: '80%',
        color: '#A0A0A0',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '25px',
        margin: 'auto',
    },
}));
export default UnAvailable;
