import { GraphQLClient } from 'graphql-request';
import { getAccessTokenFromLocalStorage } from './StorageHelper';

export interface HeadersProps {
    headers: {
        authorization?: string
    }
}
const gqlConfig = (path: string) => {
    const token = getAccessTokenFromLocalStorage();
    let endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4000/api/graphql';
    endpoint += path;
    const headers: HeadersProps = {
        headers: {},
    };
    if (token) {
        headers.headers.authorization = `Bearer ${token}`;
    }
    const graphQLClient = new GraphQLClient(endpoint, headers);
    return graphQLClient;
};

export default gqlConfig;
