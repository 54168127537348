import React, { Suspense } from 'react';
import Loader from '../components/Loader';

const Preload = (Component: any) => function comp(props: any) {
    return (
        <Suspense fallback={<Loader loading />}>
            <Component {...props} />
        </Suspense>
    );
};
export default Preload;
