import React from 'react';
import {
    Box, Button, Grid, TextField, Typography,
    Container, DialogActions, DialogContent,
}
    from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import NewPhaseStyle from './NewPhase.style';

function NewPhaseModal(props: {
        PhaseModel: boolean;
        setPhaseModel: (PhaseModel: boolean) => void;
      }): React.JSX.Element {
    const classes = NewPhaseStyle();
    const {
        PhaseModel, setPhaseModel,
    } = props;
    const handlePhaseOpen = () => {
        setPhaseModel(true);
    };
    const handlePhaseClose = () => {
        setPhaseModel(false);
    };
    return (
        <div>
            <Dialog
                open={PhaseModel}
                onClose={setPhaseModel}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
                PaperProps={{
                    sx: {
                        width: '45%',
                        minHeight: 250,
                    },
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h5" component="h5">
                        New Phase
                    </Typography>

                </DialogTitle>
                <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                    <Container>
                        <Grid className={classes.addPopup} container spacing={4} sx={{ pt: '10px' }}>
                            <Grid xs={12} md={3}>
                                <Typography variant="h6" component="span">Code</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="4" size="small" />
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Typography variant="h6" component="span">Name</Typography>
                                <TextField label="" className={classes.white} fullWidth placeholder="Alternative 3" size="small" />
                            </Grid>
                        </Grid>
                    </Container>

                </DialogContent>
                <DialogActions sx={{ mr: '20px' }}>
                    <Box>
                        <Button autoFocus onClick={handlePhaseClose} variant="outlined">
                            Cancel
                        </Button>
                    </Box>
                    <Box className={classes.undoButtnNew}>
                        <Button onClick={handlePhaseClose} autoFocus variant="contained">
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NewPhaseModal;
