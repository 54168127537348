import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
    useMemo,
} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import '@sencha/best-react-grid/dist/themes/grui.css';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import DonutChart from 'react-donut-chart';
import AgGridTable from '../../../../components/agGridTable';
import CoaBanchStyle from './COABenchmarking.style';
import AddProjectsModal from '../../components/AddProjectsModal';
import RemoveProjectsModal from '../../components/RemoveProjectsModal';
import { Button } from '@mui/material';

const Main = styled('main')({
    flexGrow: 1,
    // height: '80vh',
    overflow: 'auto',
});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    margin: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
}));

function COABenchmarkingView() {
    const classes = CoaBanchStyle();
    const [open, setOpen] = React.useState<boolean>(false);
    const [addpro, setAddPro] = React.useState<boolean>(false);
    const [removepro, setRemovePro] = React.useState<boolean>(false);
    const [diffView, setdiffView] = React.useState('');

    const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
        setdiffView(event.target.value);
    };
    const handleClickAddpro = () => {
        setAddPro(true);
    };
    const handleCloseAddpro = () => {
        setAddPro(false);
    };
    const handleClickremovepro = () => {
        setRemovePro(true);
    };
    const handleCloseremovepro = () => {
        setRemovePro(false);
    };

    const data = [
        {
            label: 'Direct',
            value: 60,
        },
        {
            label: 'Indirect',
            value: 40,
        },
    ];

    const colors = ['#35B83D', '#484DFF'];

    return (
        <div>
            <Box className={classes.tabelFoot}>
                <Main>
                    <Item>
                        {/* Add Project Popup */}
                        <AddProjectsModal
                            open={open}
                            setOpen={setOpen}
                            addpro={addpro}
                            setAddPro={setAddPro}
                        />
                        <RemoveProjectsModal open={open} setOpen={setOpen} addpro={removepro} setAddPro={setRemovePro} />
                        <Box sx={{ height: '100%', width: '100%' }}>
                            {/* Header start here */}
                            <div className={classes.TableHeader}>
                                <Box className={classes.inTableHeader}>
                                    <Typography variant="h6">
                                        COA
                                        <div className={classes.chartStyle}>
                                            <span style={{ color: colors[0] }}>
                                                Direct $238600639
                                            </span>
                                            {' '}
                      &nbsp;&nbsp;&nbsp;
                                            <span style={{ color: colors[1] }}>
                                                Indirect $113,182,367
                                            </span>
                      &nbsp;&nbsp;&nbsp;
                                            <span>Total $351,783,006</span>
&nbsp;&nbsp;&nbsp;
                                            <DonutChart
                                                colors={colors}
                                                data={data}
                                                height={25}
                                                width={25}
                                                legend={false}
                                            />
                                        </div>
                                    </Typography>
                                    <Box className={classes.versonUndo}>
                                        <Box className={classes.undoButtn}>
                                            <Button>
                                                <BarChartOutlinedIcon />
                                                {' '}
                                                Benchmark
                                            </Button>
                                        </Box>
                                        <Box className={classes.undoButtn}>
                                            <Button>
                                                <DataSaverOffOutlinedIcon />
                                                {' '}
                                                Reports
                                            </Button>
                                        </Box>
                                        <FormControl variant="filled">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={diffView}
                                                label="Age"
                                                onChange={handleChange}
                                                className={classes.dView}
                                            >
                                                <MenuItem disabled value="VERSION 2.1">
                                                    Default View
                                                </MenuItem>
                                                <MenuItem value={10}>Total Coast</MenuItem>
                                                <MenuItem value={20}>Total % of Direct Cost</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className={classes.versonUndo}>
                                    <Box className={classes.undoButtn}>
                                        <Button>
                                            <CloudUploadOutlinedIcon />
                                            {' '}
                                            Export
                                        </Button>
                                    </Box>
                                    <Box className={classes.undoButtn}>
                                        <Button className={classes.deleteBtn} onClick={handleClickremovepro}>
                                            <RemoveCircleOutlineOutlinedIcon />
                                            {' '}
                                            Remove Project
                                        </Button>
                                    </Box>
                                    <Box className={classes.undoButtnNew}>
                                        <Button onClick={handleClickAddpro}>
                                            <AddIcon />
                                            {' '}
                                            Add Project
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                            {/* Header End here */}
                            <div>
                                {/* Height Pass as a Props */}
                                <AgGridTable height={530} />
                            </div>
                        </Box>
                    </Item>
                </Main>
            </Box>
        </div>
    );
}

export default COABenchmarkingView;
