import React, { useContext, useState } from 'react';
import {
    Box, FormControl, MenuItem, Typography, DialogActions, DialogContent, DialogTitle,
    Dialog, TextField, Grid, Button, Select, SelectChangeEvent, FormHelperText, Container,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InviteUserPopupStyle from './InviteUserPopup.style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UserContext } from '../../../../store/context/userContext';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import userInvites from '../../../../queries/invite';
import Loader from '../../../../components/Loader';
import NotificationBar from '../../../../components/NotificationBar';
import NotificationBarType from '../../../../types/NotificationBarType';
import InvitationType from '../../../../types/InvitationTypes';
import { emailValidation } from '../../../../helper/validations';
import { useUserRole } from '../../../../hooks/useUserRole';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../../constants';

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void,
}

function InviteUserPopup(props: Props) {
    const classes = InviteUserPopupStyle();
    const {
        open, setOpen,
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const userCtx = useContext(UserContext);
    const [userEmail, setUserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [error, setError] = useState({
        email: false,
        role: false,
        emailFormat: false,
    });
    // BugFix - PC2-I77 // send correct inviter name instead of id.
    // BugFix - PC2-I37 // send email in lower case to BE to store correctly in DB.
    const inviter = userCtx?.user?.name || '';
    const inviteeEmail = userEmail.toLowerCase();
    const orgId = userCtx?.user?.default_org_id || '';
    const roleId = userRole;
    const loggedInUserRole = useUserRole();

    const handleCloseModal = () => {
        setOpen(false);
        setUserEmail('');
        setUserRole('');
    };
    const handleInviteSuccess = (responseData: InvitationType) => {
        if (responseData && responseData.addInvitation && responseData.addInvitation.error_code) {
            const message = responseData.addInvitation.error;
            setNotificationDetails({
                open: true,
                message,
                type: 'error',
            });
        } else {
            setNotificationDetails({
                open: true,
                message: 'User invitation link sent on email.',
                type: 'success',
            });
            handleCloseModal();
        }
    };

    const handleInviteFailure = () => {
        setNotificationDetails({
            open: true,
            message: 'Failed to send invite',
            type: 'error',
        });
    };

    const { mutate, isLoading } = useGQLMutation(
        'SendInvite',
        // eslint-disable-next-line no-useless-escape
        userInvites.SEND_INVITE(inviter, inviteeEmail, orgId, roleId),
        {
            onSuccess: handleInviteSuccess,
            onError: handleInviteFailure,
        },
        '/invitations',
    );

    const handlePopupClose = () => {
        setOpen(false);
        // Clear all states on popup close
        setError({
            email: false,
            role: false,
            emailFormat: false,
        });
        setUserEmail('');
        setUserRole('');
    };

    const sendUserInvite = () => {
        // Validations for role and email
        const isValidEmail = emailValidation(userEmail);
        if (!userEmail || !userRole || !isValidEmail) {
            setError({
                email: !userEmail,
                role: !userRole,
                emailFormat: !isValidEmail,
            });
        } else {
            mutate({});
        }
    };

    let emailErrorMessage = '';
    if (error.email) {
        emailErrorMessage = 'Mandatory field';
    } else if (error.emailFormat) {
        emailErrorMessage = 'Enter valid email format';
    }

    return (
        <Box>
            {/* Invite User Popup */}
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Invite User</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    {isLoading ? <Loader loading={isLoading} /> : null}
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Email
                            </Typography>
                            <TextField
                                error={error.email || error.emailFormat}
                                helperText={emailErrorMessage}
                                label=""
                                color="secondary"
                                fullWidth
                                placeholder="Type email..."
                                value={userEmail}
                                onChange={(e) => {
                                    setError({
                                        ...error,
                                        email: false,
                                        emailFormat: false,
                                    });
                                    setUserEmail(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Role
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} error={error.role}>
                                <Select
                                    id="demo-simple-select"
                                    IconComponent={KeyboardArrowDownIcon}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    fullWidth
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            role: false,
                                        });
                                        setUserRole(e.target.value);
                                    }}
                                    value={userRole}
                                >
                                    { loggedInUserRole === SUPER_ADMIN_ROLE || loggedInUserRole === ADMIN_ROLE ? (
                                        <MenuItem value="2">Admin</MenuItem>
                                    ) : null }
                                    <MenuItem value="3">User</MenuItem>
                                </Select>
                                { error.role ? <FormHelperText>Mandatory field</FormHelperText> : null}
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={handlePopupClose}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button variant="contained" color="primary" onClick={sendUserInvite}>
                            Send
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
        </Box>
    );
}

export default InviteUserPopup;
