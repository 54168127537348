import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    projectListMain: {
        marginTop: '30px',
        '& .ag-details-row': {
            '& .ag-theme-alpine': {
                '&:nth-child(1)': {
                    height: '100% !important',
                },
            },
        },
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
        '& .ag-details-grid': {
            height: '100% !important',
        },
    },
    gridHeight1: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 275px) !important',
            },
        },
    },
    gridHeight2: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 251px) !important',
            },
        },
    },
    opacityBlur: {
        opacity: '50%',
    },
    headerCont: {
        display: 'flex',
    },
    filterDesc: {
        marginBottom: '5px',
        '& span': {
            '&:nth-child(1)': {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
            '&:nth-child(2)': {
                fontSize: '14px',
                fontFamily: 'Inter-Medium',
                color: 'rgb(72, 77, 255)',
            },
        },
    },
    boldText: {
        fontSize: '13px',
        fontFamily: 'Inter-Medium',
        fontWeight: 600,
    },
}));
