/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Auth0ProviderWithHistory from './helper/AuthProvider';
import { LicenseManager } from 'ag-grid-enterprise';
import {} from './activereportCore';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY || '');
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <BrowserRouter>
        <Auth0ProviderWithHistory>
            <QueryClientProvider
                client={queryClient}
            >
                <App />
            </QueryClientProvider>
        </Auth0ProviderWithHistory>
    </BrowserRouter>,
);
