import React, {
    createContext, ReactNode, useState, useMemo,
} from 'react';

interface Props {
  children: ReactNode
}

interface SideNavContextType {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const SideNavContext = createContext<SideNavContextType>({
    open: true,
    setOpen: () => {},
});

function SideNavContextProvider(props: Props) {
    const { children } = props;
    const [open, setOpen] = useState<boolean>(true);

    const defaultValue = useMemo(() => ({
        open,
        setOpen,
    }), [open, setOpen]);

    return (
        <SideNavContext.Provider value={defaultValue}>
            {children}
        </SideNavContext.Provider>
    );
}

export { SideNavContext };

export default SideNavContextProvider;
