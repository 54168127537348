import united from '../assets/united.svg';
import ind from '../assets/india.svg';
import canada from '../assets/flags/canada.svg';
import AUSTRALIA from '../assets/flags/AUSTRALIA.svg';
import AUSTRIA from '../assets/flags/AUSTRIA.svg';
import BAHAMAS from '../assets/flags/BAHAMAS.svg';
import BANGLADESH from '../assets/flags/BANGLADESH.svg';
import BOLIVIA from '../assets/flags/BOLIVIA.svg';
import CHILE from '../assets/flags/CHILE.svg';
import COLOMBIA from '../assets/flags/COLOMBIA.svg';
import DENMARK from '../assets/flags/DENMARK.svg';
import EGYPT from '../assets/flags/EGYPT.svg';
import FINLAND from '../assets/flags/FINLAND.svg';
import FRANCE from '../assets/flags/FRANCE.svg';
import GERMANY from '../assets/flags/GERMANY.svg';
import INDIA from '../assets/flags/INDIA.svg';
import ITALY from '../assets/flags/ITALY.svg';
import JAPAN from '../assets/flags/JAPAN.svg';
import LUXEMBOURG from '../assets/flags/LUXEMBOURG.svg';
import MALTA from '../assets/flags/MALTA.svg';
import MONACO from '../assets/flags/MONACO.svg';
import NETHERLANDS from '../assets/flags/NETHERLANDS.svg';
import NEW_ZEALAND from '../assets/flags/NEW_ZEALAND.svg';
import NORWAY from '../assets/flags/NORWAY.svg';
import PAKISTAN from '../assets/flags/PAKISTAN.svg';
import PHILIPPINES from '../assets/flags/PHILIPPINES.svg';
import POLAND from '../assets/flags/POLAND.svg';
import russia from '../assets/flags/russia.svg';
import SLOVAKIA from '../assets/flags/SLOVAKIA.svg';
import SRI_LANKA from '../assets/flags/SRI_LANKA.svg';
import SWEDEN from '../assets/flags/SWEDEN.svg';
import SWITZERLAND from '../assets/flags/SWITZERLAND.svg';
import TURKEY from '../assets/flags/TURKEY.svg';
import UAE from '../assets/flags/UAE.svg';
import UKRAINE from '../assets/flags/UKRAINE.svg';
import UNITED_KINGDOM from '../assets/flags/UNITED_KINGDOM.svg';
import ARGENTINA from '../assets/flags/ARGENTINA.svg';
import BRAZIL from '../assets/flags/BRAZIL.svg';
import CZECH_REPUBLIC from '../assets/flags/CZECH_REPUBLIC.svg';
import EL_SALVADOR from '../assets/flags/EL_SALVADOR.svg';
import ETHIOPIA from '../assets/flags/ETHIOPIA.svg';
import INDONESIA from '../assets/flags/INDONESIA.svg';
import IRELAND from '../assets/flags/IRELAND.svg';
import JORDAN from '../assets/flags/JORDAN.svg';
import KAZAKHSTAN from '../assets/flags/KAZAKHSTAN.svg';
import MEXICO from '../assets/flags/MEXICO.svg';
import MOROCCO from '../assets/flags/MOROCCO.svg';
import NAMIBIA from '../assets/flags/NAMIBIA.svg';
import PERU from '../assets/flags/Peru.svg';
import SAUDI_ARABIA from '../assets/flags/SAUDI_ARABIA.svg';
import SERBIA from '../assets/flags/SERBIA.svg';
import SOUTH_AFRICA from '../assets/flags/SOUTH_AFRICA.svg';

interface ObjectType {
    [key: string]: string,
}

export const CURR_FORMAT: ObjectType = {
    CAD: 'en-CA',
    USD: 'en-US',
    INR: 'en-IN',
    AUD: 'en-AU',
    RUB: 'ru-RU',
    EUR: 'de-DE', // to be discussed the case of same currency codes for multiple countries
    BSD: 'en-BS',
    BDT: 'bn-BD',
    BOV: 'es-BO',
    CLP: 'es-CL',
    COP: 'es-CO',
    DKK: 'da-DK',
    EGP: 'ar-EG',
    JPY: 'ja-JP',
    LUF: 'lb-LU',
    NZD: 'en-NZ',
    NOK: 'nb-NO',
    PKR: 'ur-PK',
    PHP: 'fil-PH',
    PLN: 'pl-PL',
    SKK: 'sk-SK',
    LKR: 'si-LK',
    SEK: 'sv-SE',
    CHF: 'de-CH',
    TRY: 'tr-TR',
    AED: 'ar-AE',
    UAH: 'uk-UA',
    GBP: 'en-GB',
    ARS: 'es-AR',
    BRL: 'pt-BR',
    CZK: 'cs-CZ',
    ETB: 'am-ET',
    IDR: 'id-ID',
    JOD: 'ar-JO',
    KZT: 'kk-KZ',
    MXN: 'es-MX',
    MAD: 'ar-MA',
    NAD: 'en-NA',
    PEN: 'es-PE',
    SAR: 'ar-SA',
    RSD: 'sr-RS',
    ZAR: 'zu-ZA',
};

export const COUNTRY_FLAG = {
    CAD: canada,
    USD: united || EL_SALVADOR,
    INR: ind,
    AUD: AUSTRALIA,
    RUB: russia,
    EUR: AUSTRIA || FINLAND || FRANCE || GERMANY || ITALY || MALTA || MONACO || NETHERLANDS || IRELAND, // to be discussed the case of same currency codes for multiple countries
    BSD: BAHAMAS,
    BDT: BANGLADESH,
    BOV: BOLIVIA,
    CLP: CHILE,
    COP: COLOMBIA,
    DKK: DENMARK,
    EGP: EGYPT,
    JPY: JAPAN,
    LUF: LUXEMBOURG,
    NZD: NEW_ZEALAND,
    NOK: NORWAY,
    PKR: PAKISTAN,
    PHP: PHILIPPINES,
    PLN: POLAND,
    SKK: SLOVAKIA,
    LKR: SRI_LANKA,
    SEK: SWEDEN,
    CHF: SWITZERLAND,
    TRY: TURKEY,
    AED: UAE,
    UAH: UKRAINE,
    GBP: UNITED_KINGDOM,
    ARS: ARGENTINA,
    BRL: BRAZIL,
    CZK: CZECH_REPUBLIC,
    ETB: ETHIOPIA,
    IDR: INDONESIA,
    JOD: JORDAN,
    KZT: KAZAKHSTAN,
    MXN: MEXICO,
    MAD: MOROCCO,
    NAD: NAMIBIA,
    PEN: PERU,
    SAR: SAUDI_ARABIA,
    RSD: SERBIA,
    ZAR: SOUTH_AFRICA,

};

export const FLAGS_LIST: ObjectType = {
    Canada: canada,
    Russia: russia,
    'United States': united,
    Australia: AUSTRALIA,
    Austria: AUSTRIA,
    Bahamas: BAHAMAS,
    Bangladesh: BANGLADESH,
    Bolivia: BOLIVIA,
    Chile: CHILE,
    Colombia: COLOMBIA,
    Denmark: DENMARK,
    Egypt: EGYPT,
    Finland: FINLAND,
    France: FRANCE,
    Germany: GERMANY,
    India: INDIA,
    Italy: ITALY,
    Japan: JAPAN,
    Luxembourg: LUXEMBOURG,
    Malta: MALTA,
    Monaco: MONACO,
    Netherlands: NETHERLANDS,
    'New Zealand': NEW_ZEALAND,
    Norway: NORWAY,
    Pakistan: PAKISTAN,
    Philippines: PHILIPPINES,
    Poland: POLAND,
    Slovakia: SLOVAKIA,
    'Sri Lanka': SRI_LANKA,
    Sweden: SWEDEN,
    Switzerland: SWITZERLAND,
    Turkey: TURKEY,
    UAE,
    Ukraine: UKRAINE,
    'United Kingdom': UNITED_KINGDOM,
    Argentina: ARGENTINA,
    Brazil: BRAZIL,
    'Czech Republic': CZECH_REPUBLIC,
    'El Salvador': EL_SALVADOR,
    Ethiopia: ETHIOPIA,
    Indonesia: INDONESIA,
    Ireland: IRELAND,
    Jordan: JORDAN,
    Kazakistan: KAZAKHSTAN,
    Mexico: MEXICO,
    Morocco: MOROCCO,
    Namibia: NAMIBIA,
    Peru: PERU,
    'Saudi Arabia': SAUDI_ARABIA,
    Serbia: SERBIA,
    'South Africa': SOUTH_AFRICA,
};
