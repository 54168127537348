/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
    ChangeEvent, useState, useContext, useEffect, useRef,
} from 'react';
import {
    Box, Button, Grid, TextField, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsStyle from './GeneralSettings.style';
import EditModel from '../../components/EditGeneralModal';
import AddCurrencyModel from '../../components/AddCurrencyModal';
import EditCurrencyModel from '../../components/EditCurrencyModal';
import AddCaseModel from '../../components/NewCaseModal';
import AddPhaseModel from '../../components/NewPhaseModal';
import EditLocationModal from '../../components/EditProjectLocationModal';
import EditformatModal from '../../components/FormatModel';
import SaveGeneralModel from '../../components/SaveGeneralModal';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import projectQuery from '../../../../queries/projectInfo';
import projectVersionQuery from '../../../../queries/projectVersion';
import projectQueryLocation from '../../../../queries/projectLocation';
import { DELETE_CURRENCY } from '../../../../queries/projectCurrency';
import projectPhase from '../../../../queries/phase';
import projectCs from '../../../../queries/case';
import projectQueryVersion from '../../../../queries/version';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import NotificationBarType from '../../../../types/NotificationBarType';
import NotificationBar from '../../../../components/NotificationBar';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import ProjectCurrencyType from '../../../../types/ProjectCurrencyType';
import useIsInViewport from '../../../../hooks/useElementViewport';
import { ProjectSettingsTabContext } from '../../../../store/context/projectSettingsTabContext';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { dateFormat } from '../../../../helper/DateFormat';
import Loader from '../../../../components/Loader';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import RestoreVersionModal from '../../components/RestoreVersionModal';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import { Marker, Tooltip } from 'react-leaflet';
import LeafletMap from '../../../../components/mapBox/LeafletMap';
import markerIcon from '../../../../assets/marker.svg';
import { Icon } from 'leaflet';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ImportOptionsModel from '../../../ProjectsListing/Components/ImportOptionsModel';
import { ROLES } from '../../../../constants';
import { useUserRole } from '../../../../hooks/useUserRole';
import { PATH_CMFR } from '../../../../Routes/path';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';

interface CheckBoxType {
    target: {
        value: string,
        checked: boolean,
    }
    code: string,
    id: string,
    isCurrentVersion: boolean,
    name: string,
    date: string,
    updated_by: string,
}
interface VersionData {
    id: string,
    code: string,
    name: string,
}

type Value = string | undefined;
type Code = number | undefined;

function GeneralSetting() {
    const [openim, setOpenImport] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [save, setSave] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<ProjectCurrencyType>();
    const [currency, setCurrency] = useState<boolean>(false);
    const [editCurrency, setEditCurrency] = useState<boolean>(false);
    const [caseModel, setCaseModel] = useState<boolean>(false);
    const [PhaseModel, setPhaseModel] = useState<boolean>(false);
    const [editLocation, setEditLocation] = useState<boolean>(false);
    const [editFormat, setEditFormat] = useState<boolean>(false);
    const [separator, setSeparator] = useState<string>('');
    const [caoSeparator, setCaoSeparator] = useState<string>('');
    const [buttonVisible, setButtonVisible] = useState<boolean>(false);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [openDeleteCurrModel, setOpenDeleteCurrModel] = useState(false);
    const [selectedDeletId, setSelectedDeletId] = useState<VersionData>({
        id: '',
        code: '',
        name: '',
    });
    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
    const [restorePopOpen, setRestorePopOpen] = useState<boolean>(false);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [projectCurrencyId, setProjectCurrencyId] = useState<string>('');
    const cont: OutletContext = useOutletContext();
    const generalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const versionRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const structureRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const locationRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const currentRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const isGeneralInViewport = useIsInViewport(generalRef);
    const isVersionInViewport = useIsInViewport(versionRef);
    const isStructureInViewport = useIsInViewport(structureRef);
    const isCurrencyInViewport = useIsInViewport(currentRef);
    const isLocationInViewport = useIsInViewport(locationRef);

    const projectSettingsContext = useContext(ProjectSettingsTabContext);
    const projCtx = useContext(ProjectContext);
    useEffect(() => {
        projectSettingsContext?.setTabSelected((prevState) => {
            const newState = {
                general: isGeneralInViewport,
                version: isVersionInViewport && !isGeneralInViewport,
                structure: isStructureInViewport && !isVersionInViewport,
                currency: isCurrencyInViewport && !isStructureInViewport,
                location: isLocationInViewport && !isCurrencyInViewport,
            };
            return Object.values(newState).includes(true) ? newState : prevState;
        });
    }, [isGeneralInViewport, isVersionInViewport, isStructureInViewport, isCurrencyInViewport, isLocationInViewport]);

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const customIcon = new Icon({
        iconUrl: markerIcon,
        iconSize: [30, 30],
    });
    const fillerFn = () => {};
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const isViewOnly = Number(projectCtx?.project?.permission_id) === 1;
    const projectCurrncy = projectCtx?.projectCurrencyData;
    const refetchCurrency = projectCtx?.refetchCurrency || fillerFn;
    const refetchProject = projectCtx?.refetchProject || fillerFn;
    const isProjectFetching = projectCtx?.isProjectFetching;
    const loggedInUserRole = useUserRole();
    const navigate = useNavigate();

    const { data: projectPhse, refetch: isPhaseFetching } = useGQLQuery(
        'GetProjectPhase',
        projectPhase.GET_PROJECT_PHASE(
            userCtx?.user?.default_org_id || '',
            projCtx?.project?.id || '',
            projCtx?.project?.version_id || '',
        ),
        {},
        {
            enabled: false,
            onError: handleApiError,
        },
        '/setting',
    );
    const { data: projectCase, refetch: isCaseFetching } = useGQLQuery(
        'GetProjectCase',
        projectCs.GET_PROJECT_CASE(
            userCtx?.user?.default_org_id || '',
            projCtx?.project?.id || '',
            projCtx?.project?.version_id || '',
        ),
        {},
        {
            enabled: false,
            onError: handleApiError,
        },
        '/setting',
    );
    const { data: projectVersion, refetch: isVersionFetching } = useGQLQuery(
        'GetProjectVersion',
        projectQueryVersion.GET_PROJECT_VERSION(userCtx?.user?.default_org_id || '', projectCtx?.project?.id || ''),
        {},
        {
            onError: handleApiError,
        },
        '/setting',
    );

    const handleVersionSuccess = () => {
        refetchProject();
        isVersionFetching();
        setNotificationDetails({
            open: true,
            message: 'Version restored successfully.',
            type: 'success',
        });
    };

    const { mutate: switchVersionMutate, isLoading: switchVerLoading } = useGQLMutation(
        'SwitchVersion',
        projectQueryVersion.SWITCH_VERSION(userCtx?.user?.default_org_id || '', projectCtx?.project?.id || '', selectedDeletId.id),
        {
            onSuccess: handleVersionSuccess,
        },
    );

    const handleDeleteSuccess = () => {
        isVersionFetching();
        setNotificationDetails({
            open: true,
            message: 'Version successfully deleted.',
            type: 'success',
        });
    };

    const { mutate, isLoading } = useGQLMutation(
        'DeleteVersion',
        projectVersionQuery.DELETE_VERSION(selectedDeletId.id, projectCtx?.project?.id || ''),
        {
            onSuccess: handleDeleteSuccess,
        },
    );

    const handleDeleteCurrency = () => {
        refetchProject();
        refetchCurrency();
        setEditItem(undefined);
        setNotificationDetails({
            open: true,
            message: 'Currency successfully deleted.',
            type: 'success',
        });
    };

    const { mutate: deleteProjectCurrency, isLoading: delCurrLoading } = useGQLMutation(
        'DeleteCurrency',
        DELETE_CURRENCY(
            projectCurrencyId,
            projectCtx?.project?.id || '',
            userCtx?.user?.default_org_id || '',
        ),
        {
            onSuccess: handleDeleteCurrency,
        },
        '/setting',
    );

    useEffect(() => {
        if (!isProjectFetching) {
            isPhaseFetching();
            isCaseFetching();
        }
    }, [projectCtx?.project, isProjectFetching]);

    const handleSaveOpen = () => {
        setSave(true);
    };

    const handleDelete = () => {
        setOpenDeleteCurrModel(true);
    };
    const handlecurrOpen = () => {
        setCurrency(true);
    };
    const handleEditOpen = () => {
        setEditCurrency(true);
    };
    const handleRestoreOpen = () => {
        setRestorePopOpen(true);
    };
    const handleClickOpenImport = () => {
        setOpenImport(true);
    };

    const handleCurrencyChange = (event: ChangeEvent<HTMLInputElement>, item: ProjectCurrencyType) => {
        if (event.target.checked) {
            setProjectCurrencyId(item.id);
            setEditItem(item);
            setCheckboxChecked(!checkboxChecked);
        } else {
            setProjectCurrencyId('');
            setEditItem(undefined);
        }
    };
    const classes = SettingsStyle();
    const data = [
        {
            label: 'Direct',
            value: 60,
        },
        {
            label: 'Indirect',
            value: 40,
        },
        {
            label: 'Total',
            value: 20,
        },
    ];

    const renderZeros = (count: number | undefined) => {
        if (count === 1) {
            return '0';
        } if (count === 2) {
            return '00';
        } if (count === 3) {
            return '000';
        }
        return '';
    };

    const onSelectionChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedItemId(event.target.checked ? event.target.value : null);
        setButtonVisible(event.target.checked);
    };
    const onSelectedItem = (item: CheckBoxType) => {
        setSelectedDeletId({
            id: item.id,
            code: item.code,
            name: item.name,
        });
    };

    const handleVerDelete = () => {
        setOpenDeleteModel(true);
    };

    const onDeleteVersionConfirmation = () => {
        mutate({});
        setOpenDeleteModel(false);
        setSelectedDeletId({
            id: '',
            code: '',
            name: '',
        });
        setSelectedItemId(null);
        setButtonVisible(false);
    };

    const onRestoreVersionConfirmation = () => {
        switchVersionMutate({});
        // isPhaseFetching();
        // isCaseFetching();
        setRestorePopOpen(false);
        setSelectedItemId(null);
        setButtonVisible(false);
    };

    const deleteSelectedCurrency = () => {
        deleteProjectCurrency({});
        setOpenDeleteCurrModel(false);
    };

    // To get WBS and COA levels value
    const getLevelValue = (codeArr: Code[]) => {
        let levelCount = 0;
        codeArr.forEach((code) => {
            if (code !== 0) levelCount += 1;
        });
        return `${levelCount} Levels`;
    };

    // modify WBS and COA levels with separator
    const renderExampleData = (separatrValue: Value, code1: Code, code2:Code, code3:Code, code4:Code, code5:Code, code6:Code) => {
        let result = '';
        if (separatrValue === 'Space') {
            // eslint-disable-next-line max-len
            result = `(Example: ${renderZeros(code1)} ${' '}${renderZeros(code2)}${' '}${renderZeros(code3)}${' '}${renderZeros(code4)}${' '}${renderZeros(code5)}${' '}${renderZeros(code6)}
            )`;
        } else {
            // eslint-disable-next-line max-len
            result = `(Example: ${renderZeros(code1)}${renderZeros(code2)}${renderZeros(code3)}${renderZeros(code4)}${renderZeros(code5)}${renderZeros(code6)})`;
        }
        return result;
    };

    const projSettingData = projectCtx?.project;
    // Format the date to required format
    const parsedDate = projSettingData?.updated_at && dateFormat(Number(projSettingData?.updated_at), 'dd/mm/yyyy');
    const baseDate = projSettingData?.base_date && dateFormat(projSettingData?.base_date, 'dd/mm/yyyy');

    // checking logged in user
    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=project settings`);
    };

    const handleTooltipClick = () => {
        const project = projectCtx?.project;
        if (project && project.latitude && project.longitude) {
            const { latitude, longitude } = project;
            const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(url, '_blank');
        }
    };

    return (
        <div>
            {/* Page Header start here */}
            <Loader loading={isProjectFetching || isLoading || switchVerLoading || delCurrLoading} />
            <div className={classes.TableHeader}>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h2">
                        PROJECT SETTINGS
                    </Typography>
                    <CostHeaderBar />
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                </Box>
            </div>
            {/* Page Header End here */}
            {/* Popup Add here */}
            <SaveGeneralModel save={save} setSave={setSave} />
            <EditModel open={open} setOpen={setOpen} save={save} setSave={setSave} />
            <AddCurrencyModel
                currency={currency}
                setCurrency={setCurrency}
                refetchProject={refetchProject}
                setEditItem={setEditItem}
            />
            <EditCurrencyModel
                editCurrency={editCurrency}
                setEditCurrency={setEditCurrency}
                editItem={editItem}
                refetchCurrency={refetchCurrency}
                setEditItem={setEditItem}
                setProjectCurrencyId={setProjectCurrencyId}
            />
            <AddCaseModel caseModel={caseModel} setCaseModel={setCaseModel} />
            <AddPhaseModel PhaseModel={PhaseModel} setPhaseModel={setPhaseModel} />
            <EditLocationModal editLocation={editLocation} setEditLocation={setEditLocation} />
            <EditformatModal editFormat={editFormat} setEditFormat={setEditFormat} />
            {/* Popup Add here */}
            {/* General Section  */}
            <div className={classes.container}>
                {/* General Section  */}
                <Box id="general" ref={generalRef}>
                    <Typography className={classes.heading} variant="h4" component="h4">
                        General Information
                        {/* <Button
                        className={classes.btnAcn}
                        onClick={handleSaveOpen}
                        variant="outlined"
                        sx={{ float: 'right', position: 'relative', top: '-10px' }}
                    >
                        <EditOutlinedIcon style={{ fontSize: 20, marginRight: '5px' }} />
                        {' '}
                        Save
                    </Button> */}
                        {/* <Button
                        className={classes.btnAcn}
                        onClick={handleClickOpen}
                        variant="outlined"
                    >
                        <EditOutlinedIcon style={{ fontSize: 20, marginRight: '5px' }} />
                        {' '}
                        Edit
                    </Button> */}
                    </Typography>
                    <Grid container className={`${classes.boxwrap} ${classes.genInfoWidth}`}>
                        <Grid item>
                            <Typography variant="caption">
                                Project number
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.client_number}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Consultant
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.consultant}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Owner
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.owner}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Client
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Client Project No.
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.client_number}
                            </Typography>
                        </Grid>
                        <Divider />
                        {/* Second Row */}
                        <Grid item>
                            <Typography variant="caption">
                                Estimate Type
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.estimate_type}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Target Accuracy
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.estimate_accuracy}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Base Date
                            </Typography>
                            <Typography variant="h6">
                                {baseDate || ''}
                            </Typography>
                        </Grid>
                        {projCtx?.projectCurrencyData?.getprojectCurrency && projCtx?.projectCurrencyData?.getprojectCurrency.map((item) => {
                            if (item.isBaseCurrency) {
                                return (
                                    <Box key={item?.id}>
                                        <Typography variant="caption">
                                            Currency
                                        </Typography>
                                        <Typography variant="h6">
                                            {item?.code}
                                        </Typography>
                                    </Box>
                                );
                            }
                            return null;
                        })}
                        <Grid item>
                            <Typography variant="caption">
                                Version
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.version_code}
                            </Typography>
                        </Grid>
                        {/* third Row */}
                        <Divider />
                        <Grid item>
                            <Typography variant="caption">
                                Last Modified
                            </Typography>
                            <Typography variant="h6">
                                {parsedDate || ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Industry
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.industry?.description}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Sub Industry
                            </Typography>
                            <Typography variant="h6">
                                {projSettingData?.subindustry?.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {/* First Row */}
                {/* End General Section  */}
                {/* Version Section  */}
                <Box id="version" ref={versionRef}>
                    <Divider className={classes.divider} variant="inset" component="li" />
                    <Typography className={classes.heading} variant="h4">
                        Versions

                        {(buttonVisible && !isViewOnly) ? (
                            <Box sx={{ float: 'right', position: 'relative' }}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    className={classes.btndelete}
                                    onClick={() => handleVerDelete()}
                                >
                                    <DeleteOutlineOutlinedIcon className={classes.btnIcon} style={{ color: '#d32f2f' }} />
                                    {' '}
                                    Delete
                                </Button>
                                <Button
                                    className={classes.btnAcn}
                                    onClick={handleRestoreOpen}
                                    variant="outlined"
                                >
                                    <SettingsBackupRestoreIcon style={{ fontSize: 20, marginRight: '5px' }} />
                                    {' '}
                                    Restore
                                </Button>
                            </Box>
                        ) : (
                            <Box sx={{ float: 'right', position: 'relative' }}>
                                {(!isViewOnly) && (
                                    <Button variant="outlined" className={classes.btnAcn} onClick={handleClickOpenImport}>
                                        <CloudDownloadOutlinedIcon style={{ fontSize: 20, marginRight: '5px' }} />
                                        {' '}
                                        Import Version
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Typography>
                    <Grid container>
                        <Grid item xs={9}>
                            <div className={classes.tableCont}>
                                <table className={classes.tblcurr} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th aria-label="Checkbox" className={classes.checkWidth} />
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectVersion?.version?.map((item: CheckBoxType) => (
                                            <tr key={item.code} className={selectedItemId === item.code ? classes.rowBackground : classes.hoverRow}>
                                                <td aria-label="Checkbox" className={classes.checkBoxStyle}>
                                                    <input
                                                        type="checkbox"
                                                        // eslint-disable-next-line eqeqeq
                                                        disabled={isViewOnly || item.isCurrentVersion === true}
                                                        value={item.code}
                                                        checked={selectedItemId === item.code}
                                                        onChange={onSelectionChanged}
                                                        onClick={() => onSelectedItem(item)}
                                                    />
                                                </td>
                                                <td>{item.code}</td>
                                                <td>{item.name}</td>
                                                <td className={`${item.isCurrentVersion === true ? classes.currenrversionstl : ''}`}>
                                                    {item.isCurrentVersion === true ? 'Current Version' : item.date}
                                                </td>
                                                <td>{item.updated_by}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.tableBottomBorder} />
                        </Grid>
                    </Grid>
                </Box>
                {/* End Version Section  */}
                {/* Code Section   */}
                <Box id="structure" ref={structureRef}>
                    <Divider className={classes.divider} variant="inset" component="li" />
                    {' '}
                    <Typography className={classes.heading} variant="h4">
                        Coding Structure
                    </Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={5}>
                            <Typography className={classes.subheading} variant="h4">
                                Cases
                                {/* <Box sx={{ float: 'right', position: 'relative', top: '-10px' }}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    className={classes.btndelete}
                                >
                                    <DeleteOutlineOutlinedIcon className={classes.btnIcon} style={{ color: '#d32f2f' }} />
                                    {' '}
                                    Delete
                                </Button>
                                <Button
                                    variant="outlined"
                                    className={classes.btnSpace}
                                    onClick={handleEditOpen}
                                    sx={{ color: '#787878' }}
                                >
                                    <EditOutlinedIcon className={classes.btnIcon} />
                                    {' '}
                                    Edit
                                </Button>
                                <Button
                                    className={classes.undoButtnNew}
                                    variant="outlined"
                                    onClick={handleCaseOpen}
                                >
                                    <AddIcon style={{ fontSize: 20, marginRight: '5px' }} />
                                    {' '}
                                    Add
                                </Button>
                            </Box> */}
                            </Typography>
                            <div className={classes.tableCont}>
                                <table className={classes.tblcurr} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th aria-label="Checkbox" className={classes.checkWidth} />
                                            <th>Case</th>
                                            <th>Name</th>
                                            <th>Base</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectCase?.case?.map((item) => (
                                            <tr key={item.code} className={selectedItemId === item.code ? classes.rowBackground : classes.hoverRow}>
                                                <td aria-label="Checkbox" className={classes.checkBoxStyle}>
                                                    <input type="checkbox" disabled />
                                                </td>
                                                <td>{item?.code}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.base ? 'True' : 'False'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.tableBottomBorder} />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={classes.subheading} variant="h4" component="h4">
                                Phase
                                {/* <Button
                                className={classes.undoButtnNew}
                                variant="outlined"
                                onClick={handlePhaseOpen}
                                sx={{ float: 'right', position: 'relative', top: '-10px' }}
                            >
                                <AddIcon style={{ fontSize: 20, marginRight: '5px' }} />
                                {' '}
                                Add
                            </Button> */}
                            </Typography>
                            <div className={classes.tableCont}>
                                <table className={classes.tblcurr} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th aria-label="Checkbox" className={classes.checkWidth} />
                                            <th>Phase</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectPhse?.phase?.map((item) => (
                                            <tr key={item.code} className={selectedItemId === item.code ? classes.rowBackground : classes.hoverRow}>
                                                <td aria-label="Checkbox" className={classes.checkBoxStyle}>
                                                    <input type="checkbox" disabled />
                                                </td>
                                                <td>{item?.code}</td>
                                                <td>{item?.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.tableBottomBorder} />
                        </Grid>
                    </Grid>
                </Box>
                {/* End Code Section   */}
                {/* Format Section   */}
                <Divider className={classes.divider} variant="inset" component="li" />
                <Typography className={classes.subheading} variant="h4" component="h4" sx={{ width: '72%' }}>
                    Formats
                    {/* <Button
                        className={classes.btnAcn}
                        variant="outlined"
                        onClick={handleFormatOpen}
                        sx={{ top: '0px' }}
                    >
                        <EditOutlinedIcon style={{ fontSize: 20, marginRight: '5px' }} />
                        {' '}
                        Edit
                    </Button> */}
                </Typography>
                <Box component="div" className={classes.boxWrapformat}>
                    <Box component="div" className={classes.bggray}>
                        <Grid container spacing={2}>
                            {/* First Row */}
                            <Grid item justifyContent="center" xs={2}>
                                <Typography className={classes.forheading} variant="h6" component="h6" sx={{ marginTop: '38px !important' }}>
                                    WBS Format
                                </Typography>
                            </Grid>
                            <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}>
                                <TextField
                                    sx={{ marginTop: '35px !important' }}
                                    className={classes.InputHeight}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder={getLevelValue([
                                        projSettingData?.wbs1,
                                        projSettingData?.wbs2,
                                        projSettingData?.wbs3,
                                        projSettingData?.wbs4,
                                        projSettingData?.wbs5,
                                        projSettingData?.wbs6,
                                    ])}
                                    fullWidth
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" component="h6" className={classes.digitext}>Digits per level</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder="2"
                                            size="small"
                                            disabled
                                            value={projSettingData?.wbs1}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder="1"
                                            size="small"
                                            disabled
                                            value={projSettingData?.wbs2}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder="1"
                                            size="small"
                                            disabled
                                            value={projSettingData?.wbs3}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder=""
                                            size="small"
                                            disabled
                                            value={projSettingData?.wbs4}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder=""
                                            size="small"
                                            disabled
                                            value={projSettingData?.wbs5}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder=""
                                            size="small"
                                            disabled
                                            value={projSettingData?.wbs6}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sx={{ ml: '20px' }}>
                                <Typography variant="h6" component="h6" className={classes.digitext}>Separator</Typography>
                                {/* <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}> */}
                                <TextField
                                    className={classes.InputHeight}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="None"
                                    value={projSettingData?.wbs_sep}
                                    fullWidth
                                    size="small"
                                    disabled
                                />
                                {/* </Grid> */}
                                {/* <div className={classes.versonUndo}>
                                    <FormControl variant="filled">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            label=""
                                            sx={{ height: '41px' }}
                                            fullWidth
                                            onChange={handleWbsSepertarChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        '& .MuiMenuItem-root': {
                                                            color: '#787878',
                                                        },
                                                        '& .Mui-selected': {
                                                            color: '#021948',
                                                            bgcolor: '#E6ECFA !important',
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="space">
                                                <em>Space</em>
                                            </MenuItem>
                                            <MenuItem value={projSettingData?.wbs_sep}>
                                                {projSettingData?.wbs_sep}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div> */}
                                {/* <FormControl sx={{ m: 0, minWidth: 165 }} size="small">
                                    <InputLabel id="demo-select-small-label">None</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label=""
                                        sx={{ height: '41px' }}
                                        fullWidth
                                        onChange={handleWbsSepertarChange}
                                        // disabled
                                    >
                                        <MenuItem value="space">
                                            <em>Space</em>
                                        </MenuItem>
                                        <MenuItem value={projSettingData?.wbs_sep}>
                                            {projSettingData?.wbs_sep}
                                        </MenuItem>
                                    </Select>
                                </FormControl> */}
                                <Typography variant="body2" component="span" className={classes.exptext}>
                                    {renderExampleData(
                                        projSettingData?.wbs_sep,
                                        projSettingData?.wbs1,
                                        projSettingData?.wbs2,
                                        projSettingData?.wbs3,
                                        projSettingData?.wbs4,
                                        projSettingData?.wbs5,
                                        projSettingData?.wbs6,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box component="div" className={classes.bgwhite}>
                        <Grid container spacing={2}>
                            {/* Second Row */}
                            <Grid item justifyContent="center" xs={2}>
                                <Typography className={classes.forheading} variant="h6" component="h6" sx={{ marginTop: '38px !important' }}>
                                    COA Format
                                </Typography>
                            </Grid>
                            <Grid item justifyContent="center" xs={2} sx={{ mr: '20px' }}>
                                <TextField
                                    sx={{ marginTop: '35px !important' }}
                                    className={classes.InputHeight}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder={getLevelValue([
                                        projSettingData?.coa1,
                                        projSettingData?.coa2,
                                        projSettingData?.coa3,
                                        projSettingData?.coa4,
                                        projSettingData?.coa5,
                                        projSettingData?.coa6,
                                    ])}
                                    fullWidth
                                    size="small"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" component="h6" className={classes.digitext}>Digits per level</Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder="2"
                                            size="small"
                                            value={projSettingData?.coa1}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder="1"
                                            size="small"
                                            value={projSettingData?.coa2}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder="1"
                                            size="small"
                                            disabled
                                            value={projSettingData?.coa3}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder=""
                                            size="small"
                                            disabled
                                            value={projSettingData?.coa4}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder=""
                                            size="small"
                                            disabled
                                            value={projSettingData?.coa5}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label=""
                                            className={classes.InputHeight}
                                            placeholder=""
                                            size="small"
                                            disabled
                                            value={projSettingData?.coa6}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sx={{ ml: '20px' }}>
                                <Typography variant="h6" component="h6" className={classes.digitext}>Separator</Typography>
                                <TextField
                                    className={classes.InputHeight}
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="None"
                                    value={projSettingData?.coa_sep}
                                    fullWidth
                                    size="small"
                                    disabled
                                />
                                {/* <div className={classes.versonUndo}>
                                    <FormControl variant="filled">
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            label=""
                                            sx={{ height: '41px' }}
                                            fullWidth
                                            value={projSettingData?.coa_sep}
                                            onChange={handleCoaSeparator}
                                            IconComponent={KeyboardArrowDownIcon}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        '& .MuiMenuItem-root': {
                                                            color: '#787878',
                                                        },
                                                        '& .Mui-selected': {
                                                            color: '#021948',
                                                            bgcolor: '#E6ECFA !important',
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="none">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="space">Space</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div> */}
                                {/* <FormControl sx={{ m: 0, minWidth: 165 }} size="small">
                                    <InputLabel id="demo-select-small-label">Space</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        label=""
                                        sx={{ height: '41px' }}
                                        value={projSettingData?.coa_sep}
                                        fullWidth
                                        onChange={handleCoaSeparator}
                                    >
                                        <MenuItem value="none">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="space">Space</MenuItem>
                                    </Select>
                                </FormControl> */}
                                <Typography variant="body2" component="span" className={classes.exptext}>
                                    {renderExampleData(
                                        projSettingData?.coa_sep,
                                        projSettingData?.coa1,
                                        projSettingData?.coa2,
                                        projSettingData?.coa3,
                                        projSettingData?.coa4,
                                        projSettingData?.coa5,
                                        projSettingData?.coa6,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {/* End Format Section   */}
                {/* Currencies Section   */}
                <Box id="currency" ref={currentRef}>
                    <Divider className={classes.divider} variant="inset" component="li" />
                    <Typography className={classes.heading} variant="h4">
                        Currencies
                        {!isViewOnly && (
                            <Box sx={{ float: 'right', position: 'relative' }}>
                                {(editItem && !editItem.isBaseCurrency)
                                    && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                className={classes.btndelete}
                                                onClick={handleDelete}
                                                disabled={!editItem || editItem.isBaseCurrency}
                                            >
                                                <DeleteOutlineOutlinedIcon className={classes.btnIcon} style={{ color: '#B00000' }} />
                                                {' '}
                                                Delete
                                            </Button>
                                            {/* <Button
                                                variant="outlined"
                                                className={classes.btnSpace}
                                                onClick={handleEditOpen}
                                                sx={{ color: '#787878' }}
                                                disabled={!editItem || editItem.isBaseCurrency}
                                            >
                                                <EditOutlinedIcon className={classes.btnIcon} />
                                                {' '}
                                                Edit
                                            </Button> */}
                                        </>
                                    )}
                                <Button
                                    className={classes.undoButtnNew}
                                    onClick={handlecurrOpen}
                                    variant="outlined"
                                >
                                    <AddIcon style={{ fontSize: 20, marginRight: '5px' }} />
                                    {' '}
                                    New
                                </Button>
                            </Box>
                        )}
                    </Typography>
                    <Grid container>
                        <Grid item xs={8}>
                            <div className={classes.tableCont}>
                                <table className={classes.tblcurr} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th aria-label="Checkbox" className={classes.checkWidth} />
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Rate</th>
                                            <th>Base Currency</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectCurrncy?.getprojectCurrency && projectCurrncy.getprojectCurrency.map((item) => (
                                            <tr
                                                key={item?.name}
                                                className={editItem?.id === item.id
                                                    ? classes.rowBackground : classes.hoverRow}
                                            >
                                                <td aria-label="Checkbox" className={classes.checkBoxStyle}>
                                                    <input
                                                        type="checkbox"
                                                        // eslint-disable-next-line eqeqeq
                                                        disabled={isViewOnly || item.isBaseCurrency === true}
                                                        checked={editItem?.id === item.id}
                                                        // checked={checkboxChecked}
                                                        onChange={(event) => handleCurrencyChange(event, item)}
                                                    />
                                                </td>
                                                <td>{item?.code}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.exchange_rate}</td>
                                                <td>{item?.isBaseCurrency ? 'True' : 'False'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.tableBottomBorder} />
                        </Grid>
                    </Grid>
                </Box>
                {/* End Currencies Section   */}
                {/*  Location Section   */}
                <Box id="location" className={classes.spaceBt} ref={locationRef}>
                    <Divider className={classes.divider} variant="inset" component="li" />
                    <Typography className={classes.heading} variant="h4" component="h4">
                        Location
                        {/* <Button
                        className={classes.btnAcn}
                        variant="outlined"
                        onClick={handleLocatioOpen}
                        sx={{ top: '0px' }}
                    >
                        <EditOutlinedIcon style={{ fontSize: 20, marginRight: '5px' }} />
                        {' '}
                        Edit
                    </Button> */}
                    </Typography>
                    <Grid container className={`${classes.boxwrap} ${classes.locationWidth}`}>
                        <Grid item>
                            <Typography component="span" variant="body2">
                                Country
                            </Typography>
                            <Typography component="h6" variant="h6">{projCtx?.project?.country_name}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="span" variant="body2">
                                Region
                            </Typography>
                            <Typography component="h6" variant="h6">{projCtx?.project?.region}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="span" variant="body2">
                                Location
                            </Typography>
                            <Typography component="h6" variant="h6">{projCtx?.project?.location}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="span" variant="body2">
                                Latitude
                            </Typography>
                            <Typography component="h6" variant="h6">{projCtx?.project?.latitude}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="span" variant="body2">
                                Longitude
                            </Typography>
                            <Typography component="h6" variant="h6">{projCtx?.project?.longitude}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                {/*  End Location Section   */}
                {/*  Map Section   */}
                {/* <Typography className={classes.heading} variant="h4" component="h4">MAP VIEW</Typography> */}
                <Box className={classes.mapContainer}>
                    {(projectCtx?.project?.latitude && projectCtx?.project?.longitude
                        && Number(projectCtx?.project?.latitude) && Number(projectCtx?.project?.longitude)
                    ) ? (
                            <LeafletMap
                                zoomLevel={2}
                            >
                                <Marker
                                    position={[Number(projectCtx?.project?.latitude), Number(projectCtx?.project?.longitude)]}
                                    icon={customIcon}
                                    eventHandlers={{
                                        click: handleTooltipClick,
                                    }}
                                >
                                    <Tooltip
                                        offset={[0, -20]}
                                        direction="top"
                                        className={classes.tooltipContainer}
                                    >
                                        <div>
                                            <Typography variant="h6">
                                                {`${projectCtx?.project?.location}, ${projectCtx?.project?.region} city,`}
                                            </Typography>
                                            <Typography variant="h6">{projectCtx?.project?.country_name}</Typography>
                                        </div>
                                    </Tooltip>
                                </Marker>
                            </LeafletMap>
                        ) : (
                            <Box className={classes.currencySec}>No Map Data to Show</Box>
                        )}
                </Box>
                {/*  End Map Section   */}
            </div>
            {/* End Page Content */}
            <NotificationBar
                open={notificationDetails.open}
                setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                type={notificationDetails.type}
                message={notificationDetails.message}
            />
            {openDeleteModel && (
                <DeleteRowModel
                    open={openDeleteModel}
                    setOpenDelRow={setOpenDeleteModel}
                    deleteAction={onDeleteVersionConfirmation}
                    orgName={selectedDeletId.code}
                    title="Version"
                />
            )}
            {restorePopOpen && (
                <RestoreVersionModal
                    open={restorePopOpen}
                    setOpenVersion={setRestorePopOpen}
                    switchAction={() => onRestoreVersionConfirmation()}
                    code={selectedDeletId.code}
                    name={selectedDeletId.name}
                    prevCode={projectVersion?.version?.find((i) => i.isCurrentVersion)?.code || ''}
                />
            )}
            {openDeleteCurrModel && (
                <DeleteRowModel
                    open={openDeleteCurrModel}
                    setOpenDelRow={setOpenDeleteCurrModel}
                    deleteAction={deleteSelectedCurrency}
                    orgName={editItem?.code}
                    title="Currency"
                />
            )}
            {openim && (
                <ImportOptionsModel
                    projectId={projCtx?.project?.id || ''}
                    open={openim}
                    setOpenImport={setOpenImport}
                    refetchProjInfo={refetchProject}
                    refetchVersion={isVersionFetching}
                    refetchPhase={isPhaseFetching}
                    refetchCase={isCaseFetching}
                    isNewProjectVersion
                />
            )}
        </div>
    );
}
export default GeneralSetting;
