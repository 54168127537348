import { makeStyles } from '@mui/styles';

const NavbarStyle = makeStyles(() => ({
    headerSectionLeft: {
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            marginLeft: '45px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '8px 32px 8px 12px !important',
            fontSize: '13px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#323232',
        },
    },
    selectBoxFlag: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '170px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '8px 32px 8px 12px !important',
            fontSize: '13px',
            color: 'rgba(0, 0, 0, 0.57)',
        },
        '& img': {
            verticalAlign: 'middle',
            width: '22%',
            marginRight: '10px',
        },
        // '& svg': {
        //     color: '#323232',
        //     width: '22px !important',
        // },
    },
    undoButtn: {
        marginLeft: '12px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '6px 11px',
            cursor: 'pointer',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
    },
    notificationStl: {
        margin: '0px 34px 0px 24px',
        backgroundColor: '#F2F2F2',
        borderRadius: '50px',
        width: '37px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            color: '#787878',
            fontSize: '21px',
            verticalAlign: 'bottom',
        },
    },
    selectBoxFlagData: {
        backgroundColor: 'transparent !important',
        '& img': {
            marginRight: '10px',
        },
    },
}));

export default NavbarStyle;
