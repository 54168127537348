import React, {
    useState, ChangeEvent, FormEvent, useEffect,
} from 'react';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box,
} from '@mui/material';
import logo from '../../../../assets/logo.svg';
import { useStyles } from './ForgetPassword.style';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmail from 'validator/lib/isEmail';
import toast from 'react-hot-toast';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import userQueries from '../../../../queries/users';
import Loader from '../../../../components/Loader';
import { emailValidation } from '../../../../helper/validations';
import NotificationBar from '../../../../components/NotificationBar';
import { QueryErrorResponse } from '../../../../types/OrganisationDetailType';

function ForgetPassword() {
    const classes = useStyles();
    const navigate = useCustomNavigate();
    const [error, setError] = useState('');
    const [dirty, setDirty] = useState(false);
    const [email, setEmail] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [emailValidationError, setEmailValidationError] = useState({
        error: false,
        message: '',
    });
    const [notification, setNotification] = useState({
        open: false,
        message: '',
    });

    const handlPasswordResetFailure = ({ response } : QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        setNotification({
            open: true,
            message,
        });
    };

    const { data, isFetching, refetch } = useGQLQuery(
        'GetUserByEmail',
        userQueries.GET_USER_BY_EMAIL,
        { emailId: email },
        {
            enabled: false,
            refetchOnWindowFocus: false,
            onError: handlPasswordResetFailure,
        },
        '/checkinvitation',
    );

    const sendResetLinkToEmail = (emailId: string) => {
        const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
        fetch(`https://${domain}/dbconnections/change_password`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                email: emailId,
                connection: process.env.REACT_APP_AUTH0_CONNECTION,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.text();
                }
                return response.json();
            })
            .then((res) => {
                const message = typeof res === 'string' ? res : '';
                toast.success(message);
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            })
            .catch((err: { message: string; }) => {
                const errMsg:string = err?.message || 'Something went wrong!';
                setError(errMsg);
            });
    };

    useEffect(() => {
        // Bug fix PC2-I84 apply validation only after fetching is completed
        if (!isFetching) {
            if (data && data.getUserByEmail && data.getUserByEmail.email) {
                sendResetLinkToEmail(data.getUserByEmail.email);
            } else if (data && data.getUserByEmail && !data.getUserByEmail.email) {
                setError('User doesn\'t exists');
            }
        }
    }, [data, isFetching]);
    // Bug Fix - PC2-I19 added isFeteching as a dependency so the useEffect will rerun

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (error !== '') return;
        // Email validation
        const isValidEmail = emailValidation(email);
        if (isValidEmail) {
            refetch();
        } else {
            setEmailValidationError({
                error: true,
                message: 'Invalid email format',
            });
        }
    };

    const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target) {
            setEmail(target.value);
            if (target.value === '') {
                setIsFormValid(false);
            } else if (!isEmail(target.value)) {
                setIsFormValid(false);
                setError('');
            } else {
                setIsFormValid(true);
                setError('');
            }
            setEmailValidationError({
                error: false,
                message: '',
            });
        }
    };

    return (
        <>
            <Box component="div" />
            <Loader loading={isFetching} />
            <Grid item xs={12} sm={12} className={classes.headerBackground}>
                <Grid container spacing={0} sx={{ margin: 'auto', padding: '0px 150px' }}>
                    <Box><img src={logo} alt="logo" style={{ margin: '10px 0px' }} /></Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={0}
                direction="row"
                sx={{
                    minHeight: '90vh', margin: 'auto', padding: '0px 150px', alignItems: 'center', justifyContent: 'center',
                }}
                className={classes.wrapper}
            >
                <Grid item xs={12} sm={8}>
                    <Typography variant="h2" component="h2" className={classes.pageTitle} />
                    <Typography variant="body2" className={classes.pageDescription} />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.loginContent}>
                    <div className={classes.loginBox}>
                        <form onSubmit={handleSubmit}>
                            <p>
                                Enter your e-mail address to receive reset password link
                            </p>
                            <TextField
                                fullWidth
                                margin="dense"
                                placeholder="Email"
                                variant="outlined"
                                value={email}
                                onBlur={() => setDirty(true)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleUsernameChange}
                                error={emailValidationError.error}
                                helperText={emailValidationError.message}
                            />
                            { dirty ? <p className={classes.errorMsg}>{error}</p> : null }
                            <Button
                                className={classes.loginBtn}
                                style={{ textTransform: 'capitalize' }}
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={emailValidationError.error || !email}
                            >
                                Submit
                            </Button>
                            <NotificationBar
                                open={notification.open}
                                setOpen={() => setNotification({ ...notification, open: true })}
                                type="error"
                                message={notification.message}
                            />
                        </form>
                    </div>
                    <div className={classes.copyrightText}>
                        <p>
                            Copyright © 2024
                            {' '}
                            <a className={classes.atag} href="https://pcucorp.com/" target="_blank" rel="noreferrer">PCU Corporación SAC / PCU Corporation</a>
                        </p>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
export default ForgetPassword;
