import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_COMMODITIES_BY_ORGID = (
    skip: number,
    take: number,
    sort: string,
    sortOrder: string,
) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetCommoditiesDataByOrgId {
        commodities(skip: ${skip}, take: ${take}, ${sortType}) {
          pageInfo {
          skip
          take
          hasNextPage
          totalcount
        }
        data {
            id
            code
            description
            unit
            total_cost
            level
            attr_label
            commodities_count
        }
    }
    }`;
};

const GET_COMMODITIES_WITH_PROJECTS_BY_ORGID = (
    org_id: string,
    skip: number,
    take: number,
    searchText: string,
    hideUnusedFlag?: boolean,
    code1?: string,
) => {
    const searchTerm = searchText ? `searchTerm: ${addEscapeCharacters(searchText)}` : '';
    return gql`
    query GetCommoditiesWithProjectsDataByOrgId {
        commodities_detail(org_id: "${org_id}", ${code1 ? `code1: "${code1}"` : ''},
         skip: ${skip}, take: ${take}, ${searchTerm}, hide_unused_flag: ${hideUnusedFlag} ) {
        data {
            id
            code
            code1
            code2
            code3
            code4
            code5
            code6
            description
            unit
            total_cost
            level
            attr_label
            commodities_count
            first_parent
            second_parent
            third_parent
            forth_parent
            fifth_parent
            self_code_desc
            projects{
                id
                org_id
                generic_coa_id
                project_number
                client_number
                name
                estimate_type
                country_name
                region
                base_date
                owner
                total_cost
            }
        }
    }
    }`;
};

const GET_COMMODITIES_LIST_BY_ORGID = (
    org_id: string,
) => gql`
    query GetCommoditiesWithByOrgId {
        commodities_ddo(org_id: "${org_id}" ) {
        data {
            id
            code
            code1
            level
            self_code_desc
        }
    }
    }`;

const GET_GCOA_BY_COMMODITY_ID = (org_id: string, commodity_id: string) => gql`
                query GetCommoditiesProject {
                    getCommoditiesProject(org_id: "${org_id}", commodity_id: "${commodity_id}"){
                    data {
                    id
                    org_id
                    project_number
                    client_number
                    name
                    estimate_type
                    country_name
                    region
                    base_date
                    owner
                    total_cost
                    quantity
                    unit
                    unit_cost
                    }
                }
            }`;

const DELETE_COMMODITIES = (orgId: string, id: string) => gql`
mutation {
  deleteCommodities(input: { org_id: ${addEscapeCharacters(orgId)}, ids: ${id} }) {
    error
    error_code
    message
  }
}
`;

export default {
    GET_COMMODITIES_BY_ORGID,
    GET_COMMODITIES_LIST_BY_ORGID,
    GET_COMMODITIES_WITH_PROJECTS_BY_ORGID,
    GET_GCOA_BY_COMMODITY_ID,
    DELETE_COMMODITIES,
};
