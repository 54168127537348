import React from 'react';
import AppDrawerStyle from './AppDrawer.style';
import { Box, Typography } from '@mui/material';

type Props = {
    title: string | undefined,
};

export function AppDrawerMenuItem(props : Props) {
    const { title } = props;
    const classes = AppDrawerStyle();
    return (
        <Box className={classes.projectList}>
            <Typography variant="body1" align="center" style={{ paddingTop: '5px' }}>
                {title}
            </Typography>
        </Box>
    );
}
