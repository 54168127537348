import React, {
    createContext, ReactNode, useState, useMemo, useContext, useEffect,
} from 'react';
import { OrganisationType } from '../../types';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import costQueries from '../../queries/orgCost';
import TOTAL_PROJRCTS_COUNT from '../../queries/totalProjects';
import { UserContext } from './userContext';
import OrgCostType from '../../types/OrgCostType';
import {
    JSONParse, getDecodedItemDataFromLocalStorage, setEncodedItemDataToLocalStorage,
} from '../../helper/StorageHelper';

interface Props {
  children: ReactNode
}

interface ProjCount {
    project_count: number;
    project_country_count: number;
}

interface OrganisationContextType {
    organisations: OrganisationType | null;
    setOrganisationsDetail: (organisations: OrganisationType) => void;
    totalProjects: {
        project_count: number,
        project_country_count: number,
    } | undefined;
    refetchOrgCost: () => void;
    totalOrgCost: OrgCostType | undefined;
    refetchProjCount: () => void;
}

const OrganisationContext = createContext<OrganisationContextType | null>(null);

function OrganisationContextProvider(props: Props) {
    const { children } = props;
    const userCtx = useContext(UserContext);

    const totalOrgCostLS = getDecodedItemDataFromLocalStorage('totalOrgCost') || undefined;
    const totalProjectsLS = getDecodedItemDataFromLocalStorage('totalProjects') || undefined;
    const [organisations, setOrganisationsDetail] = useState<OrganisationType | null>(null);
    const [totalProjects, setTotalProjects] = useState<ProjCount | undefined>(JSONParse(totalProjectsLS));
    const [totalOrgCost, setTotalOrgCost] = useState<OrgCostType | undefined>(JSONParse(totalOrgCostLS));

    const { data, refetch: refetchOrgCost, isFetching } = useGQLQuery(
        `GetOrgCost-${userCtx?.user?.default_org_id || ''}`,
        costQueries.GET_ORG_COST(userCtx?.user?.default_org_id || ''),
        {},
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (data && !isFetching) {
            const orgCost = data?.orgCosts;
            setEncodedItemDataToLocalStorage('totalOrgCost', JSON.stringify(orgCost));
            setTotalOrgCost(orgCost);
        }
    }, [isFetching]);

    const { data: projectsData, refetch: refetchProjCount, isFetching: fetchingProjects } = useGQLQuery(
        `projectTotalCount-${userCtx?.user?.default_org_id || ''}`,
        TOTAL_PROJRCTS_COUNT(
            userCtx?.user?.default_org_id || '',
        ),
        {},
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (projectsData && !fetchingProjects) {
            const count = projectsData?.projectTotalCount;
            setEncodedItemDataToLocalStorage('totalProjects', JSON.stringify(count));
            setTotalProjects(count);
        }
    }, [fetchingProjects]);

    useEffect(() => {
        if (userCtx && userCtx?.user?.default_org_id) {
            refetchOrgCost();
            refetchProjCount();
        }
    }, [userCtx]);

    const defaultValue = useMemo(() => ({
        organisations,
        setOrganisationsDetail,
        totalProjects,
        refetchOrgCost,
        totalOrgCost,
        refetchProjCount,
    }), [organisations, setOrganisationsDetail, totalProjects, totalOrgCost]);

    return (
        <OrganisationContext.Provider value={defaultValue}>
            {children}
        </OrganisationContext.Provider>
    );
}

export { OrganisationContext };

export default OrganisationContextProvider;
