// Coa page styles
import { makeStyles } from '@mui/styles';

const COADifferent = makeStyles({
    heirarchystyl: {
        fontSize: '15.33px',
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
    headingText: {
        fontSize: '13px',
        fontFamily: 'Inter-Bold',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    boldText: {
        fontSize: '13px',
        fontFamily: 'Inter-Medium',
        fontWeight: 600,
    },
    normalText: {
        fontSize: '13px',
        fontFamily: 'Inter',
        fontWeight: 400,
    },
});
export default COADifferent;
