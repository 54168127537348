import React, { useContext, useState } from 'react';
import useStyles from './IndustriesHeader.style';
import {
    Box, Button, Typography, FormControl, MenuItem, Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_DATABASE } from '../../../../Routes/path';
import OrgCostHeaderBar from '../../../../components/OrgCostHeaderBar';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import { ROLES } from '../../../../constants';
import { useUserOrganization, useUserRole } from '../../../../hooks/useUserRole';
import HideUnusedButton from '../../../../components/HideUnusedButton';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import exportExcel from '../../../../helper/ExportExcel';
import { UserContext } from '../../../../store/context/userContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';

interface Props {
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setOpenImport: React.Dispatch<React.SetStateAction<boolean>>;
    hideUnusedFlag: boolean;
    setHideUnusedFlag: React.Dispatch<React.SetStateAction<boolean>>;
    selectedIndustries: string[];
    handleDelete: () => void;
    isIndustriesLoading: boolean;
}

function IndustriesHeader(props: Props) {
    const {
        searchText, setSearchText, setOpenImport, hideUnusedFlag, setHideUnusedFlag, selectedIndustries, handleDelete, isIndustriesLoading,
    } = props;

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState('list');
    const navigate = useNavigate();
    const loggedInUserRole = useUserRole();
    const userOrg = useUserOrganization();
    const userCtx = useContext(UserContext);
    const cont: OutletContext = useOutletContext();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_DATABASE.reports}?module=industries`);
    };
    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };
    const handleClear = () => {
        setSearchText('');
    };

    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    const handleClickOpenImport = () => {
        setOpenImport(true);
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'industries',
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };
    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Tooltip title={userOrg && userOrg?.gwbs_label}>
                    <Typography variant="h2" noWrap>
                        {userOrg?.gwbs_label}
                    </Typography>
                </Tooltip>
                <OrgCostHeaderBar />
                <Box className={classes.rightSection}>
                    {/* <Box className={classes.outlineButton}>
                        <Button>
                            <BarChartIcon />
                            {' '}
                            Benchmark
                        </Button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    <QuickSearchFilter searchText={searchText} setSearchText={setSearchText} />
                    {!searchText
                    && (
                        <HideUnusedButton
                            hideUnusedFlagVal={hideUnusedFlag}
                            setHideUnusedFlag={setHideUnusedFlag}
                            loadingData={isIndustriesLoading}
                        />
                    )}
                    {/* <FormControl variant="filled">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={view}
                            label="view"
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        '& .MuiMenuItem-root': {
                                            color: '#787878',
                                        },
                                        '& .Mui-selected': {
                                            color: '#021948',
                                            bgcolor: '#E6ECFA !important',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value="detail">Detail View</MenuItem>
                            <MenuItem value="list">List View</MenuItem>
                        </Select>
                    </FormControl> */}
                </Box>
            </Box>
            <Box className={classes.rightSection}>
                {(userRole?.role === 'Admin' || userRole?.role === 'Super Admin') && !selectedIndustries.length && (
                    <Box className={classes.outlineButton}>
                        <Button onClick={handleClickOpenImport}>
                            <CloudDownloadOutlinedIcon />
                            Import
                        </Button>
                    </Box>
                )}
                <Box className={classes.outlineButton}>
                    <Button onClick={handleExportExcel}>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box>
                <Box className={classes.deleteButton}>
                    {(userRole?.role === 'Admin' || userRole?.role === 'Super Admin') && !!selectedIndustries.length && (
                        <Button
                            variant="outlined"
                            onClick={() => handleDelete()}
                        >
                            <DeleteOutlineOutlined />
                            {' '}
                            Delete
                        </Button>
                    )}
                </Box>
                {/* <Box className={classes.outlineButton}>
                    <Button>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </Button>
                </Box> */}
                {/* <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </Button>
                </Box> */}
                {/* {open && <AddEditResources open={open} setOpen={setOpen} />} */}
            </Box>
        </div>
    );
}

export default IndustriesHeader;
