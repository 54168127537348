import React, { useContext, useState } from 'react';
import useStyles from './ScheduleHeader.style';
import {
    Box, Button, Typography,
} from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import NewScheduleModel from '../NewScheduleModel';
import DeleteRowModel from '../DeleteRowModel';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR, PATH_DATABASE } from '../../../../Routes/path';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import HideUnusedButton from '../../../../components/HideUnusedButton';
import exportExcel from '../../../../helper/ExportExcel';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';

interface Props {
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setHideUnusedFlag: React.Dispatch<React.SetStateAction<boolean>>;
    hideUnusedFlag: boolean;
    isScheduleFetching: boolean;
}

function ResourcesHeader(props: Props) {
    const {
        searchText, setSearchText, hideUnusedFlag, setHideUnusedFlag, isScheduleFetching,
    } = props;

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [opendr, setOpenDelRow] = useState(false);
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickDelRow = () => {
        setOpenDelRow(true);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=schedule`);
    };

    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };
    const handleClear = () => {
        setSearchText('');
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'schedule',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    SCHEDULE
                </Typography>
                <CostHeaderBar />
                <Box className={classes.rightSection}>
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                </Box>
                <QuickSearchFilter searchText={searchText} setSearchText={setSearchText} />
                {!searchText
                && <HideUnusedButton hideUnusedFlagVal={hideUnusedFlag} setHideUnusedFlag={setHideUnusedFlag} loadingData={isScheduleFetching} />}
            </Box>
            <Box className={classes.rightSection}>
                {/* <Box className={classes.deleteButton}>
                    <Button onClick={handleClickDelRow}>
                        <DeleteOutlineIcon />
                        Delete
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button onClick={handleClickOpen}>
                        <DeleteOutlineIcon />
                        Edit
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button onClick={handleClickOpen}>
                        <ContentCopyIcon />
                        Duplicate
                    </Button>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button disabled>
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </Button>
                </Box> */}
                <Box className={classes.outlineButton}>
                    <Button onClick={handleExportExcel}>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box>
                {/* <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </Button>
                </Box>
                {open && <NewScheduleModel open={open} setOpen={setOpen} />}
                {opendr && <DeleteRowModel open={opendr} setOpenDelRow={setOpenDelRow} />} */}
            </Box>
        </div>
    );
}

export default ResourcesHeader;
