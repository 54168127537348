import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_PROJECT_COST = (Org_id: string, projectId: string, versionId: string) => gql`
query GetprojectCost {
  projectCost(
          org_id: ${addEscapeCharacters(Org_id)}
          project_id: ${addEscapeCharacters(projectId)}
          version_id: ${addEscapeCharacters(versionId)}
        ) {
          total
          direct
          indirect
        }
      }
`;

const GET_ORG_COST = (Org_id: string) => gql`
query GetOrgCost {
  orgCosts(
          org_id: ${addEscapeCharacters(Org_id)}
        ) {
          total
          direct
          indirect
        }
      }
`;

export default {
    GET_PROJECT_COST,
    GET_ORG_COST,
};
