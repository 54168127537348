import React, { useContext, useEffect, useState } from 'react';
import {
    FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProjectContext } from '../../store/context/projectContext';
import { COUNTRY_FLAG } from '../../helper/CountryFlag';
import { useGQLMutation } from '../../hooks/useGQLMutation';
import { CHANGE_PROJECT_CURRENCY_VARIABLES } from '../../queries/changeCurrency';
import { UserContext } from '../../store/context/userContext';
import Loader from '../Loader';
import DefaultCurrencyImage from '../../assets/flags/Default.svg';

type Props = {
    css?: string
};

function NavbarSelect(props: Props) {
    const { css } = props;
    /* ------------------- All State Variables ---------------------------- */
    const projectContext = useContext(ProjectContext);
    const projectCurrencies = projectContext?.projectCurrencyData?.getprojectCurrency;
    const refetchProject = projectContext?.refetchProject || (() => { });
    const userCtx = useContext(UserContext);
    const [selectedCurrency, setSelectedCurrency] = useState(projectContext?.project?.currency);

    /* ------- Prepare Currencies data to show in the dropdown ------------ */
    const currencies: { src: string, label: string, code: string }[] = [];
    projectCurrencies?.forEach((currencyData) => {
        const { code, symbol } = currencyData;
        currencies.push({
            src: COUNTRY_FLAG[code] ? COUNTRY_FLAG[code] : DefaultCurrencyImage,
            label: `${symbol || ''} ${code}`,
            code,
        });
    });

    /* ---------------- Refresh data if a new project is selected. ------------------------ */
    useEffect(() => {
        setSelectedCurrency(projectContext?.project?.currency);
    }, [projectContext?.project?.currency]);

    /* -------------------------- Change Currency Mutation ---------------------- */
    const { mutate: changeCurrency, isLoading } = useGQLMutation(
        'ChangeCurrency',
        CHANGE_PROJECT_CURRENCY_VARIABLES(),
        {
            onSuccess: () => { refetchProject(); },
        },
        '/setting',
    );

    const handleChange = (event: SelectChangeEvent) => {
        const newCurrency = projectCurrencies?.find((item) => item.code === event.target.value);
        setSelectedCurrency(newCurrency?.code);
        const orgId = userCtx?.user?.default_org_id;
        const projectId = projectContext?.project?.id;
        changeCurrency({ currency_id: newCurrency?.id, org_id: orgId, project_id: projectId });
    };

    return (
        <FormControl variant="filled">
            <Loader loading={isLoading} />
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCurrency}
                label="Select"
                onChange={handleChange}
                IconComponent={KeyboardArrowDownIcon}
            // disabled={disabled}
            >
                {currencies.map((currency) => (
                    <MenuItem key={currency.label} value={currency.code} className={css}>
                        {currency.src ? <img src={currency.src} alt={currency.label} /> : null}
                        {' '}
                        {currency.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
export default NavbarSelect;
