import { makeStyles } from '@mui/styles';

const FormatModelStyle = makeStyles(() => ({
    undoButtnNew: {
        fontSize: '12px',
        color: '#fff !important',
        border: '1px solid #007DFF !important',
        borderRadius: '4px',
        backgroundColor: '#007DFF !important    ',
        padding: '5px 10px !important',
        cursor: 'pointer',
        '& svg': {
            color: '#fff !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    subheading: {
        width: '80%',
        fontSize: '19px',
        lineHeight: '0',
        fontWeight: '700 !important',
        marginTop: '30px !important',
    },
    boxWrapformat: {
        width: '100%',
        overflow: 'hidden',
    },
    bggray: {
        backgroundColor: '#F8F8F8',
        border: '#ddd 1px solid',
        borderBottom: 'none',
        padding: '25px',
        width: '100%',
    },
    forheading: {
        color: '#353535',
        fontSize: '18px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        width: '100%',
    },
    bgwhite: {
        backgroundColor: '#f0f0f0',
        border: '#ddd 1px solid',
        width: '100%',
        borderTop: 'none',
        fontWeight: 'bold',
        padding: '25px',
    },
    digitext: {
        fontSize: '15px !important',
        marginBottom: '10px !important',
    },
    exptext: {
        color: '#aaa',
        fontSize: '14px !important',
    },
    btnIcon: {
        fontSize: '18px !important',
        marginRight: '5px',
    },
    btnSpace: {
        marginRight: '16px !important',
        padding: '5px 8px !important',
    },
    InputWhite: {
        background: '#fff',
    },

}));
export default FormatModelStyle;
