import { makeStyles } from '@mui/styles';

const ReportStyle = makeStyles(() => ({
    designerHost: {
        width: '100%',
        height: '92vh',
    },
    viewerHost: {
        margin: '0 auto',
        width: '100%',
        height: '85vh',
    },
    outlineButton: {
        '& button': {
            marginLeft: '15px',
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
            margin: '10px !important',
        },
    },
}));

export default ReportStyle;
