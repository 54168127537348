import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
    Container,
    DialogActions,
    DialogContent,
    InputLabel,
} from '@mui/material';
import CoaDuplicateStyle from './DuplicateRow.style';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  dup: boolean,
  setDup: (dup: boolean) => void
}

function DuplicateRowModal(props: Props) {
    const classes = CoaDuplicateStyle();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { open, setOpen } = props;
    const { dup, setDup } = props;

    const handleClickDup = () => {
        setDup(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseDup = () => {
        setDup(false);
    };

    return (
        <div>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={dup}
                    onClose={handleCloseDup}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="lg"
                    PaperProps={{
                        sx: {
                            width: '25%',
                            minHeight: 300,
                        },
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">
                        <Typography variant="h5" component="h5">
                            Duplicate Row
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ p: '0px', backgroundColor: '#f0f0f0' }}>
                        <Container>
                            <Grid container spacing={3} sx={{ pt: '40px' }}>
                                <Grid xs={12} md={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.font14}
                                        component="span"
                                        sx={{ ml: '5px' }}
                                    >
                                        Code
                                    </Typography>
                                    <Box component="div" sx={{ display: 'flex' }}>
                                        <TextField label="" className={classes.dTextBox} />
                                        <TextField label="" className={classes.dTextBox} />
                                        <TextField label="" className={classes.dTextBox} />
                                        <TextField label="" className={classes.dTextBox} />
                                        <TextField label="" className={classes.dTextBox} />
                                        <TextField label="" className={classes.dTextBox} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContent>
                    <DialogActions sx={{ mr: '15px' }}>
                        <Button
                            className={classes.popBtn}
                            autoFocus
                            onClick={handleCloseDup}
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                        >
                            Cancel
                        </Button>
                        <Box className={classes.undoButtnNew}>
                            <Button
                                className={classes.popBtn}
                                onClick={handleClose}
                                autoFocus
                                variant="contained"
                                sx={{ textTransform: 'none', mr: '5px' }}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default DuplicateRowModal;
