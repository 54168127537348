import { makeStyles } from '@mui/styles';

const ImportOptionsModelStyle = makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '10px !important',
            width: '50%',
            maxWidth: '800px !important',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '0px !important',
        '& input': {
            padding: '6px',
            height: '27px',
            fontSize: '18px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
        },
    },
    sectionBody: {
        padding: '32px !important',
        '& p': {
            color: '#787878 !important',
            padding: '32px 24px',
        },
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '27px !important',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
        },
    },
    leftdivCenter: {
        textAlign: 'center',
        padding: '28px 16px 16px 16px !important',
        '& button': {
            textTransform: 'unset !important',
            fontSize: '16px !important',
            border: '1px solid #787878 !important',
            color: '#787878 !important',
            fontFamily: 'Inter-Medium !important',
        },
    },
    rigntdivCenter: {
        textAlign: 'center',
        borderLeft: '1px solid #CECECE',
        padding: '28px 16px 16px 16px !important',
        '& img': {
            marginTop: '-11px',
        },
        '& button': {
            textTransform: 'unset !important',
            fontSize: '16px !important',
            border: '1px solid #007DFF !important',
            color: '#ffffff !important',
            fontFamily: 'Inter-Medium !important',
            background: '#007DFF !important',
        },
    },
    checkboxStyle: {
        margin: '20px 0px',
        '& span': {
            color: '#787878',
        },
    },
    validatedStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export default ImportOptionsModelStyle;
