import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    selectRadio: {
        '& .MuiFormControlLabel-label': {
            color: '#353535',
            fontSize: '14px !important',
        },
    },
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '6px !important',
            minWidth: '35%',
            width: '35%',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '12px 30px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '10px 30px !important',
        maxHeight: '30vh',
        '& input': {
            padding: '6px',
            height: '27px',
            fontSize: '15px',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            color: '#6A6A6A',
            fontFamily: 'Inter-Medium',
        },
        '& .Mui-disabled': {
            backgroundColor: '#E0E0E0',
        },
    },
    sectionBody: {
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '15px',
            height: '27px !important',
            borderRadius: '5px',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff',
            color: '#6A6A6A',
            alignItems: 'center',
            display: 'flex',
        },
    },
    dilogBoxSectionFooter: {
        padding: '10px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
}));
