import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    backgroundImg: {
        margin: '10px 0px',
    },
    logoSection: {
        height: '74px',
        backgroundColor: '#fff',
        padding: '0px 150px',
    },
    wrapper: {
        height: 'calc(100vh - 74px)',
        background: 'rgb(2,25,72)',
        backgroundImage: 'url("https://pcu-web.csi-infra.com/static/media/login_bg.1d70522b40a84951246f.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    contentWrapper: {
        display: 'flex',
        padding: '0px 150px',
        justifyContent: 'space-between',
        height: '75vh',
        alignItems: 'center',
    },
    loginBox: {
        width: '23%',
        backgroundColor: '#fff',
        padding: '75px 50px',
        borderRadius: '20px',
        marginTop: '5%',
    },
    centerDiv: {
        textAlign: 'center',
    },
    loginBoxContainer: {
        width: '100%',
        '& input': {
            height: '50px',
            'border-radius': '4px',
            border: '1px solid rgba(0, 0, 0, 0.42)',
            fontSize: '16px',
            color: 'var(--text-color-light-mode-primary, rgba(0, 0, 0, 0.87))',
            fontWeight: '400',
            width: 'calc(100% - 30px)',
            padding: '0px 15px',
        },
    },
    marginBottom: {
        marginBottom: '30px !important',
    },
    loginButton: {
        backgroundColor: '#021948',
        borderRadius: '7px',
        height: '50px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '600',
        width: '100%',
        cursor: 'pointer',
        border: 'none',
    },
    forgotPasswordLink: {
        float: 'right',
        fontFamily: "'Inter', sans-serif",
        color: '#2C73FF',
        fontSize: '16px',
        fontWeight: '600',
        textDecoration: 'none',
        marginTop: '18px',
    },
    contentLeftSection: {
        width: '55%',
    },
    loginHeaderText: {
        fontFamily: 'sans-serif',
        color: '#fff',
        fontSize: '50px',
        fontWeight: '700',
    },
    loginParagraphText: {
        fontFamily: 'sans-serif',
        fontWeight: '400',
        color: '#fff',
        fontSize: '22px',
        marginTop: '35px',
    },
    alert_danger: {
        color: 'red',
        margin: '0px',
        fontSize: '12px',
        textAlign: 'left',
        fontFamily: "'Inter', sans-serif",
        marginBottom: '18px',
        marginTop: '-24px',
    },
    cright: {
        padding: '0px 150px',
        fontFamily: "'Inter', sans-serif",
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '400',
        position: 'absolute',
        right: '0px',
    },
    atag: {
        color: '#ffffff !important',
    },
    error_message: {
        display: 'none',
        whiteSpace: 'break-spaces',
    },

    btn_login_disabled: {
        opacity: '0.6',
        cursor: 'not-allowed',
    },
    disabledTextField: {
        backgroundColor: '#D3D3D3',
    },
    passField: {
        position: 'relative',
        '& input': {
            padding: '0px 50px 0px 14px',
        },
        '& button': {
            position: 'absolute',
            right: '10px',
            top: '8px',
        },
    },
}));
