import { makeStyles } from '@mui/styles';

const IndustriesStyle = makeStyles(() => ({
    industryTable: {
        fontWeight: '600 !important',
        color: 'black',
    },
    headerCont: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputBoxStyle: {
        position: 'relative',
        marginLeft: '15px',
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '5px',
            transform: 'translateY(-50%)',
            color: '#787878 !important',
            fontSize: '17px',
        },
    },
    loaderContainer: {
        height: '60vh',
    },
    resourceBody: {
        marginTop: '30px',
    },
    tableSec: {
        marginTop: '30px',
        '& .ag-details-row': {
            '& .ag-theme-alpine': {
                '&:nth-child(1)': {
                    height: '100% !important',
                },
            },
        },
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 245px) !important',
            },
        },
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
        '& .ag-header-cell': {
            '& .ag-checkbox-input': {
                pointerEvents: 'visible',
            },
        },
    },
    redBtn: {
        borderRadius: '4px',
        padding: '3px 15px !important',
        color: '#B00000 !important',
        border: '1px solid #B00000 !important',
        '& svg': {
            color: '#B00000 !important',
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '27px !important',
            borderRadius: '5px !important',
            border: '1px solid #787878',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '1px 32px 1px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#787878',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    TableHeader: {
        margin: '0px',
        color: '#021948',
        padding: '5px 0px 20px 0px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: 'none',
                opacity: '1',
            },
        },
    },
    headingText: {
        fontSize: '13px',
        fontFamily: 'Inter-Bold',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    boldText: {
        fontSize: '13px',
        fontFamily: 'Inter-Medium',
        fontWeight: 600,
    },
    normalText: {
        fontSize: '13px',
        fontFamily: 'Inter',
        fontWeight: 400,
    },
}));

export default IndustriesStyle;
